export let eqipLazyComponents: any = {
    'eqip-nxt-product-details': {
        t: 'Product details editor component used in NXT only.',
        client: 'EQIP',
        loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/product/eqip-product-details.component')
    },
    'eqip-product-details': {
        t: 'Product: Details/Add to Quote',
        d: 'Eqip Main product detail section, used for product pages and includes Add to Quote button.',
        client: 'EQIP',
        loader: () => import('../_components/products/details/product-details'),
        config: () => import('../_components/products/details/product-details-config')
    },
    'eqip-nxt-order-details': {
        t: 'Order details component used in NXT only.',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/eqip-order-details.component')
    },
    'eqip-attachment-editor': {
        t: 'Attachment component used in NXT only for Equipio',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/attachment-editor/eqip-attachment-editor.component')
    },
    'eqip-footer': {
        t: 'Footer component used in NXT only for Equipio',
        d: 'Footer for Equipio',
        client: 'EQIP',
        type:'footer',
        loader: () => import('../_components/footer/eqip-footer'),
        config: () => import('../_components/footer/eqip-footer-config')
    },
    'eqip-contact-document-container': {
        t: 'Document section in contacts used in NXT only for Equipio',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/contact-document/eqip-document-container.component')
    },
    'eqip-contact-editor': {
        t: 'Contact section in contacts used in NXT only for Equipio',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/contact-editor/eqip-contact-editor.component')
    },
    // 'eqip-address-editor': {
    //     t: 'Address Editor component used in NXT only for Equipio',
    //     client: 'NXT',
    //     loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/address-editor/eqip-address-editor.component')
    // },
    'eqip-order-item': {
        t: 'Order Item for Orders List used in NXT only for Equipio',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/order-item/eqip-order-item.component')
    },
    'eqip-quote-item': {
        t: 'Quote Item for Quote List used in NXT only for Equipio',
        client: 'NXT',
        loader: () => import('../../../../nxto/src/app/_clients/eqip/_components/quote-item/eqip-quote-item.component')
    },
    'eqip-nav-header': {
        t: 'Eqip Header',
        d: 'Header for Equipio',
        client: 'EQIP',
        type:'header',
        loader: () => import('../_components/header/eqip-nav-header'),
        config: () => import('../_components/header/eqip-nav-header-config')
    },
    'new-eqip-nav-header': {
        t: 'New Eqip Header',
        d: 'New Header for Equipio',
        client: 'EQIP',
        type:'header',
        loader: () => import('../_components/header/new-eqip-navbar-header.component'),
        config: () => import('../_components/header/new-eqip-navbar-header.component'),
    },
    'eqip-headline-cards-animated': {
        t: 'Eqip Headline w/ 4 cards animated',
        d: 'Eqip Main Headline with 4 cards that rise up on scroll. Cards have icons on top with a subheading under it with subparagraph under that. On Hover the Arrow moves right.',
        client: 'EQIP',
        loader: () => import('../_components/content/headline-cards-animated/headline-cards-animated'),
        config: () => import('../_components/content/headline-cards-animated/headline-cards-animated-config')
    },
    'eqip-background-image-text-animated': {
        t: 'Image with text animated',
        d: 'Eqip Image with a slight tint, Where your headline and sub header are animated text on scroll',
        client: 'EQIP',
        loader: () => import('../_components/content/background-image-text-animated/image-text-animated'),
        config: () => import('../_components/content/background-image-text-animated/image-text-animated-config')
    },
    'eqip-category-cards-centered': {
        t: 'Category Cards that link to pages',
        d: 'Eqip Category Cards with image at the top text at the bottom with a button linking to equipment pages',
        client: 'EQIP',
        loader: () => import('../_components/content/category-cards-centered/category-cards-centered'),
        config: () => import('../_components/content/category-cards-centered/category-cards-centered-config')
    },
    'eqip-image-leftOrRight-text-animated': {
        t: 'Image Left or Right with Text Animated',
        d: 'Eqip Left or Right Image with text respectively, Both can be animated on scroll',
        client: 'EQIP',
        loader: () => import('../_components/content/image-leftOrRight-text-animated/image-leftOrRight-text-animated'),
        config: () => import('../_components/content/image-leftOrRight-text-animated/image-leftOrRight-text-animated-config')
    },
    'eqip-tinted-hero-image-text-cards': {
        t: 'Large Hero Image Tinted w/ text plus Cards',
        d: 'Eqip Large Hero Image that is tinted with text inside. The Bottom has cards that cut into the image at the bottom. Text and cards animated on scroll',
        client: 'EQIP',
        loader: () => import('../_components/content/tinted-hero-image-text/tinted-hero-image-text'),
        config: () => import('../_components/content/tinted-hero-image-text/tinted-hero-image-text-config')
    },
    'eqip-contact-us': {
        t: 'Email List Sign-Up',
        d: 'Contact us form with editable headline and button text.',
        client: 'EQIP',
        loader: () => import('../_components/content/email-contact/contact-us'),
        config: () => import('../_components/content/email-contact/contact-us-config')
    },
    'background-logo-equipment': {
        t: 'Background,Logo,Equipment',
        d: 'Background with a center logo and equipment underneath them',
        client: 'EQIP',
        loader: () => import('../_components/content/background-logo-equipment/background-logo-equipment'),
        config: () => import('../_components/content/background-logo-equipment/background-logo-equipment-config')
    },
    'eqip-browse-categories-section': {
        t: 'Category Carousel',
        d: 'Scrollable cards showcasing product categories with images and links.',
        client: 'EQIP',
        loader: () => import('../_components/homepage-sections/eqip-browse-categories/eqip-browse-categories.component'),
        config: () => import('../_components/homepage-sections/eqip-browse-categories/eqip-browse-categories.component')
    },
    'eqip-cta': {
        t: 'Call to Action',
        d: 'Feature list with checkmarks and a CTA button on the left; image on the right.',
        client: 'EQIP',
        loader: () => import('../_components/homepage-sections/eqip-cta/eqip-cta-section.component'),
        config: () => import('../_components/homepage-sections/eqip-cta/eqip-cta-section.component')
    },
    'eqip-features': {
        t: 'Key Features',
        d: 'Three features displayed in a row with top borders that highlight on hover.',
        client: 'EQIP',
        loader: () => import('../_components/homepage-sections/eqip-features/eqip-features.component'),
        config: () => import('../_components/homepage-sections/eqip-features/eqip-features.component')
    },
    'eqip-new-hero-component': {
        t: 'Hero with Search',
        d: 'Large hero banner with a product search bar and a right-slanted image.',
        client: 'EQIP',
        loader: () => import('../_components/homepage-sections/eqip-new-hero/eqip-new-hero-component'),
        config: () => import('../_components/homepage-sections/eqip-new-hero/eqip-new-hero-component')
    },
    'browse-products-section': {
        t: 'Top-Rated Products',
        d: 'A horizontal scrolling carousel featuring the most popular products.',
        client: 'EQIP',
        loader: () => import('../_components/homepage-sections/eqip-browse-products/eqip-browse-products.component'),
    },
    'eqip-new-footer': {
        t: 'New Footer component used in NXT only for Equipio',
        d: 'New Footer for Equipio',
        client: 'EQIP',
        type:'footer',
        loader: () => import('../_components/footer/eqip-new-footer.component'),
        config: () => import('../_components/footer/eqip-new-footer.component')
    },
    'eqip-cta-image': {
        t: 'CTA with flower pattern of images on the right',
        d: 'CTA with flower pattern of images on the right',
        client: 'EQIP',
        loader: () => import('../_components/otherpage-sections/eqip-cta-images/eqip-cta-images.component'),
        config: () => import('../_components/otherpage-sections/eqip-cta-images/eqip-cta-images.component')
    },
    'eqip-review-section': {
        t: 'Review Section',
        d: 'Left Reviewer Image with name,review,and role of reviewer to the left',
        client: 'EQIP',
        loader: () => import('../_components/otherpage-sections/eqip-review-section/eqip-review-section.component'),
        config: () => import('../_components/otherpage-sections/eqip-review-section/eqip-review-section.component')
    },
    'eqip-grid-feature-section': {
        t: 'Grid Feature Section',
        d: 'Grid Feature Section',
        client: 'EQIP',
        loader: () => import('../_components/otherpage-sections/eqip-grid-feature-section/eqip-grid-feature-section.component'),
        config: () => import('../_components/otherpage-sections/eqip-grid-feature-section/eqip-grid-feature-section.component')
    },
    'eqip-hero-horizontal': {
        t: 'Full-Width Hero with Background & Bottom Image',
        d: 'A hero section featuring a full-width background pattern, bold headline, subtext, and dual call-to-action buttons, with a wide image displayed at the bottom.',
        client: 'EQIP',
        loader: () => import('../_components/otherpage-sections/eqip-hero-horizontal/eqip-hero-horizontal.component'),
        config: () => import('../_components/otherpage-sections/eqip-hero-horizontal/eqip-hero-horizontal.component')
    }
};
