import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {Thread, ThreadMessage, Task, Filter, User, QueueItem, Role} from '@nxt/model-core';

import {UserListDialog} from '../../_components/users/user-list.dialog';
import {takeUntil} from 'rxjs/operators';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {UserService} from '@library/nxt/_services/user.service';
import {CommonModule} from '@angular/common';

import {AvatarStackComponent} from '@library/shared/avatars/avatar.stack.component';
import {BehaviorSubject, Subscription, take} from 'rxjs';
import {FireService} from '@library/nxt/_services/fire.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';

@Component({
    standalone: true,
    imports: [CommonModule,  AvatarStackComponent],
    selector: 'followers',
    template: `
        <div class="flex w-full" *ngIf="parent && parent[property]">
            <div class="flex-none inline" *ngIf="label">
                <button (click)="edit();" [class]="btnClass" [disabled]="!editable">
                    {{label}}
                    <span *ngIf="!avatars" class="ml-1">{{ parent[property].length }}</span>
                </button>
            </div>
            <div class="flex place-content-center" *ngIf="avatars">
                <avatar-stack 
                        (onClick)="editable ? $event ? remove($event) : edit() : null" 
                        size="6"
                        [btnClass]="btnClass" 
                        [limit]="limit"
                        [items]="parent[property]"
                        [debug]="debug"
                        [editable]="editable"
                ></avatar-stack>
            </div>
        </div>
    `
})
export class FollowersComponent extends OnDestroyPage implements OnChanges {
    @Output() onChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Input() label: string = 'Followers';
    @Input() parent: any;
    @Input() property: string = 'followers';
    @Input() limit: number = 3;
    @Input() avatars: boolean = true;
    @Input() btnClass: string = 'btn-gray btn-xs';
    @Input() roles: Role[];
    @Input() editable: boolean = true;
    @Input() debug: boolean;
    sub: Subscription;

    constructor(
        private pSvc: PageService,
        private fSvc: FireService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.parent && changes.parent) {
            if (!this.parent[this.property]) {
                this.parent[this.property] = [];
            }
            this.watchParent();
        }
    }

    watchParent() {
        if (this.parent?._exists) {
            this.sub?.unsubscribe();
            this.sub = this.fSvc.watchObject(this.parent._docRef.path, this.d$)
                .subscribe(([obj,delta]) => {
                    this.parent = obj;
                });
        }
    }

    async remove(item: any) {
        try {
            this.parent.remove(this.property, item);
            this.onChange.emit(this.parent[this.property]);
        } catch (e) {
            console.warn(e);
        }
    }

    edit() {
        this.pSvc.modal$.next({
            component: UserListDialog,
            label: this.label,
            onLoaded: (comp: UserListDialog) => {
                comp.parent = this.parent;
                comp.property = this.property;
                comp.roles = this.roles;
                comp.ngOnChanges();
                comp.onClose.pipe(take(1)).subscribe(
                    users => {
                        this.onChange.emit(users);
                    }
                );
            }
        });
    }
}
