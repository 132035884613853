import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';

import {ClientService} from '@library/shared/_services/client.service';
import {UserService} from '@library/nxt/_services/user.service';
import {PageService} from '@library/shared/_services/page.service';
import {FireService, IFirestoreQuery} from '@library/nxt/_services/fire.service';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {IMenuItem, Thread, ThreadMessage, User} from '@nxt/model-core';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {MessageItemSummary} from './message-item-summary';
import {ThreadItemComponent} from './thread-item';
import {AccountService} from '@library/nxt/_services/account.service';
import {ScrollableGenericList} from '@library/nxt/list/scrollable-generic-list';
import {Router} from '@angular/router';
import {combineLatest, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {IconsComponent} from '@library/shared/icons/icons.component';

@Component({
    selector: 'unread-list',
    standalone: true,
    imports: [
        CommonModule,
        ThreadItemComponent,
        MessageItemSummary,
        PopButtonComponent,
        MessageItemSummary,
        ScrollableGenericList,
        IconsComponent
    ],
    template: `
        <div class="border-b border-gray-200">
            <div class="w-full flex justify-between place-items-center pt-4">
                <div class="flex pl-3">
                    <div (click)="showAll()" class="chicklet flex bg-dark text-light text-xs -mt-3 mr-2"  *ngIf="counts">
                        Total: {{ counts.total }}
                    </div>
                    <div (click)="toggleUnseen()" class="chicklet flex bg-red-600 text-white text-xs -mt-3" *ngIf="counts">
                        Unseen: {{ counts.unseen }}
                    </div>
                </div>
                <div class="flex">
                    <button class="btn-clear -mt-4" *ngIf="showDeleteBtn" (click)="removeSelected()">
                        <icon name="heroicon-outline-trash" class="h-5 w-5 text-red-600"></icon>
                    </button>
                    <ng-container *ngIf="clients?.length">
                        <pop-button iconName="heroicon-solid-dots-vertical"
                                    iconClass="h-4 w-4 text-gray-500 ml-1 mb-1"
                                    [items]="clients"
                                    label="Other Unreads"
                                    btnClass="border-transparent inline-flex mr-1 text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                        ></pop-button>
                    </ng-container>
                    <ng-container *ngIf="uSvc.isRole(['admin'])">
                        <pop-button iconName="heroicon-solid-dots-vertical"
                                    iconClass="h-4 w-4 text-gray-500 ml-1 mb-1"
                                    [items]="options"
                                    [label]="optionsLabel"
                                    btnClass="border-transparent inline-flex mr-1 text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                        ></pop-button>
                    </ng-container>
                    <pop-button iconName="heroicon-solid-dots-vertical"
                                iconClass="h-4 w-4 text-gray-500 ml-1 mb-1"
                                [items]="actionsMenu"
                                label="Bulk Actions"
                                btnClass="border-transparent inline-flex mr-1 text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                    ></pop-button>
                </div>
            </div>
        </div>
        
        <scrollable-generic-list
                #itemList
                class="h-screen-header-search pb-20 overflow-y-auto"
                [baseQuery]="baseQuery"
                label="Unreads"
                [pageSize]="50"
                [itemTemplate]="itemTemplate"
                [padding]="context === EThreadContext.SIDEBAR ? 'p-0' : ''"
                [path]="path"
                [autoStart]="true"
        ></scrollable-generic-list>
        
        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <div class="flex w-full border-b border-gray-200" *ngIf="item?.id">
                <div class="flex place-items-start">
                    <button (click)="toggle(item, i)"
                            class="btn-sm btn-clear w-8 h-8 p-0"
                    >
                        <icon [name]="markedForRemoval[item.id] ? 'heroicon-outline-check-circle' : 'heroicon-outline-x'" class="h-6 w-6 text-gray-400 hover:text-gray-600"></icon>
                    </button>
                </div>
                <div class="w-full h-full cursor-pointer hover:bg-gray-100">
                    <message-item-summary
                            *ngIf="item['_type']==='threadsmsgs'"
                            (onSelect)="handleClick(item)"
                            [message]="item"
                            [context]="EThreadContext.UNREAD"
                    ></message-item-summary>
                    <thread-item
                            *ngIf="item['_type']==='threads'"
                            [thread]="item"
                            [loadAll]="true"
                            [context]="EThreadContext.UNREAD"
                            (onSelect)="handleClick(item)"
                    ></thread-item>
                </div>    
            </div>
        </ng-template>

    `
})
export class UnreadListComponent extends OnDestroyPage implements OnChanges {
    @ViewChild('itemList', {static:true}) itemList: ScrollableGenericList;
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEmpty: EventEmitter<any> = new EventEmitter<any>();
    @Input() roles: string[] = ['sales'];
    @Input() context: EThreadContext;
    @Input() user: User;
    @Input() client: any;
    @Input() showTotals: boolean;
    @Input() show: boolean;
    options: IMenuItem[];
    optionsLabel: string = 'Users';
    activeItem: Thread | ThreadMessage;
    height: string;
    EThreadContext = EThreadContext;
    tabs: IMenuItem[] = [];
    uBR: any;
    baseQuery: IFirestoreQuery[] = [
        { name: 'orderBy', args: ['date','desc'] }
    ];
    path: string;
    clients: IMenuItem[];
    unread: any;
    counts: any;
    countSub: Subscription;
    markedForRemoval: any = {};
    showDeleteBtn: boolean;

    get actionsMenu(): IMenuItem[] {
        return [
            {
                label: 'Remove Selected',
                click: async () => {
                    this.removeSelected();
                }
            },
            {
                label: 'Mark Selected as Read',
                click: async () => {
                    this.pSvc.loading$.next(true);
                    await Promise.all(Object.keys(this.markedForRemoval).map(async (id:any) => {
                        try {
                            await this.markedForRemoval[id].markRead();
                        } catch (e) {
                            console.warn(e);
                        }
                    }));
                    this.markedForRemoval = {};
                    this.itemList.loadData(true);
                    this.pSvc.loading$.next(false);
                }
            },
            {
                label: 'Mark Selected as Unread',
                click: async () => {
                    this.pSvc.loading$.next(true);
                    await Promise.all(Object.keys(this.markedForRemoval).map(async (id:any) => {
                        try {
                            await this.markedForRemoval[id]._unread._docRef.update({seen: false});
                        } catch (e) {
                            console.warn(e);
                        }
                    }));
                    this.markedForRemoval = {};
                    this.itemList.loadData(true);
                    this.pSvc.loading$.next(false);
                }
            },
            {
                label: 'Select All',
                click: async () => {
                    if (Object.keys(this.markedForRemoval).length) {
                        this.markedForRemoval = {};
                        this.showDeleteBtn = false;
                    } else {
                        this.markedForRemoval.all = true;
                        await Promise.all(this.itemList?.items?.map(async (t:any) => {
                            this.markedForRemoval[t.id] = t;
                        }));
                        this.showDeleteBtn = true;
                    }
                }
            }
        ];
    };

    constructor(
        public cSvc: ClientService,
        public uSvc: UserService,
        public mSvc: MessagingService,
        private pSvc: PageService,
        private fSvc: FireService,
        private aSvc: AccountService,
        private router: Router
    ) {
        super();

        combineLatest([this.mSvc.counts$, this.cSvc.c$, this.cSvc.clients$])
            .pipe(takeUntil(this.d$))
            .pipe(map(res => {
                return {unread: res[0], client: res[1], clients: res[2]};
            }))
            .subscribe(
                result => {
                    if (result?.clients?.length) {
                        this.unread = result.unread;
                        this.client = result.client;
                        this.loadClients();
                    }
                }
            );

        this.mSvc.onChange
            .pipe(takeUntil(this.d$))
            .subscribe(e => {
                this.loadData();
            })

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.user || changes.client?.currentValue?.id && this.show) {
            this.counts = null;
            this.path = `users/${this.user.id}/clients/${this.cSvc.client_id}/unread`;
            this.loadOptions();
            this.loadData();
        }
    }

    async loadData() {
        if (this.itemList) {
            this.itemList.path = this.path;
            this.itemList.loadData(true);
            this.loadClients();
            this.loadCounts();
        }
    }

    async loadCounts() {
        this.countSub?.unsubscribe();
        if (this.context === EThreadContext.INBOX) {
            this.countSub = this.fSvc.fs.doc(`users/${this.user.id}/clients/${this.cSvc.client_id}`)
                .valueChanges()
                .pipe(takeUntil(this.d$))
                .subscribe(res => {
                    this.counts = res;
                });
        }
    }

    async loadOptions() {
        if (this.uSvc.isRole(['admin'])) {
            if (!this.uBR) {
                this.uBR = await this.aSvc.getUsersByRole();
            }
            this.options = [{
                label: this.uSvc.user$.getValue().name,
                click: () => {
                    this.optionsLabel = 'Other Users';
                    this.user = this.uSvc.user$.getValue();
                    this.path = `users/${this.user.id}/clients/${this.cSvc.client_id}/unread`;
                    this.loadData();
                }
            }].concat(this.uBR.user?.reduce((users,u) => {
                if (u.id !== this.uSvc.user$.getValue()?.id) {
                    users.push({
                        label: u.name,
                        click: () => {
                            this.user = u;
                            this.path = `users/${this.user.id}/clients/${this.cSvc.client_id}/unread`;
                            this.optionsLabel = u.name;
                            this.loadData();
                        }
                    });
                }
                return users;
            },[]).sort((a,b) => {
                return (a.label < b.label) ? -1 : 1;
            }));
        }
    }

    async loadClients() {
        if (
            this.unread
            && this.client?.id
            && this.user?.id === this.uSvc.user$.getValue()?.id
            && this.cSvc.clients$.getValue()?.length
        ) {
            this.clients = Object.keys(this.unread).reduce((items,id) => {
                if (id !== this.client.id) {
                    let c = this.cSvc.clients$.getValue()?.find(c => c.id === id);
                    if (c && this.unread[c.id].total) {
                        items.push({
                            label: `${c.name} (${this.unread[id].total})`,
                            click: () => {
                                this.router.navigate([`/${c.name_key}/threads`]);
                            }
                        });
                    }
                }
                return items;
            }, []);
        } else {
            this.clients = [];
        }
    }

    async toggle(item: Thread|ThreadMessage, i: number) {
        try {
            if (this.markedForRemoval[item?.id]) {
                delete this.markedForRemoval[item?.id];
            } else {
                this.markedForRemoval[item?.id] = item;
            }
            this.showDeleteBtn = !!(Object.keys(this.markedForRemoval).length);
        } catch (e) {
            console.warn(e, item);
            this.pSvc.notification$.next({
                title: 'Oops!',
                message: 'Had trouble deleting this item. It may already be removed. Try refreshing your page.'
            });
        }
    }

    async removeSelected() {
        this.pSvc.loading$.next(true);

        // See if user wants to delete all.
        let deleteAll: boolean = this.markedForRemoval.all;
        delete this.markedForRemoval.all;

        for (let id of Object.keys(this.markedForRemoval)) {
            let i: number = this.itemList.items.findIndex(item => item.id === id);
            if (i > -1) {
                this.itemList.items.splice(i,1);
            }
            try {
                await this.markedForRemoval[id]._unread?._docRef?.delete();
            } catch (e) {
                console.warn(e);
            }
        }
        this.markedForRemoval = {};

        if (deleteAll) {
            let preserve: string[] = this.itemList.items.map(item => item.id);
            await this.mSvc.deleteUnreads(this.user, this.client, preserve);
            this.itemList.items = [];
        }
        this.itemList.loadData(true);

        this.pSvc.loading$.next(false);
    }

    async handleClick(item: Thread|ThreadMessage) {
        if (this.context === EThreadContext.INBOX) {
            this.mSvc.showThread(item);
        } else if (this.context === EThreadContext.SIDEBAR) {
            this.mSvc.nav(item, true);
        }
        this.onClick.emit(item);
        this.itemList.loadData(true);
    }

    toggleUnseen() {
        if (this.baseQuery.length === 1) {
            this.baseQuery = [
                { name: 'orderBy', args: ['date','desc'] },
                { name: 'where', args: ['seen','==',false] }
            ];
            this.itemList.load(this.baseQuery);
        } else {
            this.showAll();
        }
    }

    showAll() {
        this.baseQuery = [
            { name: 'orderBy', args: ['date','desc'] }
        ];
        this.itemList.load(this.baseQuery);
    }
}
