import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Task} from '@nxt/model-core';
import {DomSanitizer} from '@angular/platform-browser';
import {AvatarComponent} from '../../shared/avatars/avatar.component';
import {AvatarStackComponent} from '../../shared/avatars/avatar.stack.component';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [CommonModule, AvatarComponent, AvatarStackComponent, IconsComponent],
    selector: 'task-item',
    template: `
        <a (click)="onClick.emit(task)">
            <div class="flex pl-2 w-full justify-between">
                <div class="flex flex-col">
                    <span class="font-bold">{{ task.name || '' }}</span>
                    <div class="italic p-1 text-sm">Change Group: {{ task.changeId||'N/A' }}</div>
                    <avatar-stack [items]="task.agents" size="6"></avatar-stack>
                </div>
                <div class="ml-2 w-20 flex flex-col">
                    <div id="outer" class="cursor-pointer flex h-4 w-full bg-gray-200 rounded-full text-white font-bold text-center p-0 text-xs">
                        <div id="inner"
                             [class]="(task.index < 30) ? 'bg-green-600 h-4 rounded-full' : (task.index < 60) ? 'bg-yellow-500 h-4 rounded-full' : 'bg-red-500 h-4 rounded-full'"
                             style="width: {{task.index||'10'}}%">
                            <span *ngIf="task.index>=60">HI</span>
                        </div>
                        <div class="flex grow place-content-end pr-2">
                            <span *ngIf="task.index<30">LOW</span>
                            <span *ngIf="task.index>30 && task.index<60">MED</span>
                        </div>
                    </div>
                    <div id="outer" class="mt-1 cursor-pointer flex h-4 w-full bg-gray-200 rounded-full text-white font-bold text-center p-0 text-xs">
                        <div id="inner" class="bg-blue-600 h-4 rounded-full"
                             style="width: {{task.completed||'10'}}%">
                            <span *ngIf="task.completed>=50">{{task.completed}}%</span>
                        </div>
                        <div class="flex grow place-content-end pr-2">
                            <span *ngIf="task.completed<50">{{task.completed||'0'}}%</span>    
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-gray-500 ml-6 max-h-100 overflow-hidden" [innerHTML]="html">
            </div>
        </a>
    `
})
export class TaskItem implements OnChanges {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() task: Task;
    html: any;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.task.description) {
            this.html = this.sanitizer.bypassSecurityTrustHtml(this.task.description);
        } else {
            this.html = null;
        }
    }
}
