import {
    ViewChild, EventEmitter, Component, ElementRef, Input, AfterViewInit, Output
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Router, RouterModule} from '@angular/router';

import {takeUntil} from 'rxjs/operators';
import {PageService} from '../_services/page.service';

import {createPopper} from "@popperjs/core";

import {IMenuItem, User, Contact} from '@nxt/model-core';
import {IconsComponent} from '../icons/icons.component';
import {OnDestroyPage} from '../_inherited/ondestroy.page';
import {AvatarComponent} from '../avatars/avatar.component';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, IconsComponent, AvatarComponent],
    selector: 'pop-button',
    template: `
        <div class="relative inline-flex align-middle w-full cursor-pointer">
            <div
                    [class]="btnClass"
                    [style.color]="labelColor"
                    [style.background-color]="backgroundColor"
                    (click)="toggleDropdown($event)"
                    #btnDropdownRef
            >
                <img *ngIf="imgSrc" [src]="imgSrc" [class]="imgClass"/>
                <span *ngIf="label">{{ label }}</span>
                <avatar *ngIf="avatarUser" [size]="avatarSize" ringSize="0" [user]="avatarUser"></avatar>
                <icon [name]="iconName" [class]="iconClass"></icon>
            </div>
        </div>
        <div [style.z-index]="zIndex" style="min-width: 12rem" #popoverDropdownRef>
            <div class="bg-white text-base float-left py-2 list-none text-left rounded shadow-lg mb-4 border-dark"
                 style="min-width: 12rem; border-width: 1px; max-height: 250px; overflow-y: auto;"
                 [class]="dropdownPopoverShow ? 'block' : 'hidden'"
            >
                <ng-container *ngFor="let item of items">
                    <ng-container *ngIf="item && !item.hide">
                        <div (click)="nav(item)" [style.color]="item.color"
                           class="cursor-pointer text-sm px-3 m-0 py-1 no-underline bg-transparent text-gray-700 hover:bg-gray-100"
                        >
                            {{ item.label ? item.label : item }}
                            <div class="text-xs font-italic" *ngIf="item.tooltip">{{ item.tooltip }}</div>
                        </div>
                    </ng-container>
                </ng-container>
                <a *ngIf="manualCloseLabel"
                   (click)="dropdownPopoverShow=false;"
                   class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                >
                    {{ manualCloseLabel }}
                </a>
            </div>
        </div>
    `,
})
export class PopButtonComponent extends OnDestroyPage implements AfterViewInit {
    @Output() select: EventEmitter<any> = new EventEmitter<any>();
    @Output() click: EventEmitter<any> = new EventEmitter<any>();
    @Input() items: IMenuItem[];
    @Input() label: string;
    @Input() imgSrc: string;
    @Input() imgClass: string;
    @Input() labelColor: string;
    @Input() backgroundColor: string;
    @Input() menuPlacement: any;
    @Input() btnClass: string = 'btn-dark';
    @Input() iconName: string;
    @Input() iconClass: string;
    @Input() avatarSize: '6' | '7' | '8' | '9' | '10' | '11' | '12' | '14' | '16' = '6';
    @Input() avatarUser: User | Contact;
    @Input() manualCloseLabel: string;
    @Input() disabled: boolean;
    @Input() zIndex: number = 40;


    show: boolean;
    dropdownPopoverShow = false;
    @ViewChild("btnDropdownRef", {static: false}) btnDropdownRef: ElementRef;
    @ViewChild("popoverDropdownRef", {static: false}) popoverDropdownRef: ElementRef;

    constructor(
        private router: Router,
        private pSvc: PageService,
        private eRef: ElementRef
    ) {

        super();
        this.pSvc.clickEsc$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    this.show = false;
                    this.dropdownPopoverShow = false;
                }
            );

        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (e
                        && e.target
                        && this.eRef?.nativeElement
                        && !this.eRef.nativeElement.contains(e.target)
                        && !this.manualCloseLabel
                    ) {
                        this.show = false;
                        this.dropdownPopoverShow = false;

                    }
                }
            );

    }

    ngAfterViewInit() {
        createPopper(
            this.btnDropdownRef.nativeElement,
            this.popoverDropdownRef.nativeElement,
            {
                placement: this.menuPlacement || 'auto'
            }
        );
    }

    toggleDropdown(event) {
        this.click.emit();
        event.preventDefault();
        if (this.dropdownPopoverShow) {
            if (!this.manualCloseLabel) {
                this.dropdownPopoverShow = false;
            }
        } else if (this.items?.length) {
            this.dropdownPopoverShow = true;
        }
    }

    async nav(item: IMenuItem) {
        if (item.click) {
            item.click();
        } else if (item.routerLink) {
            await this.router.navigate([item.routerLink]);
        } else if (item.url) {
            window.open(item.url, '_self');
        }
        if (!this.manualCloseLabel) {
            this.dropdownPopoverShow = false;
        }
        this.select.emit(item);
    }
}
