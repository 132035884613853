import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import {DomSanitizer} from '@angular/platform-browser';

import {ThemeService} from '@library/shared/_services/theme.service';
import {PageService} from '@library/shared/_services/page.service';
import {AccountService} from '@library/nxt/_services/account.service';
import {MessagingService} from '@library/nxt/_services/messaging.service';
import {UserService} from '@library/nxt/_services/user.service';
import {ClientService} from '@library/shared/_services/client.service';
import {RootNxtAppComponent} from '@library/shared/_inherited/root-nxt-app.component';

@Component({
    selector: 'app-root',
    template: `
        <nxt-tos-header></nxt-tos-header>
        <notification-dialog></notification-dialog>
        <progress-component [loading$]="pSvc.loading$" [blocking$]="pSvc.blocking$"></progress-component>
        <!-- CMS-POWERED CONTENT -->
        <ng-container *ngIf="(pSvc.page$|async)?.id">
            <flex-page-component [page]="(pSvc.page$|async)?.header"></flex-page-component>
            <router-outlet></router-outlet>
            <flex-page-component [page]="(pSvc.page$|async)?.footer"></flex-page-component>
        </ng-container>
        <!-- NXT-ADMIN CONTENT -->
        <ng-container *ngIf="(pSvc.page$|async) && !(pSvc.page$|async).id">
            <nxto-header-component></nxto-header-component>
            <div class="main-content-wrapper">
                <div class="flex w-full h-full">
                    <div class="flex-none">
                        <sidebar-component [menu]="pSvc.menu$|async"></sidebar-component>
                    </div>
                    <div [class]="contentClass">
                        <div *ngIf="disallowed"
                             class="bg-accent-100 mt-20 border-t border-b border-dark-500 text-dark-700 px-4 py-3 flex max-w-400 m-auto"
                        >
                            <div class="flex-grow">
                                <span class="font-bold">Permission Denied.</span><br/>
                                <span>Your permissions-level does not include the requested page. If this is incorrect, please contact an admin.</span>
                            </div>
                        </div>
                        <ng-container *ngIf="!disallowed">
                            <router-outlet></router-outlet>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container #modalContainer></ng-container>
    `
})
export class AppComponent extends RootNxtAppComponent {

    constructor(
        public cSvc: ClientService,
        public pSvc: PageService,
        public mSvc: MessagingService,
        public uSvc: UserService,
        private aSvc: AccountService,
        @Inject('TemplateLoaderService') private tSvc: any, // Include here so it's available everywhere it might be needed.
        router: Router,
        thSvc: ThemeService,
        swUpdate: SwUpdate,
        sanitizer: DomSanitizer
    ) {

        super(cSvc, mSvc, uSvc, pSvc, router, thSvc, swUpdate, sanitizer);

    }



}
