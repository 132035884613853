"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WORK_ORDER_STAGE_MAP = exports.WORK_ORDER_STAGES = exports.DIMENSION = exports.FINISHES = exports.ALLOYS = exports.SHAPES = void 0;
exports.SHAPES = [
    { name: 'Angle', types: ['Tapered', 'Sharp'] },
    { name: 'Bar', types: ['Flat', 'Round', 'Square'] },
    { name: 'Beam', types: ['I-Beam', 'H-Beam'] },
    { name: 'Channel', types: ['Straight', 'Tapered', 'Sharp'] },
    { name: 'Grating', types: ['Bar', 'Safety'] },
    { name: 'Pipe', types: ['Plain'] },
    { name: 'Plate', types: ['Plain', 'Diamond', 'Tread'] },
    { name: 'Sheet', types: ['Plain', 'Expanded', 'Perforated'] },
    { name: 'Tube', types: ['Rectangle', 'Round', 'Square', 'Square-Sharp', 'Rectangle-Sharp'] },
    { name: 'U-Trim', types: ['Plain'] }
];
exports.ALLOYS = [{ name: 'Steel' }, { name: 'Stainless' }, { name: 'Aluminum' }];
exports.FINISHES = [{ name: 'Galvanized' }, { name: 'Bright' }, { name: 'Brushed' }, { name: 'Brushed/Polished' }, { name: 'Polished' }, { name: 'Mirror' }];
exports.DIMENSION = {
    "U-Trim": [
        {
            "name": "Opening",
            "hypotenuse": true,
            "variable": false
        },
        {
            "name": "Width",
            "hypotenuse": true,
            "variable": false
        },
        {
            "name": "Length",
            "variable": true
        }
    ],
    "Angle": {
        "Tapered": [
            {
                "name": "Leg A",
                "hypotenuse": ['Leg A', 'Leg B'],
                "variable": false
            },
            {
                "name": "Leg B",
                "variable": false
            },
            {
                "name": "Thickness",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Sharp": [
            {
                "name": "Leg A",
                "hypotenuse": ['Leg A', 'Leg B'],
                "variable": false
            },
            {
                "name": "Leg B",
                "variable": false
            },
            {
                "name": "Thickness",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ]
    },
    "Sheet": {
        "Plain": [
            {
                "name": "Thickness",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Gauge",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": true
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Expanded": [
            {
                "name": "Diamond",
                "variable": false
            },
            {
                "name": "Thickness",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Gauge",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": true
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Perforated": [
            {
                "name": "Thickness",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Gauge",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Hole Diameter",
                "variable": false
            },
            {
                "name": "Spacing",
                "variable": false
            },
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": true
            },
            {
                "name": "Length",
                "variable": true
            }
        ]
    },
    "Plate": [
        {
            "name": "Thickness",
            "hypotenuse": true,
            "variable": false
        },
        {
            "name": "Width",
            "hypotenuse": true,
            "variable": true
        },
        {
            "name": "Length",
            "variable": true
        }
    ],
    "Tube": {
        "Round": [
            {
                "name": "Outside Diameter",
                "diameter": true,
                "variable": false
            },
            {
                "name": "Wall",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Square": [
            {
                "name": "Height",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Wall",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Rectangle": [
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Height",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Wall",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Square-Sharp": [
            {
                "name": "Height",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Wall",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Rectangle-Sharp": [
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Height",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Wall",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ]
    },
    "Bar": {
        "Round": [
            {
                "name": "Diameter",
                "diameter": true,
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Square": [
            {
                "name": "Height",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Flat": [
            {
                "name": "Thickness",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ]
    },
    "Beam": {
        "I-Beam": [
            {
                "name": "Height",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Flange",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Web",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "H-Beam": [
            {
                "name": "Height",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Flange",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Web",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ]
    },
    "Channel": {
        "Straight": [
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Flange",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Web",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Sharp": [
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Flange",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Web",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Tapered": [
            {
                "name": "Width",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Flange",
                "hypotenuse": true,
                "variable": false
            },
            {
                "name": "Web",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ]
    },
    "Pipe": [
        {
            "name": "Outer Diameter",
            "diameter": true,
            "variable": false
        },
        {
            "name": "Nominal Size",
            "diameter": true,
            "variable": false
        },
        {
            "name": "Inner Diameter",
            "variable": false
        },
        {
            "name": "Wall",
            "variable": false
        },
        {
            "name": "Length",
            "variable": true
        }
    ],
    "Grating": {
        "Bar": [
            {
                "name": "Thickness",
                "variable": false
            },
            {
                "name": "Height",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ],
        "Safety": [
            {
                "name": "Width",
                "variable": false
            },
            {
                "name": "Height",
                "variable": false
            },
            {
                "name": "Gauge",
                "variable": false
            },
            {
                "name": "Length",
                "variable": true
            }
        ]
    }
};
exports.WORK_ORDER_STAGES = {
    'Draft': 0,
    'Materials': 1,
    'Queued': 2,
    'Processing': 3,
    'Finished': 4,
    'Ordered': 5,
    'Problem': 6
};
exports.WORK_ORDER_STAGE_MAP = {
    0: 'Draft',
    1: 'Materials',
    2: 'Queued',
    3: 'Processing',
    4: 'Finished',
    5: 'Ordered',
    6: 'Problem'
};
