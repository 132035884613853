"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetalsWorkOrderInstruction = exports.MetalsWorkOrder = void 0;
const Utils_1 = require("./Utils");
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class MetalsWorkOrder extends model_core_1.WorkOrder {
    get stage() {
        return (this._stage !== undefined) ? this._stage : (this.state) ? Utils_1.WORK_ORDER_STAGES[this.state] : null;
    }
    set stage(i) {
        if (i !== this._stage) {
            this._stage = i;
            this.state = Utils_1.WORK_ORDER_STAGE_MAP[i] || '';
        }
    }
    constructor(data, olMap) {
        var _a;
        super(data, olMap || index_1.olm);
        this.client_id = ''; // Deprecate
        this.order_id = ''; // Deprecate
        this.agent_id = ''; // Deprecate
        this.location_id = ''; // Deprecate
        this.worker_id = ''; // Deprecate
        this.state = 'Draft';
        this.stages = [];
        this.notes = '';
        this.document = '';
        this.instructions = new model_core_1.ChildArray();
        this.order = null;
        this.location = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, MetalsWorkOrder);
        this._stage = (data === null || data === void 0 ? void 0 : data.data) ? (_a = data.data()) === null || _a === void 0 ? void 0 : _a.stage : (data === null || data === void 0 ? void 0 : data.stage) || 0;
        this.state = Utils_1.WORK_ORDER_STAGE_MAP[this._stage] || '';
        try {
            Object.keys(Utils_1.WORK_ORDER_STAGES).forEach((stage) => {
                if (!this.stages[Utils_1.WORK_ORDER_STAGES[stage]]) {
                    this.stages[Utils_1.WORK_ORDER_STAGES[stage]] = {};
                }
            });
            if (this.stages.length > Utils_1.WORK_ORDER_STAGES.length) {
                this.stages.splice(Utils_1.WORK_ORDER_STAGES.length - 1);
            }
        }
        catch (e) {
        }
    }
    async convert(opts) {
        var _a;
        if ((_a = this.order) === null || _a === void 0 ? void 0 : _a.id) {
            this.order = new index_1.MetalsOrder(await this._docRef.parent.parent.collection('orders').doc(this.order.id).get());
        }
        await this.save();
    }
    async loadAll(opts, stack) {
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        return super.loadAll(opts, stack);
    }
    toString() {
        var _a, _b;
        return `WorkOrder: ${(_a = this.workers) === null || _a === void 0 ? void 0 : _a.map(w => w.name).join(';')}: ${(_b = this.order) === null || _b === void 0 ? void 0 : _b.invoice_id}: ${this.state}`;
    }
    toJSON() {
        let r = super.toJSON();
        r.stage = this.stage;
        ['location', 'agent', 'worker', 'order'].forEach(p => {
            r[p] = (0, model_core_1.toMinJSON)(this, p);
            // Deprecate eventually.
            if (r[p] && r[p].id) {
                r[`${p}_id`] = r[p].id;
            }
        });
        ['stateMap', 'instructions', 'order'].forEach(p => delete r[p]);
        return r;
    }
}
exports.MetalsWorkOrder = MetalsWorkOrder;
class MetalsWorkOrderInstruction extends model_core_1.Base {
    set stage(i) {
        if (i !== this._stage) {
            this._stage = i;
            this.state = Utils_1.WORK_ORDER_STAGE_MAP[i] || '';
        }
    }
    get stage() {
        return (this._stage !== undefined) ? this._stage : (this.state) ? Utils_1.WORK_ORDER_STAGES[this.state] : null;
    }
    constructor(data, olMap) {
        super('instructions', 10);
        this.index = null;
        this.name = '';
        this.description = '';
        this.variant_number = '';
        this.order_id = '';
        this.work_order_id = '';
        this.inventory_id = '';
        this.variant_id = '';
        this.piece_id = '';
        this.item_id = '';
        this.location_id = '';
        this.type = '';
        this.dimensions = new model_core_1.DimensionArray();
        this.state = 'Draft';
        this.notes = '';
        this.total_weight = null;
        this.date = null;
        this.order_date = null;
        this.quantity = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, MetalsWorkOrderInstruction);
    }
    toJSON() {
        let r = super.toJSON();
        r.stage = this.stage;
        return r;
    }
}
exports.MetalsWorkOrderInstruction = MetalsWorkOrderInstruction;
index_1.olm.work_orders = (ref, map) => {
    return new MetalsWorkOrder(ref, map);
};
index_1.olm.instructions = (ref, map) => {
    return new MetalsWorkOrderInstruction(ref, map);
};
