import {Component, Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterModule} from '@angular/router';

import {IMenuItem, IMenuItemClass} from '@nxt/model-core';
import {PopButtonComponent} from '../../shared/buttons/pop-button.component';
import {IconsComponent} from '../../shared/icons/icons.component';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {PageService} from '../../shared/_services/page.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, PopButtonComponent, IconsComponent],
    selector: 'tab-bar',
    template: `
        <div *ngIf="tabs" [class]="class">
            <pop-button *ngIf="collapsed"
                        btnClass="btn-gray btn-sm"
                        iconName="heroicon-solid-chevron-down"
                        iconClass="h-4 w-4"
                        [disabled]="disabled"
                        [label]="getLabel()" [items]="tabs"
                        (select)="handleClick($event, true);"
            ></pop-button>
            <div *ngIf="!collapsed"
                 class="border-b border-gray-200 max-w-full overflow-hidden overflow-x-auto {{leftMargin}}">
                <nav class="-mb-px flex space-x-5" aria-label="Tabs">
                    <ng-container *ngFor="let item of tabs">
                        <ng-container *ngIf="uSvc.isRole(item.roles) && !item.hide">
                            <a [routerLink]="item.routerLink"
                               (click)="handleClick(item)" [style]="item.style"
                               [class]="item.active ? menuClass['active'] : menuClass['default']"
                            >
                                <icon *ngIf="item.icon" [name]="item.icon.name" [class]="item.icon.class" [style]="item.icon.style"></icon>
                                {{ item.label }}
                            </a>
                        </ng-container>
                    </ng-container>
                </nav>
            </div>
        </div>
    `
})
export class TabBarComponent extends OnDestroyPage implements OnChanges {
    @Input() tabs: IMenuItem[] = [];
    @Input() collapsible: boolean;
    @Input() leftMargin: string = 'ml-4';
    @Input() collapsed: boolean;
    @Input() class: string;
    @Input() disabled: boolean = false;
    menuClass: IMenuItemClass = {
        active: 'flex border-dark-500 text-dark-600 whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer',
        default: 'flex border-transparent text-gray-500 whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm hover:text-gray-700 hover:border-gray-300 cursor-pointer'
    }

    constructor(
        private pSvc: PageService,
        private router: Router,
        @Inject('UserService') public uSvc: any,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.collapsible && changes.collapsible) {
            this.pSvc.dimensions$
                .pipe(takeUntil(this.d$))
                .subscribe(dims => {
                    this.collapsed = dims.mobile;
                })
        }
    }

    getLabel() {
        return this.tabs.find(item => item.active)?.label;
    }

    handleClick(item: IMenuItem, nav?: boolean) {
        if (!nav) {
            if (item.click) {
                item.click();
            }
            if (item.routerLink) {
                this.router.navigate([item.routerLink]);
            }
        }
    }
}
