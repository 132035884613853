import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Thread, ThreadMessage} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {ObjectTypeIcon} from '@library/nxt/list/object-type-icon';
import {EThreadContext} from '@library/nxt/_services/messaging.service';

@Component({
    selector: 'context',
    standalone: true,
    imports: [
        CommonModule,
        ObjectTypeIcon
    ],
    template: `
        <div *ngIf="parent?.object && context !== EThreadContext.DETAILS" class="flex">
            <object-type-icon [type]="parent?.object?._type"></object-type-icon>
            <span class="text-xs ml-2">
                {{ name }}
<!--                <span *ngIf="secondary" class="ml-2 italic text-gray-300 text-xs">{{ secondary }}</span>-->
            </span>
        </div>
    `
})
export class ContextComponent implements OnChanges{
    @Input() parent: ThreadMessage|Thread;
    @Input() context: EThreadContext;
    EThreadContext = EThreadContext;
    name: string;
    secondary: string;

    ngOnChanges(changes: SimpleChanges) {
        if (this.parent) {
            this.name = this.parent.object?.name || this.parent.object?.email || this.parent.object?.phone || this.parent.object?.toString();
        }
    }
}
