import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {FireService, IFirestoreQuery} from '../_services/fire.service';
import {ScrollableDivDirective} from '../../shared/scrollable-div/scrollable-div';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {SpinnerComponent} from '../../shared/spinner/spinner.component';
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {map, takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {Base, Thread, ThreadMessage, loadObject} from '@nxt/model-core';
import {DateHeaderComponent} from '../../shared/header/date-header.component';
import {PipesModule} from '../../shared/_pipes/pipes';
import {MessagingService} from '../_services/messaging.service';
import {MessageItemComponent} from '../../../nxto/src/app/threads/_components/message-item.component';
import {AxekWorkItem} from '../../../nxto/src/app/_clients/axek/_components/work/axek-work-item';
import {ScrollableGenericList} from './scrollable-generic-list';

@Component({
    standalone: true,
    imports: [
        ScrollableDivDirective, PipesModule,
        SpinnerComponent, AsyncPipe, NgForOf,
        NgTemplateOutlet, NgIf, DateHeaderComponent, MessageItemComponent, AxekWorkItem, ScrollableGenericList
    ],
    selector: 'scrollable-thread-triggered-list',
    template: `
        <scrollable-generic-list
                #itemList
                class="max-h-screen-header-search overflow-y-auto"
                pageSize="15"
                [itemTemplate]="itemTemplate"
                label="Messages"
                [autoStart]="false"
                [debug]="true"
                [loadAll]="true"
                path="clients/{{cSvc.client_id}}/threadsmsgs"
                [watch]="true"
                (onItemsLoaded)="onItemsLoaded.emit()"
        ></scrollable-generic-list>

        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <message-item
                    [index]="i"
                    (onSelect)="mSvc.msg$.next($event)"
                    [selected]="selected?.id === item.id"
                    (onDelete)="handleDelete($event)"
                    [message]="item"
                    [parent]="mSvc.context$|async"></message-item>
        </ng-template>
    `
})
export class ScrollableThreadTriggeredList extends OnDestroyPage implements AfterViewInit {
    @ViewChild('itemList', {static:true}) itemList: ScrollableGenericList;
    @Output() onItemsLoaded: EventEmitter<any> = new EventEmitter<any>();
    thread: Thread;
    selected: ThreadMessage;

    constructor(
        public cSvc: ClientService,
        public pSvc: PageService,
        public mSvc: MessagingService
    ) {
        super();
    }

    ngAfterViewInit() {
        combineLatest([this.mSvc.msg$, this.mSvc.thread$])
            .pipe(takeUntil(this.d$))
            .pipe(map(res => {
                return {m: res[0], t: res[1]};
            }))
            .subscribe(result => {
                if (result.m?.id !== this.selected?.id) {
                    this.selected = result.m;
                }
                if (result.t?.id && result.t?.id !== this.thread?.id) {
                    if (!result.t._docRef) {
                        result.t._docRef = this.cSvc.client$.getValue()._docRef.collection('threads').doc(result.t.id);
                    }
                    this.thread = result.t;
                    this.itemList.baseQuery = [
                        {name: 'where', args: ['tRef','==', this.thread._docRef]},
                        {name: 'orderBy', args: ['date', 'desc']}
                    ];
                    this.itemList.load(this.itemList.baseQuery);
                }
            });
    }

    handleDelete(msg: ThreadMessage) {
        if (!this.itemList.items?.length || (this.itemList.items?.find(i => i.id === msg.id) && this.itemList.items?.length === 1)) {
            this.thread.delete();
        }
    }


    print() {

        let styles: string = 'styles.css';
        let tags = document.getElementsByTagName('link');
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].href.match(/styles.css$/)) {
                styles = tags[i].href;
                break;
            }
        }
        if (!styles) {
            styles = `${window.location.origin}/styles.css`;
        }
        var mywindow = window.open('', 'PRINT', 'height=800,width=1160');
        mywindow.document.write(`<html><head><title>Thread Details</title><link rel="stylesheet" href="${styles}"></head>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(this.itemList.eRef.nativeElement.innerHTML.replace(this.itemList.class, ''));
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
    }
}
