import {Component, OnInit} from '@angular/core';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {IMenuItem} from '@nxt/model-core';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {MessagingService} from '@library/nxt/_services/messaging.service';

@Component({
    selector: 'messaging-view-toggle',
    standalone: true,
    imports: [
        PopButtonComponent
    ],
    template:`
        <pop-button iconName="heroicon-arrows-right-left"
                    iconClass="ml-2 h-4 w-4 text-gray-500"
                    [items]="viewsMenu"
                    btnClass="border-transparent inline-flex mr-1 text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                    label="View"
        ></pop-button>
    `
})
export class MessagingViewToggle implements OnInit{
    viewsMenu: IMenuItem[];
    key: string = 'conversationMode';

    constructor(
        private lSvc: LocalStorageService,
        private mSvc: MessagingService
    ) {}

    ngOnInit(): void {
        this.mSvc.mode$.subscribe(() => {
            this.buildMenu();
        })
    }

    buildMenu() {
        this.viewsMenu = [
            {
                label: this.lSvc.globalState[this.key] === 'threads' ? 'Threads View - ✓' : 'Threads View',
                click: async () => {
                    await this.lSvc.saveGState(this.key,'threads');
                    this.mSvc.mode$.next('threads');
                    this.buildMenu();
                }
            },
            {
                label: this.lSvc.globalState[this.key] === 'messages' ? 'Messages View - ✓' : 'Messages View',
                click: async () => {
                    await this.lSvc.saveGState(this.key,'messages');
                    this.mSvc.mode$.next('messages');
                    this.buildMenu();
                }
            }
        ]
    }



}
