import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// @ts-ignore
import {environment as env_prod} from '@env/environment.prod';
import {Thread, User, Task, Filter, IMenuItem, QueueItem, Role} from '@nxt/model-core';
import {TabBarComponent} from '@library/nxt/tabs/tab-bar.component';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {UserService} from '@library/nxt/_services/user.service';
import {PageService} from '@library/shared/_services/page.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {AccountService} from '@library/nxt/_services/account.service';

import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {ClientService} from '@library/shared/_services/client.service';

@Component({
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        TabBarComponent, PipesModule, FooterButtonsComponent, IconsComponent
    ],
    template: `
        <div class="m-1 flex w-full">
            <div class="p-2 flex place-items-center">
                <tab-bar [tabs]="tabs" [collapsed]="true" [collapsible]="false"></tab-bar>
            </div>
            <div class="mt-1 flex rounded-md shadow-sm">
                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <icon name="heroicon-outline-search" class="h-5 w-5 text-gray-400"></icon>
                    </div>
                    <input type="text" [(ngModel)]="searchTerm"
                           class="focus:ring-dark-500 focus:border-dark-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                           placeholder="Search">
                </div>
            </div>
        </div>
        <div class="p-3 grid grid-cols-2 md:grid-cols-3 gap-2 bg-gray-100">
            <div (click)="toggleAll(active)"
                 class="p-1 cursor-pointer flex bg-dark hover:bg-gray-200 rounded-md content-center overflow-hidden">
                <div class="text-light text-sm">
                    Toggle All
                </div>
            </div>
            <div (click)="toggle(user)"
                 class="p-1 cursor-pointer flex bg-white hover:bg-gray-200 rounded-md content-center overflow-hidden {{ user._root ? 'bg-yellow-100':''}}"
                 *ngFor="let user of active | filterBy: { term: searchTerm, properties: ['name'] }">
                <icon name="heroicon-solid-check" *ngIf="isInList(user)"
                                      class="mr-1 h-5 w-5 text-green-500"></icon>
                <icon name="heroicon-solid-x" *ngIf="!isInList(user)" class="mr-1 h-5 w-5 text-gray-400"></icon>
                <div [class]="isInList(user) ? 'text-dark text-sm' : 'text-gray-500 text-sm'">
                    {{ user.name }}
                </div>
            </div>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class UserListDialog extends OnDestroyPage implements OnChanges {
    @Output() onClose: EventEmitter<User[]> = new EventEmitter<User[]>();
    @Input() label: string = '';
    @Input() parent: Thread | Task | Filter | QueueItem;
    @Input() property: 'agents'|'followers'|'roles'|string = 'followers';
    @Input() roles: Role[];
    searchTerm: string;
    tabs: IMenuItem[] = [];
    active: User[];
    path: string = '';
    uBR: any;
    buttons: IMenuItem[] = [];

    constructor(
        public uSvc: UserService,
        private pSvc: PageService,
        private cSvc: ClientService,
        private aSvc: AccountService
    ) {
        super();
    }

    ngOnChanges(changes?: SimpleChanges) {
        if (!this.active) {
            this.buildTabs();
        }
    }

    async buildTabs() {
        this.tabs = [];
        this.uBR = await this.aSvc.getUsersByRole();
        this.path = this.path || 'user';

        // Sort by the number of people in each role.  Presumably, the more important
        // roles will have more people.
        let roles: any[] = Object.assign([], this.roles||[])

        if (!this.roles) {
            for (let item of this.cSvc.client$.getValue().config.roles) {
                if (this.uBR[item.id]) {
                    roles.push(new Role(item));
                }
            }

            this.tabs.push(
                {
                    label: 'All Users',
                    active: (this.path === 'user'),
                    click: () => {
                        this.path = 'user';
                        this.buildTabs();
                    }
                },
                {
                    label: `Service Agents (${this.cSvc.client$.getValue().agents?.length||'0'})`,
                    active: (this.path === 'agents'),
                    click: () => {
                        this.path = 'agents';
                        this.buildTabs();
                    }
                }
            );
        }
        roles.sort((a,b) => {
            if (this.uBR[a.id]?.length > this.uBR[b.id]?.length) {
                return -1;
            } else {
                return 1;
            }
        });

        if (this.uBR[this.path] && this.uBR[this.path].length) {
            this.active = this.uBR[this.path].sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0);
        }

        roles.forEach((role:Role) => {
            if (this.uBR[role.id]?.length) {
                this.tabs.push({
                    label: `${role.label} (${this.uBR[role.id]?.length})`,
                    active: (this.path === role.id),
                    click: () => {
                        this.path = role.id;
                        this.buildTabs();
                    }
                });
            }
        });

        let positive: string = (this.property==='followers') ? 'Follow' : 'Assign Yourself';
        let negative: string = (this.property==='followers') ? 'Unfollow' : 'Remove Yourself';
        let label: string = this.parent[this.property].find(i => i.id === this.uSvc.user$.getValue()?.id) ? negative : positive;
        let action: 'add'|'remove' = (label===positive) ? 'add' : 'remove';

        this.buttons = [
            {
                label: 'Done',
                
                click: () => {
                    this.close();
                }
            },
            // {
            //     label: 'Save & Notify',
            //     class: 'btn-accent',
            //     click: async () => {
            //         this.parent[this.property] = [];
            //         this.close()
            //     }
            // },
            {
                label: label,
                class: 'btn-dark',
                click: () => {
                    this.toggle(this.uSvc.user$.getValue(), action);
                    this.close()
                }
            },
            {
                label: 'Remove All',
                class: 'btn-light',
                click: async () => {
                    this.parent[this.property] = [];
                    this.close()
                }
            }
        ];
    }

    async toggle(user: User, action?: 'add'|'remove') {
        this.pSvc.loading$.next(true);
        try {
            if ((!action || action==='remove') && this.parent[this.property]?.find(item => item.id === user.id)) {
                this.parent.remove(this.property, user.toMinJSON ? user.toMinJSON(true) : user);
            } else if (!action || action==='add') {
                this.parent.add(this.property as 'agents'|'followers'|'roles', user.toMinJSON ? user.toMinJSON(true) : user);
            }
        } catch (e) {
            console.warn(e);
            this.pSvc.alert$.next(e);
        }
        this.pSvc.loading$.next(false);;
    }

    async toggleAll(users: User[]) {
        let found: boolean;
        if (this.parent[this.property]?.length) {
            found = this.parent[this.property].find(f => users.find(u => u.id === f.id));
        }

        if (found) {
            // Remove all;
            this.parent[this.property] = [];
        } else {
            // Add all;
            this.parent[this.property] = users;
        }
    }

    isInList(user: User): boolean {
        return this.parent && this.parent[this.property]?.find(item => item.id === user.id) ? true : false;
    }

    close() {
        this.onClose.emit(this.parent[this.property]);
    }

}
