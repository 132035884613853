import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {NgIf, NgStyle} from '@angular/common';

@Component({
    standalone: true,
    imports: [ NgIf, ReactiveFormsModule, NgStyle ],
    selector: 'input-stacked-textarea',
    template: `
        <div *ngIf="form && controlName" [formGroup]="form"
             [class]="'relative mt-4 bg-white border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-dark-600 focus-within:border-dark-600 '+class">
            <label *ngIf="label"
                   class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white rounded-md text-xs font-medium text-gray-900">{{label}}</label>
            <textarea autocomplete="off"
                      #htmlTextAreaElement
                      [maxlength]="maxLength"
                      [ngStyle]="styles"
                      [readOnly]="readOnly"
                      (keyup)="handleKeyUp($event)"
                      (blur)="onBlur.emit(form.get(controlName).value)"
                      [placeholder]="placeholder||''"
                      [formControlName]="controlName"
                      [class]="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required') ?  'input-error block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm bg-transparent' : 'input-default block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm bg-transparent'"
            ></textarea>
            <p *ngIf="limit"
               [class]="(form?.get(controlName)?.value?.length > limit) ? 'px-3 pt-1 italic text-xs text-red-500' : 'px-3 pt-1 text-xs text-dark-500'"
            >
                {{ form?.get(controlName)?.value?.length }} of {{ limit }} characters.
            </p>
            <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('maxLength')"
               class="px-3 pt-1 italic text-xs text-red-500">
                {{ label || '' + ' exceeds max length.' }}
            </p>
            <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required')"
               class="px-3 pt-1 italic text-xs text-red-500">
                Required
            </p>
        </div>
    `
})
export class InputStackedTextAreaComponent implements OnChanges,OnInit {
    @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('htmlTextAreaElement') htmlTextAreaElement: ElementRef;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() limit: number;
    @Input() class: string = 'w-full mt-2';
    @Input() minHeight: string;
    @Input() maxLength: number;
    @Input() styles: any;
    @Input() readOnly: boolean;
    @Input() default:string;

    ngOnInit(){
        if (!this.form?.get(this.controlName)?.value && this.default) {
            this.form.get(this.controlName).setValue(this.default);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.minHeight) {
            this.styles = this.styles || {};
            this.styles.minHeight = this.minHeight;
        }
    }

    reset() {
        try {
            setTimeout(() => {
                this.form.get(this.controlName).setValue('');
                this.htmlTextAreaElement.nativeElement.focus();
                this.htmlTextAreaElement.nativeElement.blur();
                this.htmlTextAreaElement.nativeElement.focus();
                this.form.get(this.controlName).markAsUntouched();
                this.form.get(this.controlName).markAsPristine();
            });
        } catch (e) {
            console.warn(e);
        }
    }

    handleKeyUp(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            this.onEnter.emit();
        }
        this.onKeyup.emit(e);
    }
}
