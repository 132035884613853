import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Client} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [CommonModule, IconsComponent],
    selector: 'client-item',
    template: `
        <a *ngIf="client.active" (click)="onClick.emit(client)" [style.color]="client?.theme?.dark">
            <div class="flex w-full justify-between place-items-center">
                <div class="flex place-items-center">
                    <div class="mr-4 flex" style="width:75px;max-height:28px;">
                        <img *ngIf="client.theme.logo_dark"
                             [src]="client.theme.logo_dark"
                             class="object-contain"/>
                    </div>
                    <div *ngIf="!noText" class="text-xl">{{ client.name || client.name_key }}</div>    
                </div>
                <icon name="heroicon-solid-chevron-right" class="h-5 w-5"></icon>
            </div>
        </a>
    `
})
export class ClientItem {
    @Output() onClick: EventEmitter<Client> = new EventEmitter<Client>();
    @Input() client: Client;
    @Input() noText: boolean;
}

