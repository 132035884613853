import {Injectable} from '@angular/core';

import {olm, loadAll as coreLoadAll} from '@nxt/model-core';
import {olm as ewinObjectLoaderMap, loadAll as ewinLoadAll} from '@nxt/model-ewin';
import {olm as gmtlObjectLoaderMap, loadAll as gmtlLoadAll} from '@nxt/model-gmtl';
import {olm as axekObjectLoaderMap, loadAll as axekLoadAll} from '@nxt/model-axek';
import {olm as rcrtObjectLoaderMap, loadAll as rcrtLoadAll} from '@nxt/model-rcrt';
import {olm as eqipObjectLoaderMap, loadAll as eqipLoadAll} from '@nxt/model-eqip';

@Injectable()
export class ObjectLoaderService {
    constructor() {
    }

    getOLM(name_key: string) {
        switch (name_key) {
            case 'EWIN':
                return ewinObjectLoaderMap;
            case 'GMTL':
                return gmtlObjectLoaderMap;
            case 'AXEK':
                return axekObjectLoaderMap;
            case 'RCRT':
                return rcrtObjectLoaderMap;
            case 'EQIP':
                return eqipObjectLoaderMap;
            default:
                return olm;
        }
    }

    getLoadAll(name_key: string) {
        switch (name_key) {
            case 'EWIN':
                return ewinLoadAll;
            case 'GMTL':
                return gmtlLoadAll;
            case 'AXEK':
                return axekLoadAll;
            case 'RCRT':
                return rcrtLoadAll;
            case 'EQIP':
                return eqipLoadAll;
            default:
                return coreLoadAll;
        }
    }

}
