import {Input, Component} from '@angular/core';
import {EMedium, Thread, ThreadMessage} from '@nxt/model-core';
import {NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {IconsComponent} from '../../shared/icons/icons.component';


@Component({
    standalone: true,
    imports: [
        NgSwitch, NgSwitchCase, NgSwitchDefault, IconsComponent
    ],
    selector: 'message-medium-icon',
    template: `
        <ng-container [ngSwitch]="item?.medium">
            <icon name="heroicon-outline-mail" *ngSwitchCase="EMedium.EMAIL" [class]="class"></icon>
            <icon name="heroicon-outline-chat-alt-2" *ngSwitchCase="EMedium.CHAT" [class]="class"></icon>
            <icon name="heroicon-outline-chat-alt-2" *ngSwitchCase="EMedium.NOTE" [class]="class"></icon>
            <icon name="heroicon-outline-chat-alt" *ngSwitchCase="EMedium.TEXT" [class]="class"></icon>
            <icon name="heroicon-outline-globe-alt" *ngSwitchCase="EMedium.WEB" [class]="class"></icon>
            <icon name="heroicon-outline-archive" *ngSwitchCase="EMedium.ARCHIVE" [class]="class"></icon>
        </ng-container>
    `
})
export class MessageMediumIcon {
    @Input() item: ThreadMessage | Thread;
    @Input() class: string = 'w-12 h-12 text-gray-500';
    EMedium = EMedium;
}
