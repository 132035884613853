import {Injectable} from '@angular/core';

import {getApp} from '@angular/fire/app';
import {PageService} from '../../shared/_services/page.service';
import {ClientService} from '../../shared/_services/client.service';

// @ts-ignore
import {environment} from '@env/environment';
import {connectStorageEmulator, getStorage, ref, getDownloadURL, uploadBytes} from '@angular/fire/storage';

@Injectable()
export class ConsumerStorageService {
    storage: any;

    constructor(
        private pSvc: PageService
    ) {

        this.storage = getStorage(getApp(environment.appName));
        if (this.storage && environment.type === 'local' && this.storage?._settings?.host !== 'localhost:9199') {
            connectStorageEmulator(this.storage, 'localhost', 9199);
        }

    }

    async upload(filePath: string, file: File): Promise<string> {
        return new Promise((resolve, reject) => {

            const imageRef = ref(this.storage,filePath);
            uploadBytes(imageRef, file)
                .then(
                    async r => {
                        let downloadUrl: any = await getDownloadURL(imageRef);
                        resolve(downloadUrl?.split(/\?/)[0] + '?alt=media&t=' + Date.now());
                        this.pSvc.loading$.next(null);
                    },
                    err => {
                        this.pSvc.alert$.next(err);
                    }
                );

        });
    }

}
