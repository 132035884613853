import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {IMenuItem, ThreadMessage} from '@nxt/model-core';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {AttachmentViewerComponent} from './attachment-viewer.component';

@Component({
    standalone: true,
    imports: [CommonModule, FooterButtonsComponent, AttachmentViewerComponent],
    template: `
        <attachment-viewer class="break-all" [condensed]="true" [parent]="message"></attachment-viewer>
        <iframe #iframe class="h-screen-header border-0 w-full" [srcdoc]="iframeSrc"></iframe>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class HtmlViewerDialog implements OnChanges {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() message: ThreadMessage;
    @Input() html: string;
    iframeSrc: any;
    buttons: IMenuItem[] = [];
    replaced: boolean = false;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes?: SimpleChanges) {
        console.log(this.replaced);
        if (this.html && !this.replaced) {
            this.replaced = true;
            // Remove anchor tag links with target="_blank"
            this.html = this.html.replace(/ href/g, ' target="_blank" href');

            // Append images at the bottom.
            // if (this.message?.files?.length) {
            //     this.message.files.forEach((file:CloudFile) => {
            //         if (file.name.match(/jpeg|jpg|png|webp|gif|tiff|bmp|svg/)) {
            //             this.html += `<hr><img src="${file.url}">`;
            //         } else {
            //             this.html += `<hr><a href="${file.url}">${file.name}</a>`;
            //         }
            //     });
            // }

            this.iframeSrc = this.sanitizer.bypassSecurityTrustHtml(this.html);
        }
        if (this.message) {
            this.buttons = [
                {
                    label: 'Close',
                    click: () => {
                        this.onClose.emit();
                    }
                },
                {
                    label: 'Print',
                    class: 'btn-dark',
                    hide: !this.message,
                    click: () => {

                        let recipients: string = this.message.recipients?.map(item => {
                            return item.email || item.name;
                        }).join('; ');
                        let cc: string = this.message.cc?.map(item => {
                            return item.email || item.name;
                        }).join('; ');

                        var mywindow = window.open('', 'PRINT', 'height=820,width=1180');
                        mywindow.document.write(`<html><head><title>${this.message.subject}</title>`);
                        mywindow.document.write('</head><body >');
                        mywindow.document.write(`
        
                    <div style="font-weight: bold; font-size: large; margin-bottom:4px;">${this.message.subject}</div>
                    <div style="font-weight: normal; font-style: italic; margin-bottom:4px;">From: ${this.message.creator?.email || this.message.creator.name}</div>
                    <div style="font-weight: normal; font-style: italic; margin-bottom:4px;">To: ${recipients}</div>
                    <div style="font-weight: normal; font-style: italic; margin-bottom:4px;">Cc: ${cc}</div>
                    
                    ${this.message.html||this.message.message}
                    
                `);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        // mywindow.print();
                        // mywindow.close();


                        return true;
                    }
                }
            ];
        }
    }

}
