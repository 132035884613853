export let rcrtLazyComponents: any = {
   'rcrt-contact-us': {
      t: 'Contact Us/Help Form',
      client: 'RCRT',
      loader: () => import('./contact-us/rcrt-contact-us'),
      config: () => import('./contact-us/rcrt-contact-us.config')
   },
   'rcrt-blog-3-column': {
      t: 'Blog: 3-Column',
      d: 'Grid of 3 most recent blog articles, or those manually chosen',
      client: 'RCRT',
      loader: () => import('./blog/rcrt-blog-3-column'),
      config: () => import('./blog/rcrt-blog-3-column')
   },
   'rcrt-testimonial-with-overlapping-img': {
      t: 'Testimonial With Overlapping Image',
      d: 'Pulled from Tailwind UI.',
      img: 'https://firebasestorage.googleapis.com/v0/b/nxtpart-dev.appspot.com/o/clients%2Fz2d036v742kzk1iedplupu%2Fcms%2Fbyx2DlNjsX.png?alt=media',
      client: 'RCRT',
      loader: () => import('./testimonials/rcrt-testimonial-with-overlapping-img'),
      config: () => import('./testimonials/rcrt-testimonial-with-overlapping-img')
   },
   'rcrt-home-hero': {
      t: 'Recruit Messenger Home Page Hero',
      client: 'RCRT',
      loader: () => import('./home-hero/rcrt-home-hero'),
      config: () => import('./home-hero/rcrt-home-hero')
   },
   'rcrt-public-event': {
      t: 'Event Highlight Grid',
      d: 'Grid of event icons leading to public-facing event details pages, showing how good our data is.',
      client: 'RCRT',
      loader: () => import('./public-events/rcrt-public-events'),
      config: () => import('./public-events/rcrt-public-events')
   },
   'rcrt-multi-column-content-feature': {
      t: 'Multi-Content Column Feature',
      d: 'Multi-column content feature. Source: https://tailwindui.com/components/marketing/sections/feature-sections',
      client: 'RCRT',
      loader: () => import('./multi-column-content-feature/rcrt-multi-column-content-feature'),
      config: () => import('./multi-column-content-feature/rcrt-multi-column-content-feature')
   },
   'rcrt-simple-cta': {
      t: 'Simple Call-to-Action',
      img: 'https://firebasestorage.googleapis.com/v0/b/nxtpart-dev.appspot.com/o/clients%2Fz2d036v742kzk1iedplupu%2Fcms%2FiZjOoV6hHs.png?alt=media',
      d: 'Simple, single-button call-to-action. Source: https://tailwindui.com/components/marketing/sections/cta-sections',
      client: 'RCRT',
      loader: () => import('./simple-cta/rcrt-simple-cta'),
      config: () => import('./simple-cta/rcrt-simple-cta')
   },
   'rcrt-offset-feature-list': {
      t: 'Offset Feature List',
      img: 'https://firebasestorage.googleapis.com/v0/b/nxtpart-dev.appspot.com/o/clients%2Fz2d036v742kzk1iedplupu%2Fcms%2Fk9sc97VZw8.png?alt=media',
      d: 'Offset feature list. Source: https://tailwindui.com/components/marketing/sections/feature-sections',
      client: 'RCRT',
      loader: () => import('./feature-list/rcrt-offset-feature-list'),
      config: () => import('./feature-list/rcrt-offset-feature-list')
   },
   'rcrt-broad-panel-with-screenshot': {
      t: 'Broad Panel With Screenshot',
      img: 'https://firebasestorage.googleapis.com/v0/b/nxtpart-dev.appspot.com/o/clients%2Fz2d036v742kzk1iedplupu%2Fcms%2Fm3GHZZSWrX.png?alt=media',
      d: 'Multi-column content feature. Source: Tailwind UI - Marketing - Calls to Action - Archived',
      source: 'https://tailwindui.com/components/marketing/sections/cta-sections?include=archived#component-32f0e177b92be4e27503af09f23ab637',
      client: 'RCRT',
      loader: () => import('./broad-panel-with-screenshot/rcrt-broad-panel-with-screenshot'),
      config: () => import('./broad-panel-with-screenshot/rcrt-broad-panel-with-screenshot')
   },
   'rcrt-footer': {
      t: 'Recruit Messenger Footer',
      d: 'Standard footer for RM site.',
      client: 'RCRT',
      loader: () => import('./footer/rcrt-footer'),
      config: () => import('./footer/rcrt-footer.config')

   },
   'rcrt-nxt-product-details': {
      t: 'Product editor component used in RCRT only.',
      client: 'RCRT',
      loader: () => import('../../../../nxto/src/app/_clients/rcrt/_components/rcrt-product-details')
   },
   'rcrt-nxt-order-details': {
      t: 'Order details component used in NXT only.',
      client: 'NXT',
      loader: () => import('../../../../nxto/src/app/_clients/rcrt/_components/rcrt-order-details')
   },
};
