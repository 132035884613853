import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import {ClientService} from '../../shared/_services/client.service';

@Injectable()
export class AuthGuard  {
    constructor(
        private router: Router,
        private cSvc: ClientService
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise(async (resolve) => {

            const isUser = route.data?.isUser;
            this.cSvc.u$
                .subscribe(
                    async u => {
                        if (u === null) {
                            await this.router.navigate([`/login`], {queryParams: {redirect: state.url}});
                            resolve(false);
                        } else if (u?.uid) {
                            resolve(true);
                        }


                }
            );

        });
    }
}
