import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/compat/auth';
import {USE_EMULATOR as USE_FIRESTORE_EMULATOR} from '@angular/fire/compat/firestore';
import {USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';

import {AppComponent} from './app.component';

import {MessagingService} from '@library/nxt/_services/messaging.service';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, RouterOutlet, Routes} from '@angular/router';

import {ServiceWorkerModule} from '@angular/service-worker';
import {ClientGuard} from '@library/shared/_services/client.guard';

import {gmtlLazyComponents} from '../../../gmtl/src/app/_components/gmtl.lazy.components';
import {ewinLazyComponents} from '../../../ewin/src/app/_components/ewin.lazy.components';
import {axekLazyComponents} from '../../../axek/src/app/_components/axek.lazy.components';
import {nxcoLazyComponents} from '../../../nxco/src/app/_components/nxco.lazy.components';
import {mvrkLazyComponents} from "../../../mvrk/src/app/_components/mvrk.lazy.components";
import {eqipLazyComponents} from '../../../eqip/src/app/_components/eqip.lazy.components';
import {rcrtLazyComponents} from '../../../rcrt/src/app/_components/rcrt.lazy.components';
import {sharedLazyComponents} from '@library/shared.lazy.components';

import {ThemeService} from '@library/shared/_services/theme.service';
import {ClientService} from '@library/shared/_services/client.service';
import {PageService} from '@library/shared/_services/page.service';
import {UserService} from '@library/nxt/_services/user.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {AccountService} from '@library/nxt/_services/account.service';
import {AuthGuard} from '@library/nxt/_services/auth.guard';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';


import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {ObjectLoaderService} from '@library/nxt/_services/object.loader.service';
import {NxtMenuService} from '@library/nxt/_services/nxt.menu.service';
import {GlobalErrorHandler} from '@library/shared/_services/global-error-handler';
import {NxtTemplateLoaderService} from '@library/nxt/_services/template.loader.service';
// @ts-ignore
import {environment} from '@env/environment';
import {NotificationDialog} from '@library/shared/alert/notification.dialog';
import {ProgressComponent} from '@library/shared/progress/progress.component';
import {FlexComponent} from '@library/shared/flex/flex.component';
import {NxtoHeaderComponent} from '@library/nxt/navigation/nxto-header.component';
import {SidebarComponent} from '@library/nxt/navigation/sidebar.component';
import {ModalDialog} from '@library/shared/modal/modal.dialog';
import {NxtAllSearchDialog} from '@library/nxt/search/nxt-all-search.dialog';
import {ThreadDetailComponent} from './threads/_components/thread-detail.component';

import {ThreadOptionsService} from '@library/nxt/_services/thread-options.service';
import {nxtoLazyComponents} from './nxto.lazy.components';
import {cbdibLazyComponents} from "../../../cbdib/src/app/_components/cbdib.lazy.components";
import {ConsumerStorageService} from '@library/consumer/_services/consumer.storage.service';
import {NxtTosHeader} from '@library/nxt/terms/nxt-tos-header';

const routes: Routes = [
    {
        path: ':name_key/cms',
        loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/company',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/marketing',
        loadChildren: () => import('./marketing/marketing.routes.module').then(m => m.MarketingPagesRoutingModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/activate',
        loadChildren: () => import('./activate/activate.module').then(m => m.ActivatePagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/tasks',
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TaskPagesModule),
        canActivate: [ClientGuard],
        data: {isUser: true}
    },
    {
        path: ':name_key/threads',
        loadChildren: () => import('./threads/threads.module').then(m => m.ThreadPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/orders',
        loadChildren: () => import('./commerce/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/work',
        loadChildren: () => import('./commerce/work/work.module').then(m => m.WorkModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/quotes',
        loadChildren: () => import('./commerce/quotes/quotes.module').then(m => m.QuotesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/data',
        loadChildren: () => import('./data/data.pages.module').then(m => m.DataPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate: [ClientGuard]
    },
    {
        path: '**',
        loadChildren: () => import(`@library/shared/flex/flex.page`).then(m => m.FlexPageModule),
        canActivate: [ClientGuard]
    }
];

@NgModule({
    imports: [
        BrowserModule, HttpClientModule,
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
            scrollPositionRestoration: 'enabled'
        }),
        AngularFireModule.initializeApp(environment.firebase, environment.appName),
        AngularFirestoreModule, AngularFireAuthModule, AngularFireStorageModule,
        AngularFireAnalyticsModule,
        ServiceWorkerModule.register('/firebase-messaging-sw.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:6000'
        }),
        NotificationDialog, ProgressComponent, FlexComponent, NxtoHeaderComponent, SidebarComponent,
        RouterOutlet, ModalDialog, NxtAllSearchDialog, ThreadDetailComponent, NxtTosHeader
    ],
    providers: [
        ClientService, ThemeService, PageService, MessagingService, LocalStorageService, UserService, ConsumerStorageService,
        {provide: 'UserService', useFactory:  (cSvc: ClientService) => new UserService(cSvc), deps: [ClientService]},
        {provide: 'MenuService', useFactory: (cSvc: ClientService) => new NxtMenuService(cSvc), deps: [ClientService]},
        {
            provide: 'ObjectLoaderService',
            useFactory: () => new ObjectLoaderService(),
            deps: [ClientService]
        },
        {
            provide: 'LazyComponents',
            useValue: [
                sharedLazyComponents,
                gmtlLazyComponents,
                ewinLazyComponents,
                axekLazyComponents,
                nxcoLazyComponents,
                mvrkLazyComponents,
                nxtoLazyComponents,
                cbdibLazyComponents,
                eqipLazyComponents,
                rcrtLazyComponents
            ]
        },
        {
            provide: 'TemplateLoaderService',
            useFactory: (mSvc: MessagingService, cSvc: ClientService, pSvc: PageService) => new NxtTemplateLoaderService(mSvc,cSvc,pSvc),
            deps: [MessagingService, ClientService, PageService]
        },
        FireService, AuthGuard, AccountService, ClientGuard,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: USE_AUTH_EMULATOR, useValue: environment.type === 'local' ? ['http://localhost:9099', 9099] : undefined },
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.type === 'local' ? ['localhost', 8080] : undefined },
        { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.type === 'local' ? ['localhost', 5001] : undefined },
        { provide: 'AllSearchDialog', useValue: NxtAllSearchDialog },
        {
            provide: 'ThreadOptionsService',
            useFactory: (cSvc: ClientService, uSvc: UserService, fSvc: FireService, pSvc: PageService, AllSearchDialog: 'AllSearchDialog') => new ThreadOptionsService(cSvc,uSvc,fSvc,pSvc,AllSearchDialog),
            deps: [ClientService, UserService, FireService, PageService, 'AllSearchDialog']
        }
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
}
