import {Injectable} from '@angular/core';

import {MessagingService} from '../../nxt/_services/messaging.service';
import {Contact, Document, EMedium, IMenuItem, User} from '@nxt/model-core';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {Subscription} from 'rxjs';

@Injectable()
export class NxtTemplateLoaderService {

    constructor(
        private mSvc: MessagingService,
        private cSvc: ClientService,
        private pSvc: PageService
    ) {
        let sub: Subscription;
        this.cSvc.client$
            .subscribe(c => {
                if (c?.id) {
                    this.mSvc.templates$.next([]);
                    sub?.unsubscribe();
                    sub = this.mSvc.context$
                        .subscribe(async p => {
                            await this.loadTemplates(p?._type);
                        });
                }
            });
    }

    async loadTemplates(type: string,modal:boolean=true):Promise<IMenuItem[]> {
        if (type) {
            type = type?.replace(/s$/,'');
            if (!this.mSvc.templates$.getValue()?.length || !this.mSvc.templates$.getValue()?.find(t => t.value?.data_argument===type)) {
                //had to make this for eqip vendor subquotes otherwise templates won't load
                //TODO change templates to have data_argument of subquotes
                const adjustedType = type === 'subquote' ? 'quote' : type.replace(/s$/, '');
                let templateQuery = [
                    {name: 'where', args: ['data_argument', '==', adjustedType]}
                    , {name: 'where', args: ['type', '==', 'manual']}
                    , {name: 'where', args: ['active', '==', true]}
                ];
                let result: any[] = await this.cSvc.callAPI('/cms/documents', 'post', {templateQuery: templateQuery});
                result = (result?.map(item => {
                    let d: Document = new Document(item);
                    return {
                        label: d.name,
                        value: d,
                        click: async () => {
                            this.mSvc.templateDocument$.next(d);

                            try {

                                let parent: any = this.mSvc.context$.getValue();
                                switch (parent?._type) {
                                    case 'orders':
                                        await parent.loadAll();
                                        if (!parent.shipments?.length && !parent.items?.find(i => i.subscription)) {
                                            this.pSvc.notification$.next({
                                                title: 'No Shipping!',
                                                message: 'This quote has no shipping! Are you sure this is right?'
                                            });
                                        }
                                        break;
                                    case 'quotes':
                                        await parent.loadAll();
                                        if (!parent['items'] || !parent['items'].length) {
                                            throw {
                                                title: 'Cannot Send Quote!',
                                                message: `There are no items in this quote.`
                                            }
                                        }
                                        if (!parent['shipments'] || !parent['shipments'].length && this.cSvc.name_key !== 'EQIP') {
                                            throw {
                                                title: 'Cannot Send Quote!',
                                                message: `There are no shipments in this quote.`
                                            }
                                        }
                                        if (!parent['locked'] && d.template_id === 'quote.html.js') {
                                            await parent.update({locked:true});
                                            this.pSvc.notification$.next({
                                                title: 'Locking Quote!',
                                                message: 'The quote has been locked so it is not altered from the version sent to the customer.'
                                            });
                                        }
                                        break;
                                }

                                let recip: any;
                                if (parent instanceof Contact || parent instanceof User) {
                                    recip = parent;
                                } else if (parent?.contact) {
                                    recip = parent.contact;
                                }

                                await this.mSvc.startTemplateMessage(
                                    parent,
                                    d.template_id,
                                    d.template_id,
                                    d.metadata.sms ? EMedium.TEXT : EMedium.EMAIL,
                                    recip ? [recip] : [],
                                    modal,
                                    d.metadata,
                                    d);


                            } catch (e) {
                                console.warn(e);
                                this.pSvc.alert$.next(e);
                            }
                        }
                    };
                }) || []);
                if (result?.length) {
                    result.push({
                        label: 'No Template',
                        click: async () => {

                            this.mSvc.templateDocument$.next(null);
                            let parent: any = this.mSvc.context$.getValue();
                            let recip: any;
                            if (parent instanceof Contact || parent instanceof User) {
                                recip = parent;
                            } else if (parent?.contact) {
                                recip = parent.contact;
                            }

                            await this.mSvc.startTemplateMessage(parent,null, null, EMedium.EMAIL, recip ? [recip] : [], true);

                        }
                    });
                } else {
                    // Flush any previous templates if the current context has no templates.
                    this.mSvc.templateDocument$.next(null);
                }
                this.mSvc.templates$.next(result);
            }
        }
        return this.mSvc.templates$.getValue();
    }

}


