import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {AngularEditorComponent, AngularEditorConfig, AngularEditorModule} from '@kolkov/angular-editor';
import {OnDestroyPage} from '../_inherited/ondestroy.page';
import {ElementElement} from 'soap/lib/wsdl/elements';

@Component({
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule, AngularEditorModule
    ],
    selector: 'input-html',
    template: `
        <div *ngIf="form && controlName" [formGroup]="form" class="relative mt-6">
            <div *ngIf="label"
                 class="absolute -top-2 z-10 left-2 -mt-px inline-block px-1 bg-white rounded-md text-xs font-medium text-gray-900">{{label}}</div>

            <angular-editor #editor [config]="config"
                            [formGroup]="form"
                            [formControlName]="controlName"
                            (focus)="focus($event)"
                            (keyup)="handleKeyUp($event)"
            ></angular-editor>

            <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required')"
               class="px-3 pt-1 italic text-xs text-red-500">
                {{ label + ' is required.'  }}
            </p>
            <input [id]="controlName" [hidden]="true" [formControlName]="controlName"/>
        </div>
    `
})
export class InputHtmlComponent extends OnDestroyPage implements OnInit, OnChanges {
    @ViewChild('editor', {static: false}) editor: AngularEditorComponent;
    @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() showToolbar: boolean = false;
    @Input() customConfig: AngularEditorConfig = {};
    show: boolean = false;
    config: AngularEditorConfig = {};

    constructor() {
        super();
    }

    ngOnInit() {
        ['form', 'controlName'].forEach(prop => {
            if (!this[prop]) {
                console.warn(`Cannot render text input. Missing value for ${prop}`);
            }
        });

    }

    ngOnChanges(changes: SimpleChanges) {

        this.config = Object.assign({
                sanitize: false,
                editable: true,
                spellcheck: true,
                enableToolbar: this.showToolbar,
                placeholder: '',
                showToolbar: this.showToolbar,
                translate: 'no',
                width: '100%',
                height: '200px',
                minHeight: '200px',
                toolbarHiddenButtons: [
                    [
                        'subscript',
                        'superscript',
                        'justifyFull',
                        'indent',
                        'outdent',
                        'fontName'
                    ],
                    [
                        'fontSize',
                        'backgroundColor',
                        'customClasses',
                        'insertImage',
                        'insertVideo'
                    ]
                ]
            }, this.customConfig||{});

    }

    handleKeyUp(e) {
        this.onKeyup.emit(this.form?.get(this.controlName).value);
        this.resize(this.editor.textArea);
    }

    resize(elementRef: ElementRef) {
        elementRef.nativeElement.style.height = '0';
        elementRef.nativeElement.style.height = elementRef.nativeElement.scrollHeight + 'px';
    }

    focus(e) {
        // e?.addEventListener("paste", function(e) {
        //     // cancel paste
        //     e.preventDefault();
        //     // get text representation of clipboard
        //     let text = (e.originalEvent || e).clipboardData.getData('text/plain');
        //     // insert text manually
        //     document.execCommand("insertHTML", false, text);
        // });
    }
}
