export const environment= {
    production: false,
    appName: 'NxtOrder',
    type: 'dev',
    firebase: {
        apiKey: 'AIzaSyBupugI9b9-eiao0-zTCrZ5ZvLQUn2K3LY',
        authDomain: 'dev.nxtorder.co',
        databaseURL: 'https://nxtpart-dev.firebaseio.com',
        projectId: 'nxtpart-dev',
        storageBucket: 'nxtpart-dev.appspot.com',
        messagingSenderId: '391091977702',
        appId: '1:391091977702:web:f86fc817930df371'
    },
    url: 'https://dev.nxtorder.co',
    public_url: 'https://dev.nxtorder.co',
    fnUrl: 'https://us-central1-nxtpart-dev.cloudfunctions.net/api',
    storageUrl: 'https://firebasestorage.googleapis.com/v0/b/nxtpart-dev.appspot.com/o/',
    push_public_key: "BEYqp0lwPbFRH1TTpKc910tlK1p7r7RMVWHJIaGwY6_5CWglSFbhCA9KnAafthoKvLIjcChdl6eTBA8soK-dnxs",
    default_client: {
        theme: {
            dark: '#575757',
            light: '#ffffff',
            accent: '#4584FF',
            icon: '/assets/icons/48.png',
            logo_light: '/assets/icons/48.png'
        },
        phone: '',
        id: 'd18ma0fdask9lys4iu9030',
        name_key: 'NXTO',
        settings: [
            {id: 'email', name: 'Email'},
            {id: 'chat', name: 'Chat'}
        ]
    },
    required_settings: [
        {id: 'email', name: 'Email'},
        {id: 'chat', name: 'Chat'},
        {id: 'mixpanel', name: 'MixPanel'},
        {id: 'drip', name: 'Drip'},
        {id: 'hours', name: 'Office Hours'},
        {id: 'stripe', name: 'Stripe'},
        {id: 'paypal', name: 'PayPal'},
        {id: 'order-stages', name: 'Order Stages'}
    ],
    nxtProjectId: 'nxtpart-dev',
    phone: '',
    stripe: '',
    paypal: {client_id: ''},
    algolia: {
        appId: 'R4QRFG8HNF',
        searchKey: '329314321b79d35da39f0c7e45ddcaf3'
    },
    login: {
        microsoft: false,
        google: true
    },
    pageServiceSiteId: ''
};
