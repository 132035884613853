import {Component, EventEmitter, Output, ViewChild, Input} from '@angular/core';

import {getManualFilterSet, getUsersByRoleFilterSets, IAlgoliaFilterSet} from '../../shared/search/algolia-search.component';
import {IAlgoliaSearchResults} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {LocalStorageService} from '../../shared/_services/local-storage.service';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {FireService} from '../_services/fire.service';
import {Router} from '@angular/router';
import {AccountService} from '../_services/account.service';
import {InputToggleNoformComponent} from '../../shared/input/input-toggle-noform.component';
import {MessagingViewToggle} from '../../../nxto/src/app/threads/_components/messaging-view-toggle';
import {AlgoliaSearchComponent} from '../../shared/search/algolia-search.component';
import {take} from 'rxjs/operators';
import {format} from 'date-fns';
import {DateRangeDialog} from '../../shared/input/date-range.dialog';

@Component({
    standalone: true,
    imports: [
        CommonModule, AlgoliaSearchComponent,
        InputToggleNoformComponent, MessagingViewToggle
    ],
    selector: 'message-search',
    template: `
        <div class="flex w-full">
            <div class="grow">
                <algolia-search-component
                        #searchComponent
                        index="threadsmsgs"
                        placeholder="Search Messages"
                        [saveSearch]="saveSearch"
                        [filterSet]="filterSets"
                        (onResults)="onResults.emit($event)"
                        (onClear)="onClear.emit($event)"
                        (onSearching)="onSearching.emit($event)"
                ></algolia-search-component>
            </div>
            <div class="pt-2" *ngIf="!hideToggle">
                <messaging-view-toggle></messaging-view-toggle>
            </div>
        </div>
    `
})
export class MessageSearchComponent {
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean]>();
    @Output() onClear: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSearching: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('searchComponent', {static: true}) searchComponent: AlgoliaSearchComponent;
    @Input() hitsPerPage: number = 50;
    @Input() hideToggle: boolean = false;
    @Input() saveSearch: boolean = true;
    filterSets: IAlgoliaFilterSet[];

    constructor(
        public lSvc: LocalStorageService,
        private cSvc: ClientService,
        private pSvc: PageService,
        private fSvc: FireService,
        private router: Router,
        private aSvc: AccountService
    ) {

    }

    async loadFilters() {
        this.pSvc.loading$.next(true);
        let now: number = Date.now();
        let start: Date = new Date();
        let end: Date = new Date();

        let filterSets:IAlgoliaFilterSet[] = [{
            name: 'Basic Filters',
            buttons: [
                {
                    label: 'Custom Date Range',
                    class: 'btn-dark',
                    click: () => {
                        this.pSvc.modal$.next({
                            label: 'Custom Date Range',
                            component: DateRangeDialog,
                            styles: {
                                width: '600px'
                            },
                            onLoaded: (comp: DateRangeDialog) => {
                                comp.start = start;
                                comp.end = end;
                                comp.onClose
                                    .pipe(take(1)).subscribe(
                                    (result: {start:Date, end:Date}) => {
                                        
                                        if (result) {
                                            start = result.start;
                                            end = result.end;
                                            this.searchComponent.builder.remove('filterItems', {id:'CUSTOM'});
                                            this.searchComponent.toggleFilter({
                                                id: 'CUSTOM',
                                                name: `${format(start,'MMM d, yyyy')} > ${format(end,'MMM d, yyyy')}`,
                                                color: '#505cac',
                                                contrast: '#ffffff',
                                                key: 'Date',
                                                type: 'numeric',
                                                key_unique: true,
                                                more: `date>${start.valueOf()}`,
                                                value: `date<${end.valueOf()}`
                                            });
                                        }
                                    }
                                );
                                comp.ngOnChanges();
                            }
                        });
                    }
                }
            ],
            items: [
                {
                    id: 'web',
                    name: 'Web Submissions',
                    color: '#ab2317',
                    contrast: '#f3e9e9',
                    key: 'Web',
                    key_unique: true,
                    type: 'facet',
                    value: ['medium:web']
                },
                {
                    id: 'unread',
                    name: 'Unread Messages',
                    color: '#dfdfdf',
                    contrast: '#000000',
                    key: 'Unread',
                    key_unique: true,
                    type: 'facet',
                    value: ['unread:true'],
                    more: ['active:true']
                },
                {
                    id: 'unassigned',
                    name: 'Unassigned',
                    color: '#FFEDD5',
                    contrast: '#000000',
                    key: 'Unassigned',
                    key_unique: true,
                    type: 'facet',
                    value: ['unassigned:true']
                },
                {
                    id: 'inbound',
                    name: 'Inbound',
                    color: '#dfdfdf',
                    contrast: '#000000',
                    key: 'direction',
                    key_unique: true,
                    type: 'facet',
                    value: ['inbound:true']
                },
                {
                    id: 'outbound',
                    name: 'Outbound',
                    color: '#dfdfdf',
                    contrast: '#000000',
                    key: 'direction',
                    key_unique: true,
                    type: 'facet',
                    value: ['inbound:false']
                },
                {
                    id: 'read',
                    name: 'Read Messages',
                    color: '#dfdfdf',
                    contrast: '#000000',
                    key: 'Unread',
                    key_unique: true,
                    type: 'facet',
                    value: ['unread:false'],
                    more: ['active:true']
                },
                {
                    id: '30MIN',
                    name: 'Last 30 Minutes',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * .5))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '1HR',
                    name: 'Last 1 Hour',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 1))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '3HRS',
                    name: 'Last 3 Hours',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 3))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '6HRS',
                    name: 'Last 6 Hours',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 6))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '12HRS',
                    name: 'Last 12 Hours',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 12))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '24HRS',
                    name: 'Last 24 Hours',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 24))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '48HRS',
                    name: 'Last 2 Days',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 48))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '72HRS',
                    name: 'Last 3 Days',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 72))}`] // 3600000 = 1hr in milliseconds
                },
                {
                    id: '7DAYS',
                    name: 'Last 7 Days',
                    color: '#505cac',
                    contrast: '#ffffff',
                    key: 'Date',
                    type: 'numeric',
                    key_unique: true,
                    value: [`last_date>${(now - (3600000 * 24 * 7))}`] // 3600000 = 1hr in milliseconds
                }
            ]
        }];

        filterSets.push(await getManualFilterSet(this.cSvc, this.fSvc, this.pSvc, this.router));
        filterSets = filterSets.concat(await getUsersByRoleFilterSets(this.aSvc, this.cSvc));
        this.filterSets = filterSets;
        this.pSvc.loading$.next(false);
    }

    ngOnInit() {
        this.loadFilters();
    }


}
