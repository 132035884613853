import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Client, IMenuItem, User} from '@nxt/model-core';
import {PageService} from '@library/shared/_services/page.service';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {ClientService} from '../../shared/_services/client.service';
import {UserService} from '../_services/user.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
    standalone: true,
    imports: [
        CommonModule,
        FooterButtonsComponent
    ],
    template: `
        <div #agreementDiv>
            <div class="py-10 px-3" #agreementDiv [innerHTML]="html">
                
            </div>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class NxtTosAgreementDialog implements OnInit{
    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() client: Client;
    @ViewChild('agreementDiv') agreementDiv: ElementRef;
    user: User;
    terms: any;
    html: any;
    buttons: IMenuItem[] = [
        {
            label: 'Close',
            click: () => {
                this.onClose.emit();
            }
        },
        {
            label: 'Agree',
            class: 'btn-dark',
            click: async () => {
                try {
                    this.user.metadata.terms = {
                        version: this.terms.version,
                        date: Date.now()
                    }
                    await this.cSvc.callAPI(`/user/${this.user.id}`, 'post', this.user.toJSON());
                    this.onClose.emit(true);
                } catch (e) {
                    this.pSvc.alert$.next(e);
                }
            }
        },
        {
            label: 'Print',
            click: () => {

                var mywindow = window.open('', 'PRINT', 'height=400,width=600');

                mywindow.document.write('<html><head><title>Terms of Service</title>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(this.agreementDiv.nativeElement.innerHTML);
                mywindow.document.write('</body></html>');
                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/
                mywindow.print();
                mywindow.close();

                return true;
            }
        }
    ]

    constructor(
        public pSvc: PageService,
        private cSvc: ClientService,
        private uSvc: UserService,
        private sanitizer: DomSanitizer
    ) {

    }

    ngOnInit() {
        this.user = this.uSvc.user$.getValue();
        this.terms = this.cSvc.terms$.getValue();
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.terms.terms);
    }

}
