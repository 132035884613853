import {Component, Input} from '@angular/core';
import {NgSwitch, NgSwitchCase} from '@angular/common';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    selector: 'object-type-icon',
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        IconsComponent
    ],
    template: `
        <ng-container [ngSwitch]="type">

            <icon name="heroicon-solid-fire"
                    *ngSwitchCase="'quotes'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-outline-credit-card"
                    *ngSwitchCase="'contacts'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="custom-flight-takeoff"
                    *ngSwitchCase="'trips'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-outline-clipboard-check"
                    *ngSwitchCase="'tasks'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-outline-currency-dollar"
                    *ngSwitchCase="'orders'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-outline-users"
                    *ngSwitchCase="'users'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-outline-folder"
                    *ngSwitchCase="'pages'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-outline-cloud-upload"
                    *ngSwitchCase="'deployments'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-outline-user-group"
                    *ngSwitchCase="'groups'"
                    class="w-4 h-4 text-gray-500"></icon>
            <icon name="heroicon-wrench"
                    *ngSwitchCase="'work_orders'"
                    class="w-4 h-4 text-gray-500"></icon>

        </ng-container>
    `
})
export class ObjectTypeIcon {
    @Input() type: string;
}
