import {Component, EventEmitter, Output, OnInit, Input, ViewChild} from '@angular/core';

import {AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, IAlgoliaSearchResults} from '@nxt/model-core';
import {CommonModule} from '@angular/common';

import {
    getUsersByRoleFilterSets, IAlgoliaFilterSet
} from '../../shared/search/algolia-search.component';
import {AccountService} from '../_services/account.service';
import {AlgoliaSearchComponent} from '../../shared/search/algolia-search.component';
import {
    endOfDay,
    endOfMonth,
    endOfYear, format,
    startOfDay,
    startOfMonth,
    startOfYear,
    subMonths,
    subYears
} from 'date-fns';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {take} from 'rxjs/operators';
import {DateRangeDialog} from '../../shared/input/date-range.dialog';


@Component({
    standalone: true,
    imports: [CommonModule, AlgoliaSearchComponent],
    selector: 'nxt-order-search',
    template: `
        <algolia-search-component
                #searchComponent
                index="orders"
                placeholder="Search Orders"
                [saveSearch]="saveSearch"
                [filterSet]="filterSets"
                (onResults)="onResults.emit($event)"
                [preSearchLogic]="preSearchLogic"
                (onClear)="onClear.emit($event)"
        ></algolia-search-component>

    `
})
export class NxtOrderSearch implements OnInit {
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean]>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('searchComponent', {static: true}) searchComponent: AlgoliaSearchComponent;
    @Input() saveSearch: boolean = true;
    type: string = 'orders';
    filterSets: IAlgoliaFilterSet[]

    constructor(
        private aSvc: AccountService,
        private cSvc: ClientService,
        private pSvc: PageService
    ) {
    }

    ngOnInit() {
        this.loadFilters();
    }

    async loadFilters() {
        let now: Date = new Date();
        let start: Date = new Date();
        let end: Date = new Date();

        this.filterSets = [];
        let dateSet:IAlgoliaFilterSet[] = [
            {
                name: 'Date Filters',
                buttons: [
                    {
                        label: 'Custom Date Range',
                        class: 'btn-dark',
                        click: () => {
                            this.pSvc.modal$.next({
                                label: 'Custom Date Range',
                                component: DateRangeDialog,
                                styles: {
                                    width: '600px'
                                },
                                onLoaded: (comp: DateRangeDialog) => {
                                    comp.start = start;
                                    comp.end = end;
                                    comp.onClose
                                        .pipe(take(1)).subscribe(
                                        (result: {start:Date, end:Date}) => {
                                            
                                            if (result) {
                                                start = result.start;
                                                end = result.end;
                                                this.searchComponent.builder.remove('filterItems', {id:'CUSTOM'});
                                                this.searchComponent.toggleFilter({
                                                    id: 'CUSTOM',
                                                    name: `${format(start,'MMM d, yyyy')} > ${format(end,'MMM d, yyyy')}`,
                                                    color: '#505cac',
                                                    contrast: '#ffffff',
                                                    key: 'Date',
                                                    type: 'numeric',
                                                    key_unique: true,
                                                    more: `date>${start.valueOf()}`,
                                                    value: `date<${end.valueOf()}`
                                                });
                                            }
                                        }
                                    );
                                    comp.ngOnChanges();
                                }
                            });
                        }
                    }
                ],
                items: [
                    {
                        id: 'MONTH',
                        name: 'Current Month',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `date>${startOfMonth(startOfDay(now)).valueOf()}`,
                        value: `date<${endOfMonth(endOfDay(now)).valueOf()}`
                    },
                    {
                        id: 'LAST_MONTH',
                        name: 'Previous Month',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `date>${subMonths(startOfMonth(startOfDay(now)),1).valueOf()}`,
                        value: `date<${subMonths(endOfMonth(endOfDay(now)),1).valueOf()}`
                    },
                    {
                        id: 'YEAR',
                        name: 'Current Year',
                        color: '#7481d5',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `date>${startOfYear(startOfDay(now)).valueOf()}`,
                        value: `date<${endOfYear(endOfDay(now)).valueOf()}`
                    },
                    {
                        id: 'YEAR',
                        name: 'Last Year',
                        color: '#7481d5',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `date>${subYears(startOfYear(startOfDay(now)),1).valueOf()}`,
                        value: `date<${subYears(endOfYear(endOfDay(now)),1).valueOf()}`
                    }
                ]
            }
        ];
        let roleSet = await getUsersByRoleFilterSets(this.aSvc, this.cSvc);
        this.filterSets = dateSet.concat(roleSet);
    }

    preSearchLogic(builder: AlgoliaQueryBuilder): AlgoliaQueryBuilder {
        if (builder) {
            let item: AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem({
                id: 'invoice_id',
                name: '',
                key: 'invoice_id',
                key_unique: true,
                value: `invoice_id:${builder.query}`
            });
            builder.removeFilter(item);
            if (builder.query?.match(/[0-9]{6}[a-zA-Z]{4}/)) {
                builder.addFilter(item);
            }
        }
        return builder;
    }

}
