import {
    Component,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    OnChanges, SimpleChanges
} from '@angular/core';
import Cropper from 'cropperjs';
import {SafeUrl} from '@angular/platform-browser';

import {PageService} from '../../shared/_services/page.service';
import {IMenuItem} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {FooterButtonsComponent} from '../footer/footer-buttons.component';
import {IconsComponent} from '../../shared/icons/icons.component';

export interface ImageCropperData {
    file?: File;
    type?: string;
    imageSource: SafeUrl;
    options: ImageCropperOptions
}

export interface ImageCropperOptions {
    dragMode?: 'move';
    background?: boolean;
    movable?: boolean;
    scalable?: boolean;
    zoomable?: boolean;
    guides?: boolean;
    viewMode?: number;
    minContainerWidth?: number;
    responsive?: boolean;
    checkCrossOrigin?: boolean;
    aspectRatio?: number;
};

@Component({
    standalone: true,
    imports: [CommonModule, FooterButtonsComponent, IconsComponent],
    selector: 'image-cropper',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../../../node_modules/cropperjs/dist/cropper.css'],
    template: `
        <div class="text-center">
            <div class="flex mb-3 bg-white p-2 sticky -top-1 relative -top-1" style="z-index: 10000;">
                <button class="btn-clear btn-xs" (click)="cropper.zoom(.1)">
                    <icon name="heroicon-outline-zoom-in" class="h-6 w-6 text-gray-600 m-auto cursor-pointer"></icon>
                </button>
                <button class="btn-clear btn-xs" (click)="cropper.zoom(-.1)">
                    <icon name="heroicon-outline-zoom-out" class="h-6 w-6 text-gray-600 m-auto"></icon>
                </button>
                <button class="btn-dark btn-xs" (click)="save()">
                    Save
                </button>
            </div>
            <img class="img-cropping m-auto max-w-3/12" style="overflow: hidden" #image id="image" [src]="data.imageSource" crossorigin alt="Cropped Image"/>
        </div>

    `
})
export class ImageCropperComponent implements OnChanges {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('image', { static: false }) set imageElement(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.imageEl = content;
            this.init();
        }
    }
    imageEl: ElementRef;
    @Input() data: ImageCropperData;
    @Input() toDataUrl: boolean;
    imageDestination: string;
    cropper: Cropper;
    loading: boolean = false;
    buttons: IMenuItem[] = [{
        label: 'Save',
        class: 'btn-dark',
        click: () => {
            this.save();
        }
    }]

    constructor(private pSvc: PageService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.data) {
            this.init();
        }
    }

    cropMoved(): void {
        this.imageDestination = this.cropper.getCroppedCanvas().toDataURL();
    }

    async save() {
        this.pSvc.loading$.next(true);

        let [file,dataUrl] = await Promise.all([
            new Promise(resolve => {
                this.cropper?.getCroppedCanvas()?.toBlob(blob => {
                    if (blob) {
                        let file: any = blob;
                        file.lastModifiedDate = this.data.file['lastModifiedDate'];
                        file.lastModified = this.data.file.lastModified;
                        file.name = this.data.file.name;
                        resolve(file);
                    }
                }, this.data.file.type);
            }),
            new Promise(resolve => {
                resolve( this.cropper?.getCroppedCanvas()?.toDataURL(this.data.file.type) );
            })
        ])

        if (this.toDataUrl) {
            this.onClose.emit([ file, dataUrl ]);
        } else {
            this.onClose.emit(file);
        }

        this.pSvc.loading$.next(false);;

    }

    init(opts?: any) {
        if (this.data && this.imageEl?.nativeElement) {
            this.cropper = new Cropper(this.imageEl.nativeElement, {
                dragMode: 'move',
                background: true,
                movable: false,
                scalable: true,
                zoomable: true,
                guides: true,
                viewMode: 1,
                responsive: true,
                zoom: this.cropMoved.bind(this),
                cropmove: this.cropMoved.bind(this),
                zoomOnWheel: false,
                checkCrossOrigin: true,
                aspectRatio: this.data?.options?.aspectRatio || 16 / 9
            });
        }
    }
}
