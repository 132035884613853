export let ewinLazyComponents: any = {

   'header-transparent': {
      t: 'Header (Transparent BG)',
      client: 'EWIN',
      loader: () => import('./header-transparent/header-transparent'),
      config: () => import('./header-transparent/header-transparent-config'),
      type: 'header'
   },
   'header-opaque': {
      t: 'Opaque Header',
      type: 'header',
      client: 'EWIN',
      loader: () => import('./header-opaque/ewin-header-opaque'),
      config: () => import('./header-opaque/ewin-header-opaque-config')
   },
   'hero': {
      t: 'Hero',
      d: 'Hero component for EWIN',
      client: 'EWIN',
      loader: () => import('./home/hero/hero'),
      config: () => import('./home/hero/hero-config')
   },
   'benefits': {
      t: 'Benefits',
      client: 'EWIN',
      page: '/',
      loader: () => import('./home/benefits/benefits')
   },
   'page-title': {
      t: 'Page Title',
      client: 'EWIN',
      loader: () => import('@library/shared/page-title/page-title'),
      // @ts-ignore
      config: () => import('@library/shared/page-title/page-title-config'),
   },
   'image-slider-component': {
      t: 'Image Slider',
      client: 'EWIN',
      loader: () => import('./image-slider/image-slider'),
      editor: () => import('./image-slider/image-slider-editor')
   },
   'quality-guidance-privacy': {
      t: 'Quality-Guidance-Privacy',
      client: 'EWIN',
      page: '/',
      loader: () => import('./home/quality-guidance-privacy/quality-guidance-privacy')
   },
   'contact-us': {
      t: 'Contact Us',
      type: 'footer',
      client: 'EWIN',
      loader: () => import('./contact-us/contact-us')
   },
   'how-to-measure': {
      t: 'How to Measure',
      url: '/how_to_measure_windows',
      client: 'EWIN',
      loader: () => import('./how-to-measure/how-to-measure')
   },
   'gallery': {
      t: 'Gallery',
      d: 'Gallery Editor with sections for images by quality (standard, select & premium/replacement)',
      client: 'EWIN',
      loader: () => import('./gallery/gallery'),
      config: () => import('./gallery/gallery-config')
   },
   'gallery List': {
      t: 'Gallery List',
      d: 'Gallery list grid for homepage.',
      client: 'EWIN',
      page: '/',
      loader: () => import('./gallery/gallery-list'),
      config: () => import('./gallery/gallery-list-config')
   },
   'Button with colored background': {
      t: 'Button with colored background',
      d: 'Section with just a button and a colored background',
      client: 'EWIN',
      loader: () => import('./button-with-solid-background/button-with-solid-background'),
      config: () => import('./button-with-solid-background/button-with-solid-background-config')
   },
   'Text on the left with image background': {
      t: 'Text aligned left with image background',
      d: 'EWIN section for text on the left and an image background',
      client: 'EWIN',
      loader: () => import('./text-left-faded-background/text-left-faded-background'),
      config: () => import('./text-left-faded-background/text-left-faded-background-config')
   },
   'Style Hero': {
      t: 'Style Hero Component',
      d: 'Hero component for style landing',
      client: 'EWIN',
      loader: () => import('./style-landing/style-hero/style-hero'),
      config: () => import('./style-landing/style-hero/style-hero-config')
   },
   'Style Transparent Header': {
      t: 'Style Transparent Header',
      d: 'Header component for style landing',
      client: 'EWIN',
      loader: () => import('./style-landing/style-transparent-header/style-transparent-header'),
      config: () => import('./style-landing/style-transparent-header/style-transparent-header-config')
   },
   'Style Gallery': {
      t: 'Style Gallery List Component',
      d: 'Gallery list component for style landing',
      client: 'EWIN',
      loader: () => import('./style-landing/style-gallery/style-gallery-list'),
      config: () => import('./style-landing/style-gallery/style-gallery-list-config')
   },'Pic Left Text Right': {
      t: 'Style Pic Left Text Right Component',
      d: 'Pic left with text right and colored background behind text',
      client: 'EWIN',
      loader: () => import('./style-landing/style-two-column/pic-left-text-right/pic-left-text-right'),
      config: () => import('./style-landing/style-two-column/pic-left-text-right/pic-left-text-right-config')
   },'Text Left Pic Right': {
      t: 'Style Text Left Pic Right Component',
      d: 'Text left with pic right and colored background behind text',
      client: 'EWIN',
      loader: () => import('./style-landing/style-two-column/text-left-pic-right/text-left-pic-right'),
      config: () => import('./style-landing/style-two-column/text-left-pic-right/text-left-pic-right-config')
   },
   'Style FAQ Component': {
      t: 'Style FAQ Component',
      d: 'FAQ component for style landing',
      client: 'EWIN',
      loader: () => import('./style-landing/style-faq/style-faq'),
      config: () => import('./style-landing/style-faq/style-faq-config')
   },
   'order-process': {
      t: 'Ordering Process',
      d: 'Revolutionary Ordering Process',
      page: '/',
      client: 'EWIN',
      loader: () => import('./home/order-process/order-process')
   },
   'full-width-image-component': {
      t:'Full Width Image Component',
      d: 'Full Width Image Component for EW',
      loader: () => import('@library/shared/full-width-image/full-width-image'),
      config: () => import('@library/shared/full-width-image/full-width-image-config')
   },
   'ewin-nxt-order-details': {
      t: 'Order details component used in NXT only.',
      client: 'NXT',
      loader: () => import('../../../../nxto/src/app/_clients/ewin/_components/ewin-order-details.component')
   },
   'ewin-window-builder-gallery': {
      t:'Window Builder Gallery',
      d: 'Gallery for display of images beneath each style',
      loader: () => import('./window-image-preview/window-builder-gallery-blank'),
      config: () => import('./window-image-preview/window-builder-gallery-config')
   },
   'installation-steps-component': {
      t:'Installation Steps Component',
      d: '3 steps for installation on the EWIN Homepage',
      loader: () => import('./home/3-steps/installation-steps'),
      config: () => import('./home/3-steps/installation-steps-config')
   },
   'text-and-images-component': {
      t:'Text and Images Component',
      d: 'Text and Images with Button Component for Installation page',
      client: 'EWIN',
      loader: () => import('./installation/text-and-images/text-and-images'),
      config: () => import('./installation/text-and-images/text-and-images-config')
   },
   'two-column-text-image-component': {
      t:'Two Column Text Image component',
      d: 'EWIN Installation two column text and image component',
      client: 'EWIN',
      loader: () => import('./installation/two-column-text-image/two-column-text-image'),
      config: () => import('./installation/two-column-text-image/two-column-text-image-config')
   },
   'image-text-row-component': {
      t:'Image and Text in Row',
      d: 'Image and Text in a row for EWIN Istallation',
      client: 'EWIN',
      loader: () => import('./installation/image-text-row/image-text-row'),
      config: () => import('./installation/image-text-row/image-text-row-config')
   },
   'installation-hero-component': {
      t:'Installation Hero Component',
      d: 'Hero component for EWIN Istallation',
      client: 'EWIN',
      loader: () => import('./installation/installation-hero/installation-hero'),
      config: () => import('./installation/installation-hero/installation-hero-config')
   },
   'two-column-lists-component': {
      t:'Two Columns Lists',
      d: 'Two Columns, each are a list, with a headline up top.',
      client: 'EWIN',
      loader: () => import('./installation/two-column-lists/two-column-lists'),
      config: () => import('./installation/two-column-lists/two-column-lists-config')
   },
   'ewin-nxt-pricing-details': {
      t: 'Pricing details editor component used in NXT only.',
      client: 'NXT',
      loader: () => import('../../../../nxto/src/app/_clients/ewin/_components/ewin-pricing-details.component')
   },
    'text-list': {
        t:'Text List Columns',
        d: 'Text lists component, designed for 3 text lists',
        client: 'EWIN',
        loader: () => import('../_components/text-list/text-list'),
        config: () => import('../_components/text-list/text-list-config')
    },
};
