import {
    Component,
    EventEmitter,
    Output,
    Input,
    ViewChild,
    ElementRef,
    OnChanges,
    SimpleChanges,
    Inject
} from '@angular/core';
import {Subscription} from 'rxjs';
import {CommonModule} from '@angular/common';

import {ScrollableDivDirective} from '@library/shared/scrollable-div/scrollable-div';
import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {MessageRefIcon} from '@library/nxt/list/message-ref-icon';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {FollowersComponent} from './followers.component';

import {IMenuItem, Thread, ThreadMessage, EMedium} from '@nxt/model-core';
import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';

import {MessageItemComponent} from './message-item.component';
import {ScrollableThreadTriggeredList} from '@library/nxt/list/scrollable-thread-triggered-list';
import {ComposeMsgComponent} from './compose-msg.component';
import {FollowersByRoleComponent} from './followers-by-role.component';
import {PageService} from '@library/shared/_services/page.service';


@Component({
    standalone: true,
    imports: [
        CommonModule, ScrollableDivDirective, MessageMediumIcon,
        MessageRefIcon, PopButtonComponent, FollowersComponent,
        MessageItemComponent,
        ScrollableThreadTriggeredList, ComposeMsgComponent, FollowersByRoleComponent
    ],
    selector: 'thread-detail',
    template: `
        <div #scrollContainer
             [class]="(context===EThreadContext.DETAILS) ? 'h-screen-header-tab overflow-y-auto relative bg-white pb-20' : 'h-screen-header overflow-y-auto relative bg-white pb-20'"
             *ngIf="thread"
        >
            <div *ngIf="modal">
                <div class="w-full p-2 border-b border-gray-200 flex">
                    <div class="w-full flex justify-between">
                        <message-medium-icon class="w-4 h-auto text-gray-500 mr-3" [item]="thread"></message-medium-icon>
                        <div class="flex ml-4">
                            <message-ref-icon [item]="thread" class="w-5 h-5 text-gray-400 mr-1"></message-ref-icon>
                            <a (click)="mSvc.nav(thread)"
                               class="cursor-pointer truncate ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                {{ parent?.toString() || thread?.object?.toString() }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-between p-2" *ngIf="parent?._type !== 'clients' && parent?._type !== 'tasks' && parent?._type !== 'deployments'">
                <div class="flex">
                    <button class="btn-gray btn-xs ml-1" title="Move Thread" (click)="tOptSvc.move(mSvc,thread)">
                        Move
                    </button>
                    <button class="btn-gray btn-xs ml-1" title="Move Thread" (click)="msgList.print()">
                        Print
                    </button>
                </div>
                
                <div class="flex">
                    <followers
                            *ngIf="parent?._type !== 'tasks'"
                            label="Agents"
                            limit="3"
                            [parent]="thread"
                            property="agents"
                            (onChange)="update('agents',$event)"
                    ></followers>
                    <followers
                            *ngIf="parent?._type !== 'tasks'"
                            label="Followers"
                            limit="4"
                            [parent]="thread"
                            property="followers"
                            (onChange)="update('followers',$event)"
                    ></followers>
                    <followers-by-role
                            *ngIf="parent?._type !== 'tasks'"
                            [parent]="thread"
                            label="Roles"
                            property="roles"
                            (onChange)="update('roles',$event)"
                    ></followers-by-role>
                </div>
            </div>

            <!-- MSG COMPOSER -->
            <compose-msg
                    [thread]="thread"
                    [message]="mSvc.activeMsg$|async"
                    [context]="context"
                    [parent]="parent"
                    (onThread)="confirmThread($event)"
                    (onLoaded)="onCompose.emit($event);"
                    (onMessage)="onMessage.emit($event)"
                    [templateDocument]="mSvc.templateDocument$|async"
                    [isTemplateModal]="isTemplateModal"
            ></compose-msg>

            <scrollable-thread-triggered-list
                    #msgList
                    class="h-screen-header-search pb-20 overflow-y-auto"
                    (onItemsLoaded)="markFirstItemRead($event);"
            ></scrollable-thread-triggered-list>
            
        </div>
    `
})
export class ThreadDetailComponent extends OnDestroyPage implements OnChanges {
    @ViewChild('scrollContainer') scrollContainer: ElementRef;
    @ViewChild('msgList', {static:true}) msgList: ScrollableThreadTriggeredList;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMessage: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Output() onCompose: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
    @Input() thread: Thread;
    @Input() parent: any;
    @Input() context: EThreadContext;
    @Input() modal: boolean = false;
    @Input() isTemplateModal: boolean = true;
    EThreadContext = EThreadContext;
    EMedium = EMedium;
    message: ThreadMessage;
    loaded: boolean;
    buttons: IMenuItem[] = [];
    sub: Subscription;
    medium: any;
    pagingSize: number = 15;

    get subject(): string {
        switch (this.thread?.medium) {
            case EMedium.CHAT:
                switch (this.parent?._type) {
                    case 'tasks':
                    case 'deployments':
                        return this.parent.name;
                }
                break;
            default:
                return this.thread?.subject || '';
        }
    }

    constructor(
        private fSvc: FireService,
        private pSvc: PageService,
        public mSvc: MessagingService,
        @Inject('ThreadOptionsService') public tOptSvc: any
    ) {

        super();

    }

    async ngOnChanges(changes?: SimpleChanges) {
        if (this.thread?.id) {
            if (this.thread.medium === EMedium.CHAT && !this.mSvc.getMsg(this.thread?.id)?.id) {
                await this.mSvc.startMessage(this.parent, this.thread, [], [], EMedium.CHAT);
            }
            this.mSvc.activeMsg$.next(this.mSvc.getMsg(this.thread?.id));
        }
    }

    async confirmThread(threadPath: string) {
        if (threadPath && !this.thread?._exists) {
            this.thread = (await this.fSvc.getObject(threadPath)) as Thread;
        }
    }

    async close() {
        await this.mSvc.flushThread('close');
        // Force the underlying modal window to close:
        this.onClose.emit();
    }

    async update(property: string, users: any) {
        this.thread[property] =  users;
        if (property === 'followers' && this.thread.agents?.find(a => users.find(u => u.id === a.id))) {
            this.pSvc.notification$.next({
                title: 'Already Agent!',
                message: `You are already following this thread as an agent.`
            });
        }
        if (this.thread._exists) {
            await this.thread.save();
        }
    }

    alreadyChecked: boolean;
    async markFirstItemRead(items: ThreadMessage[]) {
        if (items?.length && !this.alreadyChecked) {
            let item = items[0];
            if (item instanceof ThreadMessage && item.unread) {
                await item.update({unread: false});
            }
        }
    }

}
