import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {Base, IMenuItem, Thread} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
// @ts-ignore
import {environment} from '@env/environment';
import {TabBarComponent} from '../tabs/tab-bar.component';
import {ItemListComponent} from '../list/item-list.component';
import {EThreadContext} from '../_services/messaging.service';
import {NewNxtContactSearchComponent} from './nxt-contact-search';
import {NxtOrderSearch} from './nxt-order-search';
import {NewNxtQuoteSearchComponent} from './nxt-quote-search';
import {MessageSearchComponent} from './message-search';
import {ScrollableGenericList} from '../list/scrollable-generic-list';
import {ContactItem} from '../list/contact.item';
import {NxtOrderItemComponent} from '../list/nxt-order-item.component';
import {NxtQuoteItemComponent} from '../list/nxt-quote-item.component';
import {MessageItemSummary} from '../../../nxto/src/app/threads/_components/message-item-summary';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TabBarComponent,
        ItemListComponent,
        NewNxtContactSearchComponent,
        NxtOrderSearch,
        NewNxtQuoteSearchComponent,
        MessageSearchComponent,
        ScrollableGenericList,
        ContactItem,
        NxtOrderItemComponent,
        NxtQuoteItemComponent,
        MessageItemSummary
    ],
    template: `
        <tab-bar *ngIf="path" [tabs]="tabs"></tab-bar>
        <div *ngIf="message" class="p-4">{{ message }}</div>
        <ng-container [ngSwitch]="path">
            
            <nxt-contact-search *ngSwitchCase="'contacts'"
                    #contactSearch
                    (onResults)="itemList.handleAlgoliaResults($event)"
                    (onClear)="itemList.items=null"
                    [saveSearch]="false"
                    [autoStart]="false"
            ></nxt-contact-search>

            <nxt-quote-search *ngSwitchCase="'quotes'"
                  #quotesSearch
                    (onResults)="itemList.handleAlgoliaResults($event)"
                    (onClear)="itemList.items=null"
                    [saveSearch]="false"
            ></nxt-quote-search>

            <nxt-order-search *ngSwitchCase="'orders'" 
                  #ordersSearch
                   (onResults)="itemList.handleAlgoliaResults($event)"
                   (onClear)="itemList.items=null"
                   [saveSearch]="false"
            ></nxt-order-search>

            <message-search *ngSwitchCase="'threadsmsgs'"
                    #messagesSearch
                    (onResults)="itemList.handleAlgoliaResults($event)"
                    (onClear)="itemList.items=null"
                    [hitsPerPage]="pagingSize"
                    [saveSearch]="false"
                    [hideToggle]="true"
            ></message-search>
        </ng-container>
        
        <scrollable-generic-list
                #itemList
                class="pb-14"
                [label]="path"
                (onClear)="clearSearch()"
                [pageSize]="pagingSize"
                [exclude]="exclude"
                [itemTemplate]="itemTemplate"
        ></scrollable-generic-list>

        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <ng-container [ngSwitch]="item._type">
                <contact-item *ngSwitchCase="'contacts'"
                              [contact]="item"
                              (onClick)="onClick.emit($event)"
                ></contact-item>
                <nxt-order-item *ngSwitchCase="'orders'" [item]="item"
                                (onClick)="onClick.emit($event)"></nxt-order-item>
                <nxt-quote-item *ngSwitchCase="'quotes'" [item]="item"
                                (onClick)="onClick.emit($event);"></nxt-quote-item>
                <ng-container *ngSwitchCase="'threadsmsgs'">
                    <message-item-summary
                            (onSelect)="onClick.emit($event)"
                            [message]="item"
                            [avatars]="true"
                            [context]="EThreadContext.INBOX"
                    ></message-item-summary>    
                </ng-container>
                
            </ng-container>
        </ng-template>
        
    `
})
export class NxtAllSearchDialog {
    @Output() onClose: EventEmitter<Thread> = new EventEmitter<Thread>();
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('contactSearch') contactSearch: NewNxtContactSearchComponent;
    @ViewChild('quotesSearch') quotesSearch: NewNxtQuoteSearchComponent;
    @ViewChild('ordersSearch') ordersSearch: NxtOrderSearch;
    @ViewChild('messagesSearch') messagesSearch: MessageSearchComponent;

    thread: Thread;
    // results: any = {};
    env = environment;
    path: string = 'contacts';
    exclusivePath: string[];
    message: string;
    pagingSize: number = 20;
    EThreadContext = EThreadContext;
    exclude: Base;

    get tabs(): IMenuItem[] {
        return [
            {
                label: `Contacts`,
                active: this.path === 'contacts',
                hide: (this.exclusivePath && !this.exclusivePath.includes('contacts')),
                click: () => {
                    this.path = 'contacts';
                }
            },
            {
                label: `Quotes`,
                active: this.path === 'quotes',
                hide: (this.exclusivePath && !this.exclusivePath.includes('quotes')),
                click: () => {
                    this.path = 'quotes';
                }
            },
            {
                label: `Orders`,
                active: this.path === 'orders',
                hide: (this.exclusivePath && !this.exclusivePath.includes('orders')),
                click: () => {
                    this.path = 'orders';
                }
            },
            {
                label: `Messages`,
                active: this.path === 'threadsmsgs',
                hide: (this.exclusivePath && !this.exclusivePath.includes('threadsmsgs')),
                click: () => {
                    this.path = 'threadsmsgs';
                }
            }
        ];
    }

    clearSearch() {
        ['contactSearch','quotesSearch','ordersSearch','messagesSearch'].forEach(p => {
            try {
                this[p]['searchComponent']?.clearTerm(true);
            } catch (e) {
                // console.warn(e);
            }
        });
    }

}
