"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetalsOrderItem = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class MetalsOrderItem extends model_core_1.OrderItem {
    constructor(data, olMap) {
        super(data, olMap, 'items', 19);
        this.index = null;
        this.properties = {};
        this.inventory_id = '';
        this.variant_number = '';
        this.variant_id = '';
        this.name = '';
        this.notes = '';
        this.url = '';
        this.unit_cost = null;
        this.unit_weight = null;
        this.base_cost = null;
        this.size = null;
        this.price = null;
        this.price_override = null;
        this.subtotal = null;
        this.dimensions = new model_core_1.DimensionArray();
        this.fixed_dimensions = '';
        this.quantity = 0;
        this.metadata = {};
        this.added = null;
        this.pricing_id = '';
        this.pricing = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, MetalsOrderItem);
        // TODO Deprecate at some point.
        // SKU replaces variant_number
        if (this.variant_number) {
            this.sku = this.variant_number;
        }
        if (this.base_cost) {
            this.unit_cost = this.base_cost;
        }
        if (data === null || data === void 0 ? void 0 : data.cost) {
            this.unit_cost = data.cost;
            this.base_cost = data.cost;
        }
        if (data === null || data === void 0 ? void 0 : data.weight) {
            this.unit_weight = data.weight;
        }
        if (data === null || data === void 0 ? void 0 : data.sku) {
            this.variant_number = data.sku;
        }
    }
    toString() {
        return `OrderItem: ${this.variant_number}: ${this.dimensions.toString()}`;
    }
    incr(increment, u) {
        if (increment > 0) {
            if (!this.quantity) {
                this.quantity = increment;
            }
            else {
                this.quantity += increment;
            }
        }
        else {
            if ((this.quantity + increment) >= 0) {
                this.quantity += +increment;
            }
            else {
                this.quantity = 0;
            }
        }
        this.calc(u);
    }
    calc(u) {
        var _a, _b, _c, _d, _e;
        // console.log('--------');
        // SIZE IN FEET (OR SQ. FEET)
        let feet = ((_a = (this.dimensions)) === null || _a === void 0 ? void 0 : _a.getFeet) ? (_b = (this.dimensions)) === null || _b === void 0 ? void 0 : _b.getFeet() : 0;
        //PRICE
        if (feet && ((_d = (_c = this.pricing) === null || _c === void 0 ? void 0 : _c.components) === null || _d === void 0 ? void 0 : _d.length)) {
            // feet = round(feet, 2);
            // Sort price components by minimum length so we always know the lowest items are first
            (_e = this.pricing) === null || _e === void 0 ? void 0 : _e.components.sort((a, b) => {
                return (a.feet < b.feet) ? -1 : 1;
            });
            let piece_weight = Number(this.unit_weight || 0) * feet;
            let cost = Number(this.base_cost || this.unit_cost || 1) * piece_weight;
            let m = this.pricing.components.reduce((m, comp) => {
                if (!m && feet <= Number(comp.feet) && comp.margin) {
                    m = Number(comp.margin);
                }
                return m;
            }, 0);
            let margin = (((m || this.pricing.metadata.minimum) / 100) + 1);
            this.price = (0, model_core_1.round)((cost * margin), 2);
            this.subtotal = (0, model_core_1.round)(this.quantity * ((isNaN(this.price) === false) ? Number(this.price) : 0), 2);
            this._override_weight = Number(piece_weight) * this.quantity;
            this._override_weight = (0, model_core_1.round)(this._override_weight, this._override_weight < 1 ? 4 : 2);
            if (u) {
                this._margin = { feet: (0, model_core_1.round)(feet, 2), m: margin, p: m, cost: (0, model_core_1.round)(cost * this.quantity, 2), weight: this.total_weight };
            }
        }
    }
    isLTL() {
        return (this.total_weight > 150 || this.dimensions.largestDimension().size > 108);
    }
    isLittle() {
        let little = false;
        this.dimensions.forEach((dim) => {
            if (dim.variable && dim.size && dim.size < 12) {
                little = true;
            }
        });
        return little;
    }
    toMinJSON(ignoreDocRef) {
        return { variant_number: this.variant_number, name: this.name, id: this.id, _type: this._type };
    }
}
exports.MetalsOrderItem = MetalsOrderItem;
index_1.olm.items = (ref, map) => {
    return new MetalsOrderItem(ref, map);
};
