import {Component, Input} from '@angular/core';
import {EMedium, Thread, ThreadMessage} from '@nxt/model-core';
import {NgIf} from '@angular/common';

@Component({
    selector: 'subject',
    standalone: true,
    imports: [
        NgIf
    ],
    template: `
        <div class="w-full text-accent text-sm font-bold" *ngIf="parent.medium !== EMedium.TEXT">
<!--            {{ (parent._type==='threads' && parent.medium===EMedium.CHAT) ? 'Internal Chat' : parent.subject }}-->
            {{ (parent._type === 'threads' && parent.medium === EMedium.CHAT)
            ? (parent.inbound === true ? 'External/Internal Chat' : 'Internal Chat')
            : parent.subject }}
        </div>
    `
})
export class SubjectComponent {
    @Input() parent: ThreadMessage|Thread;
    EMedium = EMedium;
}
