import {
    AfterViewInit,
    Component,
    EventEmitter, Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterModule} from '@angular/router';

import {Quote, ORDER_TYPES} from '@nxt/model-core';

import {OrderQuoteCount} from './order-quote-count';
import {ClientService} from '../../shared/_services/client.service';
import {IconsComponent} from '../../shared/icons/icons.component';
import {AvatarComponent} from '../../shared/avatars/avatar.component';
import {AvatarStackComponent} from '../../shared/avatars/avatar.stack.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, OrderQuoteCount,
        IconsComponent, AvatarComponent, AvatarStackComponent
    ],
    selector: 'nxt-quote-item',
    template: `
        <ng-container #quoteItem></ng-container>
        <ng-template #defaultTemplate>
        <div class="flex" (click)="onClick.emit(item)">
            <div class="p-3 flex place-items-center">
                <avatar-stack [items]="item?.agents" size="8"></avatar-stack>
            </div>
            <div class="p-2 flex w-full justify-between">
                <div class="flex flex-col">
                    <div class="flex place-items-center">
                        <order-quote-count [contact]="item.contact"></order-quote-count>
                        <div class="font-bold ml-2 mr-2">{{ item.contact?.name }}</div>
                        <div class="italic">{{ item.quote_id }}</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex">
                            <icon name="heroicon-outline-globe-alt" class="text-gray-400 h-4 w-4 m-0.5"
                                                        *ngIf="item.source===ORDER_TYPES.WEB"></icon>
                            <icon name="heroicon-outline-phone" class="text-gray-400 h-4 w-4 m-0.5"
                                                    *ngIf="item.source===ORDER_TYPES.PHONE"></icon>
                            <icon name="heroicon-outline-refresh" class="text-gray-400 h-4 w-4 m-0.5"
                                                    *ngIf="item.source===ORDER_TYPES.SUBSCRIPTION"></icon>
                            <icon name="heroicon-outline-lock-closed" class="text-gray-400 h-4 w-4 m-0.5"
                                    *ngIf="item.locked"></icon>
                            <icon name="custom-bing" class="text-gray-400 h-4 w-4 m-0.5"
                                              *ngIf="item.metadata?.referrer?.search?.utm_source==='bing'"></icon>
                        </div>
                        <div class="ml-10 text-sm italic">{{ item.contact?.company }}</div>
                    </div>
                </div>
                <div>
                    <div class="italic text-xs text-right">{{ item.date | date:'h:mm a' }}</div>
                    <div class="font-bold">{{ item.total | currency:'USD':'symbol'}}</div>
                </div>
            </div>
        </div>
        </ng-template>
    `
})
export class NxtQuoteItemComponent implements OnChanges,AfterViewInit {
    @Output() onClick: EventEmitter<Quote> = new EventEmitter<Quote>();
    @ViewChild('quoteItem', {read: ViewContainerRef, static: false}) quoteItem: ViewContainerRef;
    @ViewChild('defaultTemplate', {static: false}) defaultTemplate;
    @Input() item: Quote;
    ORDER_TYPES = ORDER_TYPES;
    quoteComp:NxtQuoteItemComponent

    constructor(
        private cSvc: ClientService,
        private router: Router,
        @Inject('LazyComponents') protected lazyComponents: any[]
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.item) {

        }
    }

    async ngAfterViewInit(){
        if(this.defaultTemplate) {
            await this.loadComponent();
        }
    }

    async loadComponent() {
        const selector: string = `${this.cSvc.name_key.toLowerCase()}-quote-item`;

        if (!this.quoteComp || this.quoteComp['_id'] !== selector) {
            let component: any;
            for (let c of this.lazyComponents) {
                for (let key of Object.keys(c)) {
                    if (key === selector) {
                        component = c[key];
                        break;
                    }
                }
            }

            if (!component?.loader) {
                this.loadDefaultTemplate();
                return;
            } else {
                try {
                    let imported = await component.loader();

                    const key = Object.keys(imported).find(key => !key.match(/Module/));
                    if (!key) {
                        console.warn(`No export found in module ${selector}.`);
                        this.loadDefaultTemplate();
                        return;
                    }
                    let module = imported[key];
                    let ref = this.quoteItem.createComponent(module);
                    this.quoteComp = ref.instance as NxtQuoteItemComponent;
                    this.quoteComp['_id'] = selector;
                    this.quoteComp.item = this.item;
                    this.quoteComp.onClick.subscribe(e => {
                        this.onClick.emit(e);
                    });


                } catch (e) {
                    console.error(`Failed to load component ${selector}:`, e);
                    this.loadDefaultTemplate();
                }
            }
        } else {
            this.quoteComp.item = this.item;
        }
    }

    loadDefaultTemplate() {
        if (this.defaultTemplate) {
            this.quoteItem.createEmbeddedView(this.defaultTemplate);
        } else {
            console.error('defaultTemplate is undefined');
        }
    }


}
