import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule
    ],
    selector: 'input-toggle',
    template: `
        <div class="flex items-center {{class}}" *ngIf="form && controlName" [formGroup]="form">
            <button type="button" (click)="toggleValue()" [disabled]="disabled||form.get(controlName)?.disabled"
                    [class]="form?.get(this.controlName)?.value ? buttonClass.enabled : buttonClass.disabled">
                <span aria-hidden="true"
                      [class]="form?.get(this.controlName)?.value ? innerClass.enabled : innerClass.disabled"
                      role="switch"
                      aria-labelledby="label"
                ></span>
            </button>
            <label [for]="controlName" class="ml-3" id="label" *ngIf="label||placholder">
                <span class="text-sm font-medium text-gray-900">{{ label }}</span>
                <span class="text-sm text-gray-500 ml-3">{{ placholder }}</span>
          </label>
            <input [id]="controlName" type="checkbox" [formControlName]="controlName" style="display: none"/>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('required')"
               class="px-3 pt-1 italic text-xs text-red-500">
                Required
            </p>
        </div>
    `
})
export class InputToggleComponent {
    @Input() form: UntypedFormGroup;
    @Input() class: string = '';
    @Input() controlName: string;
    @Input() label: string;
    @Input() placholder: string;
    @Input() disabled: boolean;
    @Input() hideErrors: boolean = false;
    @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    buttonClass: any = {
        enabled: 'bg-dark relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark',
        disabled: 'bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark'
    }
    innerClass: any = {
        enabled: 'translate-x-5 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        disabled: 'translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
    };

    toggleValue() {
        this.form.get(this.controlName).setValue(this.form.get(this.controlName).value ? false : true);
        this.onChange.emit(this.form.get(this.controlName).value);
    }
}
