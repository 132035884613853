export const nxcoLazyComponents: any = {
   'header': {
      t: 'Header',
      type: 'header',
      client: 'NXCO',
      loader: () => import('./header/header'),
      config: () => import('./header/header-config'),
      description: 'Header with transparent background.',
   },
   'nxco-footer': {
      t: 'NxtCompanies Footer',
      type: 'footer',
      client: 'NXCO',
      loader: () => import('./footer/nxco-footer'),
      config: () => import('./footer/nxco-footer-config'),
      description: 'Default footer.',
   },
   'nxco-header-transparent-component': {
      t: 'Header Transparent',
      d: 'Transparent header for NXCO',
      type: 'header',
      client: 'NXCO',
      description: 'Header with transparent background.',
      loader: () => import('./nxco-header-transparent/nxco-header-transparent'),
      config: () => import('./nxco-header-transparent/nxco-header-transparent-config')
   },
   'software-services': {
      t: 'Homepage: Software & Services',
      description: 'Custom component for homepage.',
      url: '/',
      client: 'NXCO',
      loader: () => import('./home/software-services/software-services'),
      config: () => import('./home/software-services/software-services-config')
   },
   'nxtco-hero': {
      t: 'Homepage: Hero',
      description: 'Custom component for homepage.',
      url: '/',
      client: 'NXCO',
      loader: () => import('./home/hero/hero'),
      config: () => import('./home/hero/hero-config')
   },
   'bios': {
      t: 'Team Bios',
      description: 'Custom component for team page.',
      client: 'NXCO',
      loader: () => import('./team/bios/bios'),
      config: () => import('./team/bios/bios-config')
   },
   'business-boxes': {
      t: `'Powered By' Boxes`,
      description: 'Boxes designed for dark background images and white foreground text',
      client: 'NXCO',
      loader: () => import('./business-boxes/business-boxes'),
      config: () => import('./business-boxes/business-boxes-config')
   },
   'case-study': {
      t: `Case Study Hero`,
      description: `Similar to 'Powered By' Boxes, but used as the hero-header for case study pages.`,
      client: 'NXCO',
      loader: () => import('./case-study/case-study'),
      config: () => import('./case-study/case-study-config')
   },
   'our-solution': {
      t: `Our Solutions Component`,
      description: `Created for use on the case-study pages showing the NXT software tools used for each line of business.`,
      client: 'NXCO',
      loader: () => import('./our-solution/our-solution'),
      config: () => import('./our-solution/our-solution-config')
   },
   'about-header': {
      t: 'About Header',
      description: 'Header for About Page',
      client: 'NXCO',
      url: '/about',
      loader: () => import('./about/about-header/about-header'),
      config: () => import('./about/about-header/about-header-config')
   },
   'nxtclick-table': {
      t: 'NXTClick Table',
      description: 'NXTClick info table for Companies page',
      url: '/companies',
      client: 'NXCO',
      loader: () => import('./companies/nxtclick-table/nxtclick-table')
   },
   'nxtops-table': {
      t: 'NXTOps Table',
      d: 'NXTOps info table for Companies page',
      client: 'NXCO',
      url: '/companies',
      loader: () => import('./companies/nxtops-table/nxtops-table')
   },
   'bg-image-text': {
      t: 'Top section for nxco',
      d: 'Background image, logo, text for top section of nxco',
      client: 'NXCO',
      loader: () => import('./bg-image-text/bg-image-text'),
      config: () => import('./bg-image-text/bg-image-text-config')
   },
   'bg-image-text-component': {
      t: 'Top section for nxco Software',
      d: 'Background image, logo, text for top section of nxco software pages',
      client: 'NXCO',
      loader: () => import('./software/software'),
      config: () => import('./software/software-config')
   },
   'nxco-two-column-text-component': {
      t: 'Side by side text component',
      d: 'Two columns of logo, header, and content for NXCO',
      client: 'NXCO',
      loader: () => import('./about/about-column-text/about-column-text'),
      config: () => import('./about/about-column-text/about-column-text-config')
   },
   'nxco-text-column': {
      t: 'Columns Of Text',
      d: 'Array of columns of text, for pain point section',
      client: 'NXCO',
      loader: () => import('./text-columns/text-columns'),
      config: () => import('./text-columns/text-columns-config')
   },
   'axek-home-hero': {
      t: 'Home Hero',
      d: 'Hero image background with headline, button and icon.',
      loader: () => import('../../../../axek/src/app/_components/home/hero/home-hero'),
      config: () => import('../../../../axek/src/app/_components/home/hero/home-hero-config')
   }
};
