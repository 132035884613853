"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Activity = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Activity extends model_core_1.Base {
    constructor(data, olMap) {
        super('activity', 12);
        this.action = null;
        this.reaction = {};
        this.ref = null;
        this.type = '';
        this.user_id = '';
        this.object = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Activity);
    }
    async loadAll(opts, stack, p) {
        var _a;
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
        if (this.ref) {
            if (opts === null || opts === void 0 ? void 0 : opts.getDoc) {
                this.object = (0, model_core_1.loadObject)(await opts.getDoc(this.ref), opts);
            }
            else if ((_a = this.ref) === null || _a === void 0 ? void 0 : _a.get) {
                this.object = (0, model_core_1.loadObject)(await this.ref.get(), opts);
            }
        }
    }
    async setInfo(opts, player, viewer) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        await this.loadAll(opts);
        if ((_a = this.object) === null || _a === void 0 ? void 0 : _a._exists) {
            await this.object.loadAll(opts);
        }
        this.info = this.info || {};
        let cRef;
        let eRef;
        let eM;
        if (this.object instanceof index_1.Email) {
            cRef = this.object.cRef;
            eRef = this.object.eRef;
        }
        else if (this.object instanceof index_1.CollegeRef) {
            cRef = this.object;
        }
        else if (this.object instanceof index_1.RcrtEventRef) {
            eRef = this.object;
            await eRef.loadAll(opts);
        }
        else if (this.object instanceof index_1.Comment) {
            eRef = this.object.eRef;
            cRef = this.object.cRef;
            eM = this.object.email;
        }
        if (cRef || eRef) {
            this.info.url = eRef ? `/account/colleges/${cRef === null || cRef === void 0 ? void 0 : cRef.id}/event/${eRef === null || eRef === void 0 ? void 0 : eRef.id}` : `/account/colleges/${cRef === null || cRef === void 0 ? void 0 : cRef.id}`;
            if (eM === null || eM === void 0 ? void 0 : eM.threadId) {
                this.info.url = `${this.info.url}/tab/email/${eM.threadId}`;
            }
            else if (this.object instanceof index_1.Email && this.object['threadId']) {
                this.info.url = `${this.info.url}/tab/email/${this.object['threadId']}`;
            }
            this.info.src = `${(_c = (_b = cRef === null || cRef === void 0 ? void 0 : cRef.college) === null || _b === void 0 ? void 0 : _b.image) === null || _c === void 0 ? void 0 : _c.src}`;
            this.info.description = eRef ? `${(_d = eRef.event) === null || _d === void 0 ? void 0 : _d.title}/${(_e = cRef === null || cRef === void 0 ? void 0 : cRef.college) === null || _e === void 0 ? void 0 : _e.title}` : `${(_f = cRef === null || cRef === void 0 ? void 0 : cRef.college) === null || _f === void 0 ? void 0 : _f.title}`;
        }
        if (this.object instanceof index_1.RcrtShare) {
            this.info.description = this.object.title;
            this.info.src = (_g = player === null || player === void 0 ? void 0 : player.image) === null || _g === void 0 ? void 0 : _g.src;
            if (((_k = (_j = (_h = this.object._docRef.parent) === null || _h === void 0 ? void 0 : _h.parent) === null || _j === void 0 ? void 0 : _j.parent) === null || _k === void 0 ? void 0 : _k.id) === 'events') {
                this.info.url = `/account/events/${this.object._docRef.parent.parent.id}`;
            }
            else {
                this.info.url = `/account/tab/share`;
                this.info.qP = { p: 1, tab: 'shares' };
            }
        }
        switch (this.action) {
            case index_1.ERcrtActions.COLLAB:
                this.info.label = 'You Are a Collab!';
                this.info.description = 'Click here to view your collabs list';
                this.info.url = `/account/tab/collabs`;
                break;
            case index_1.ERcrtActions.REACT:
                this.info.label = 'Reaction';
                if ((_m = (_l = this.reaction) === null || _l === void 0 ? void 0 : _l.u) === null || _m === void 0 ? void 0 : _m.name) {
                    this.info.label = `${(_o = this.reaction) === null || _o === void 0 ? void 0 : _o.u.name} Reacted`;
                }
                if (this.reaction.r === 'u') {
                    this.info.emoji = '&#x1F44D;';
                }
                else if (this.reaction.r === 'd') {
                    this.info.emoji = '&#x1F44E;';
                }
                this.info.description = `to comment: "${this.object.message}"`;
                break;
            case index_1.ERcrtActions.COMMENT:
                let c = this.object;
                if ((_p = c === null || c === void 0 ? void 0 : c.creator) === null || _p === void 0 ? void 0 : _p.first_name) {
                    this.info.label = `${c.creator.first_name} Commented`;
                    this.info.description = c.message;
                    this.info.src = ((_r = (_q = c.creator) === null || _q === void 0 ? void 0 : _q.image) === null || _r === void 0 ? void 0 : _r.src) || this.info.src;
                }
                break;
            case index_1.ERcrtActions.SHARE:
                this.info.label = eRef ? `Event Share Created` : 'Player Share Created';
                this.info.url = eRef ? `/account/events/${eRef.id}` : `/account/tab/share`;
                break;
            case index_1.ERcrtActions.DRAFT:
                this.info.description = eRef ? `${(_s = eRef.event) === null || _s === void 0 ? void 0 : _s.title}/${(_t = cRef === null || cRef === void 0 ? void 0 : cRef.college) === null || _t === void 0 ? void 0 : _t.title}` : `${(_u = cRef === null || cRef === void 0 ? void 0 : cRef.college) === null || _u === void 0 ? void 0 : _u.title}`;
                this.info.label = eRef ? 'Event Email Drafted' : 'College Email Drafted';
                break;
            case index_1.ERcrtActions.REPLIES:
            case index_1.ERcrtActions.EMAIL:
                this.info.description = eRef ? `${(_v = eRef.event) === null || _v === void 0 ? void 0 : _v.title}/${(_w = cRef === null || cRef === void 0 ? void 0 : cRef.college) === null || _w === void 0 ? void 0 : _w.title}` : `${(_x = cRef === null || cRef === void 0 ? void 0 : cRef.college) === null || _x === void 0 ? void 0 : _x.title}`;
                if (this.object['from']) {
                    this.info.label = (this.object['msgId'] === this.object['threadId']) ? 'College Email Received!' : 'College Coach Replied!';
                }
                else if (this.object['results_sent']) {
                    this.info.label = eRef ? 'Event Email Sent' : 'College Email Sent';
                }
                else {
                    this.info.label = eRef ? 'Event Email Drafted' : 'College Email Drafted';
                }
                break;
            case index_1.ERcrtActions.VIEW:
            case index_1.ERcrtActions.PLAY:
                if (this.object instanceof index_1.Email) {
                    this.info.label = eRef ? 'Event Email Viewed' : `College Email Viewed`;
                }
                else if (this.object instanceof index_1.RcrtShare) {
                    this.info.label = eRef ? `Event Share` : `Player Share`;
                    this.info.label += (this.action === index_1.ERcrtActions.PLAY) ? ` Video Played` : ` Viewed`;
                }
                break;
            case index_1.ERcrtActions.VCARD:
                this.info.label = `Card Downloaded`;
                break;
        }
        if ((viewer === null || viewer === void 0 ? void 0 : viewer.id) && (viewer === null || viewer === void 0 ? void 0 : viewer.id) !== player.id) {
            this.info.description = `${player.name} ${this.info.description}`;
        }
    }
    toJSON() {
        let r = super.toJSON();
        if (!this.ref) {
            throw `Cannot save Activity without a ref!`;
        }
        ['active', 'object', 'last_date'].forEach(p => {
            delete r[p];
        });
        return r;
    }
}
exports.Activity = Activity;
index_1.olm.activity = (ref, map) => {
    return new Activity(ref, map);
};
