import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {SpinnerComponent} from '@library/shared/spinner/spinner.component';
import {IconsComponent} from "@library/shared/icons/icons.component";
import {CloudFile, Contact} from "@nxt/model-core";
import {PageService} from "@library/shared/_services/page.service";
import {FireService} from "@library/nxt/_services/fire.service";
import {ClientService} from "@library/shared/_services/client.service";

@Component({
    selector: 'attachment-modal',
    standalone: true,
    imports: [CommonModule, SpinnerComponent, IconsComponent],
    template: `
        <div class="flex items-center justify-center h-[80vh]">
            <div class="p-4 w-11/12 h-full flex flex-col max-w-screen-lg relative">
                <div class="flex flex-col sm:flex-row justify-between w-full mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
                    <button
                        class="flex-1 bg-accent-500 text-white px-4 py-2 rounded-md shadow hover:bg-accent-600 transition ease-in-out duration-150"
                        (click)="downloadFile(currentFile)">
                        Download
                    </button>
                    <button *ngIf="cSvc.name_key==='EQIP'"
                            class="flex-1 bg-accent-500 text-white px-4 py-2 rounded-md shadow hover:bg-accent-600 transition ease-in-out duration-150"
                            (click)="saveFile(contact)">
                        Save
                    </button>
                    <button *ngIf="saveToParent"
                            class="flex-1 bg-accent-500 text-white px-4 py-2 rounded-md shadow hover:bg-accent-600 transition ease-in-out duration-150"
                            (click)="saveFile(parent)">
                        Save to Object
                    </button>
                    <button
                        class="flex-1 bg-accent-500 text-white px-4 py-2 rounded-md shadow hover:bg-accent-600 transition ease-in-out duration-150"
                        (click)="downloadAllFiles()">
                        Download All
                    </button>
                </div>
                <button
                    class="absolute z-10 top-1/2 left-4 transform -translate-y-1/2 text-gray-600 hover:text-gray-900"
                    (click)="prevFile()">
                    <icon name="heroicon-outline-chevron-left" class="h-8 w-8"></icon>
                </button>
                <div class="flex-grow p-10 flex items-center justify-center relative overflow-hidden">
                    <ng-container [ngSwitch]="getFileType(currentFile.name)">
                        <img *ngSwitchCase="'image'" [alt]='currentFile.name' [src]="currentFile.url"
                             class="object-contain max-h-full max-w-full">
                        <video *ngSwitchCase="'video'" class="object-contain max-h-full max-w-full"
                               [src]="currentFile.url" controls></video>
                        <iframe *ngSwitchCase="'pdf'" [src]="currentFileUrl" class="h-full w-full"></iframe>
                        <div *ngSwitchCase="'audio'" class="flex flex-col items-center justify-center">
                            <audio controls [src]="currentFileUrl"></audio>
                            <p class="italic truncate">If the file won't play, <a (click)="downloadFile(currentFile)" class="underline cursor-pointer">click here.</a></p>
                        </div>
                        <div *ngSwitchDefault class="flex flex-col items-center justify-center">
                            <a (click)="downloadFile(currentFile)">
                                <icon name="heroicon-outline-document-download" class="h-24 w-24"></icon>
                                <p class="truncate">{{ currentFile.name }}</p>
                            </a>
                        </div>
                    </ng-container>

                </div>
                <button
                    class="absolute z-10 top-1/2 right-4 transform -translate-y-1/2 text-gray-600 hover:text-gray-900"
                    (click)="nextFile()">
                    <icon name="heroicon-outline-chevron-right" class="h-8 w-8"></icon>
                </button>
            </div>
        </div>
    `,
})
export class AttachmentModalComponent implements OnInit {
    @Input() files: CloudFile[];
    @Input() contact: Contact;
    @Input() parent: any;
    @Input() currentIndex: number = 0;
    @Input() saveToParent: boolean = false;
    plainTextContent: string;
    currentFileUrl: SafeResourceUrl;

    get currentFile(): CloudFile {
        return this.files[this.currentIndex];
    }

    constructor(public sanitizer: DomSanitizer, private fSvc: FireService, protected cSvc: ClientService,
                private pSvc: PageService) {
    }

    ngOnInit() {
        this.loadContent();
    }

    prevFile() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        } else {
            this.currentIndex = this.files.length - 1;
        }
        this.loadContent();
    }

    nextFile() {
        if (this.currentIndex < this.files.length - 1) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
        this.loadContent();
    }

    getFileType(fileName: string): string {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'bmp':
            case 'svg':
            case 'webp':
                return 'image';
            case 'mp4':
            case 'mov':
            case 'avi':
                return 'video';
            case 'mp3':
            case 'wav':
                return 'audio';
            case 'pdf':
                return 'pdf';
            default:
                return 'other';
        }
    }

    loadContent() {
        this.currentFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentFile.url);
        if (this.getFileType(this.currentFile.name) === 'text/plain') {
            fetch(this.currentFile.url)
                .then(response => response.text())
                .then(data => {
                    this.plainTextContent = data;
                });
        } else {
            this.plainTextContent = null;
        }
    }

    downloadFile(file: CloudFile) {
        fetch(file.url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = file.name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            })
            .catch(e => console.error('Error downloading file:', e));
    }

    downloadAllFiles() {
        this.files.forEach((file, index) => {
            setTimeout(() => {
                this.downloadFile(file);
            }, index * 1000);
        });
    }

    async saveFile(destination: any) {
        try {
            const file = this.currentFile;
            const fileNameExists = destination.files.some(existingFile => existingFile.name === file.name);

            if (fileNameExists) {
                this.pSvc.alert$.next({
                    title: 'File Name Exists',
                    message: 'A file with this name already exists. Do you want to continue?',
                    buttons: [{
                        label: 'Cancel',
                        closeOnClick: true,
                        class: 'btn-gray'
                    }, {
                        label: 'Continue',
                        closeOnClick: true,
                        class: 'btn-green',
                        click: async () => {
                            await this.performFileSave(file, destination, true);
                        }
                    }],
                    styles: {
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        'z-index': '1000',
                        width: '50vw',
                        minHeight: '30vh',
                        maxHeight: '40vh',
                        display: 'flex',
                        flexDirection: 'column'
                    }
                });
            } else {
                await this.performFileSave(file, destination);
            }
        } catch (error) {
            console.error('Error saving file:', error);
            this.pSvc.notification$.next({
                title: 'Error!',
                message: `Error saving file to ${destination === this.contact ? 'contact' : 'object'}`
            });
        }
    }
    //Let the record show that these used to be 4 functions. Refactored to 2. For parent and contacts.
    private async performFileSave(file: CloudFile, destination: any, unique: boolean = false) {
        try {
            const response = await fetch(file.url);
            const blob = await response.blob();

            let fileName = file.name;

            if (unique) {
                const uniqueSuffix = Date.now();
                const fileNameParts = fileName.split('.');
                const extension = fileNameParts.pop();
                const baseName = fileNameParts.join('.');
                fileName = `${baseName}_${uniqueSuffix}.${extension}`;
            }

            const newFile = new File([blob], fileName, {type: blob.type});
            const newCloudFile = new CloudFile({
                name: newFile.name,
                type: newFile.type,
                size: newFile.size,
                lastModified: newFile.lastModified,
            });

            const currentDate = new Date().toISOString().split('T')[0];
            const fileNameWithDate = `${newFile.name.split('.').slice(0, -1).join('.')}_${currentDate}.${newFile.name.split('.').pop()}`;

            const basePath = destination === this.contact
                ? `clients/${this.cSvc.name_key}/contacts/documents/${this.contact.id}`
                : `clients/${this.cSvc.name_key}/attachments/${this.parent?._type}/${this.parent?.id}`;
            const fullPath = `${basePath}/${fileNameWithDate}`;
            const uploadedUrl = await this.fSvc.upload(fullPath, newFile);
            newCloudFile.url = uploadedUrl;

            if (!destination.files) {
                destination.files = [];
            }
            destination.files.push(newCloudFile);

            await newCloudFile.save(destination);

            this.pSvc.notification$.next({
                title: 'Success',
                message: `File successfully saved to ${destination === this.contact ? 'contact' : 'object'}`
            });
        } catch (error) {
            console.error('Error performing file save:', error);
            this.pSvc.notification$.next({
                title: 'Error!',
                message: `Error performing file save to ${destination === this.contact ? 'contact' : 'object'}`
            });
        }
    }
}
