import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';

import {prettifyPhone, User} from '@nxt/model-core';
import {AvatarComponent} from '../../shared/avatars/avatar.component';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [CommonModule, AvatarComponent, IconsComponent],
    selector: 'user-item',
    template: `
        <a (click)="onClick.emit(user)" [class]="user._root ? 'bg-yellow-100' : ''">
            <div class="flex">
                <avatar [user]="user" size="12" class="mr-4"></avatar>
                <div class="flex flex-col">
                    <div class="flex">
                        <div class="font-bold mr-4">{{ user.name || '' }}</div>
                        <span class="text-gray-400 line-clamp-2" *ngIf="user.nick_name">{{ user.nick_name }}</span>
                    </div>
                    <div class="flex place-items-center my-1" *ngIf="user.name && user.email">
                        <icon name="heroicon-solid-mail" class="h-5 w-5 text-gray-400 mr-1"></icon>
                        <span class="line-clamp-2 ml-2">{{ user.email }}</span>
                    </div>
                    <div class="flex place-items-center my-1" *ngIf="user.name && user.phone">
                        <icon name="heroicon-solid-phone" class="h-5 w-5 text-gray-400 mr-1"></icon>
                        <span class="line-clamp-2 ml-2">{{ prettifyPhone(user.phone) }}</span>
                    </div>
                </div>
            </div>
        </a>
    `
})
export class UserItem {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() user: User;
    prettifyPhone = prettifyPhone;
}
