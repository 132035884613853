import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterModule} from '@angular/router';

import {ItemListComponent} from '../list/item-list.component';

import {Client} from '@nxt/model-core';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';

import {SimpleSearch} from '../search/simple-search';
import {PipesModule} from '../../shared/_pipes/pipes';
import {AccountService} from '../_services/account.service';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, ItemListComponent, SimpleSearch, PipesModule, IconsComponent
    ],
    selector: 'client-sidebar',
    template: `
        <div   [class]="(show===true) 
            ? 'fixed inset-0 overflow-hidden transform transition ease-in-out duration-500 translate-x-0 z-30' 
            : (show===false) 
                ? 'fixed inset-0 overflow-hidden transform transition ease-in-out duration-500 -translate-x-full z-30' 
                : 'fixed inset-0 overflow-hidden -translate-x-full'"
        >
            <div class="absolute inset-0 overflow-hidden">

                <div class="absolute inset-0 bg-gray-500 ease-in-out duration-500 transition-opacity bg-opacity-75"
                     aria-hidden="true" id="overlay"></div>
                <div class="fixed inset-y-0 left-0 max-w-full flex">

                    <div class="relative w-screen max-w-md"
                         [class]="(show===true) 
                         ? 'translate-x-0 transform transition ease-in-out duration-500 sm:duration-700' : 
                         (show===false) 
                            ? '-translate-x-full transform transition ease-in-out duration-500 sm:duration-700' 
                            : '-translate-x-full transform relative w-screen max-w-md'"
                    >

                        <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                            <div class="py-6 px-4 bg-dark-700 sm:px-6">
                                <div class="flex items-center justify-between">
                                    <h2 class="text-lg font-medium text-white" id="slide-over-title">
                                        <button type="button" (click)="onToggle.emit(false)"
                                                class="text-white hover:text-black mr-4 mt-1 focus:outline-none focus:ring-2 focus:ring-white">
                                            <span class="sr-only">Close panel</span>
                                            <icon name="heroicon-outline-x" class="h-6 w-6"></icon>
                                        </button>
                                        Clients
                                    </h2>
                                </div>
                            </div>
                            <simple-search (onChange)="searchTerm=$event"></simple-search>
                            <item-list [items]="(cSvc.clients$|async) | filterBy: { term: searchTerm, properties: ['name','name_key'] }" 
                                       [noPadding]="true"
                                       (onClick)="loadClient($event)"></item-list>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    `
})
export class ClientSidebarComponent {
    @Input() show: boolean;
    @Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    searchTerm: string;

    constructor(
        public cSvc: ClientService,
        private pSvc: PageService,
        private aSvc: AccountService,
        private router: Router
    ) {
        this.pSvc.clickEsc$
            .subscribe(
                res => {
                    if (res) {
                        this.onToggle.emit(false);
                    }
                }
            );
        this.pSvc.click$
            .subscribe(
                res => {
                    if ((res?.target as any)?.id === 'overlay') {
                        this.onToggle.emit(false);
                    }
                }
            );
    }

    async loadClient(client: Client) {
        // Flush the uBR when changing clients.
        if (client.id !== this.cSvc.client_id) {
            this.aSvc.uBR = null;
        }
        this.onToggle.emit(false);
        let l = localStorage?.getItem(client.name_key) ? JSON.parse(localStorage?.getItem(client.name_key)) : {};
        await this.aSvc.setClient(client);
        await this.router.navigate([`/${client.name_key}/threads`]);
    }
}
