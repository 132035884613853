import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {EMedium, prettifyPhone, formatDate} from '@nxt/model-core';
import {ContactItem} from './contact.item';
import {ClientItem} from './client.item';
import {UserItem} from './user.item';
import {TaskItem} from './task.item';
import {DateHeaderComponent} from '../../shared/header/date-header.component';
import {PageService} from '../../shared/_services/page.service';
import {NxtOrderItemComponent} from './nxt-order-item.component';
import {NxtQuoteItemComponent} from './nxt-quote-item.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, UserItem, ClientItem,
        ContactItem, TaskItem, DateHeaderComponent, NxtOrderItemComponent, NxtQuoteItemComponent
    ],
    selector: 'item-list',
    template: `
        <div class="item-list w-full"
             [style.padding]="noPadding ? '0px':''">
            <ul role="list">
                <ng-container *ngFor="let item of items; let n = index;">
                    <li *ngIf="item?._type">
                        <date-header *ngIf="dateHeader" [label]="getDateHeader(item,n)"></date-header>
                        <ng-container [ngSwitch]="item._type">
                            <contact-item *ngSwitchCase="'contacts'"
                                          [contact]="item"
                                          [dateHeader]="dateHeader"
                                          (onClick)="onClick.emit($event)"
                            ></contact-item>
                            <client-item *ngSwitchCase="'clients'" [client]="item" [noText]="noText"
                                         (onClick)="onClick.emit($event)"></client-item>
                            <user-item *ngSwitchCase="'users'" [user]="item"
                                       (onClick)="onClick.emit($event)"></user-item>
                            <task-item *ngSwitchCase="'tasks'" [task]="item"
                                       (onClick)="onClick.emit($event)"></task-item>
                            <nxt-order-item *ngSwitchCase="'orders'" [item]="item"
                                            (onClick)="onClick.emit($event)"></nxt-order-item>
                            <nxt-quote-item *ngSwitchCase="'quotes'" [item]="item"
                                            (onClick)="onClick.emit($event)"></nxt-quote-item>
                        </ng-container>
                    </li>
                </ng-container>
            </ul>
        </div>
    `
})
export class ItemListComponent implements OnChanges {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() selected: any;
    @Input() noPadding: boolean;
    @Input() noText: boolean;
    @Input() dashboard: boolean;
    @Input() dateHeader: boolean;
    @Input() items: any[];

    _items: any[];
    prettifyPhone = prettifyPhone;
    static: any = {}

    constructor(
        public pSvc: PageService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    getDateHeader(item: any, n: number) {
        if (item) {
            let previous: any = this.items[n - 1];
            if (!item['_date']) {
                item['_date'] = formatDate(item.last_date, 'EEE, MMM do');
            }
            if (!previous || item['_date'] !== previous['_date']) {
                return item['_date'];
            }
        }
    }

    icon(thread: any): string {
        switch (thread?.medium) {
            case EMedium.EMAIL:
                return 'heroicon-outline-mail';
            case EMedium.CHAT:
            case EMedium.NOTE:
                return 'heroicon-outline-chat-alt-2';
            case EMedium.TEXT:
                return 'heroicon-outline-chat-alt';
            // case EMedium.NOTE:
            //     return 'heroicon-outline-document-text';
            default:
                return 'heroicon-outline-question-mark-circle';
        }
    }


}
