import {
    Component, ElementRef, EventEmitter,
    Input, OnChanges, Output, SimpleChanges, ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
    AlgoliaQueryBuilder,
    Contact, EMedium,
    IAlgoliaSearchRequest, IAlgoliaSearchResults,
    isValidEmail, isValidPhone,
    prettifyPhone
} from '@nxt/model-core';
import {AutoCompleteComponent} from '../../nxt/search/autocomplete.component';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ClientService} from '../_services/client.service';
import {PageService} from '../_services/page.service';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '../_inherited/ondestroy.page';

// @ts-ignore
import {environment} from '@env/environment';

import {createPopper, Instance} from '@popperjs/core';
import algoliasearch from 'algoliasearch/lite';
import {FireService} from '../../nxt/_services/fire.service';
import {IconsComponent} from '../icons/icons.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, AutoCompleteComponent, ReactiveFormsModule, IconsComponent
    ],
    selector: 'input-contacts-autocomplete',
    template: `
        <div class="p-1 border-b border-gray-100 rounded-md flex flex-wrap">
            <div [class]="(cc) ? 'bg-light text-dark py-0 px-1 h-7 mb-1 flex rounded min-w-30 mr-2 whitespace-nowrap overflow-hidden place-items-center' : 'bg-dark text-light py-0 px-1 h-7 mb-1 flex rounded min-w-30 mr-2 whitespace-nowrap overflow-hidden place-items-center'"
                 *ngFor="let contact of filteredContacts; let i = index;"
            >
                <span [class]="(cc) ?  'text-dark text-xs' :  'text-light text-xs'"
                      *ngIf="displayStyle==='email'">{{ contact.email }}</span>
                <span [class]="(cc) ?  'text-dark text-xs' :  'text-light text-xs'"
                      *ngIf="displayStyle==='phone'">
                    {{contact.first_name ? contact.name+': ' : ''}}{{ prettifyPhone(contact.phone) }}
                </span>
                <icon name="heroicon-outline-x" (click)="removeItem(i)"
                                    [class]="(cc) ?  'cursor-pointer ml-1 mr-1 h-5 w-5 text-dark' :  'cursor-pointer ml-1 mr-1 h-5 w-5 text-light'"></icon>
            </div>
            <form [formGroup]="form" *ngIf="form" class="relative">
                <input type="search"
                       #inputRef
                       id="searchTerm"
                       autocomplete="off"
                       [placeholder]="placeholder"
                       formControlName="searchTerm"
                       (keyup)="handleKeyup($event)"
                       (blur)="addItemFromForm()"
                       class="border-none bg-transparent w-56 border-transparent focus:border-transparent focus:ring-0 px-1 py-1 h-7"
                />
                <div class="z-40" style="position: absolute;" #popoverDropdownRef>
                    <div
                            class="bg-white text-base z-30 py-2 list-none text-left rounded shadow-lg mb-1"
                            style="min-width: 12rem"
                            *ngIf="matches?.length"
                    >
                        <a *ngFor="let item of matches; let i = index;"
                           (click)="selectContact(item)"
                           [title]="item.name"
                           [class]="(i === selected) ? 'cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-gray-700 bg-gray-100' : 'cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-white text-gray-700 hover:bg-gray-100'"
                        >
                            <span class="text-dark text-xs" *ngIf="displayStyle==='email'">
                                <span *ngIf="item.name!==item.email">{{ item.name }}: </span>
                                {{ item.email }}
                            </span>
                            <span class="text-dark text-xs" *ngIf="displayStyle==='phone'">{{ item.name }}
                                : {{ prettifyPhone(item.phone) }}</span>

                        </a>
                    </div>
                </div>
            </form>

        </div>
    `
})
export class InputContactsComponent extends OnDestroyPage implements OnChanges {
    @ViewChild("inputRef", {static: false}) inputRef: ElementRef;
    @ViewChild("popoverDropdownRef", {static: false}) popoverDropdownRef: ElementRef;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Input() contacts: Contact[] = [];
    @Input() placeholder: string = 'add recipients';
    @Input() displayStyle: 'email' | 'phone' = 'email';
    @Input() cc: boolean;
    @Input() medium: EMedium = EMedium.EMAIL;
    builder: AlgoliaQueryBuilder = new AlgoliaQueryBuilder({index: 'contacts'});
    matches: Contact[];
    form: UntypedFormGroup;
    prettifyPhone = prettifyPhone;
    selected: number = null;
    popoverInstance: Instance;
    filteredContacts: Contact[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private cSvc: ClientService,
        private fSvc: FireService,
        private pSvc: PageService,
        private eRef: ElementRef
    ) {
        super();
        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (e
                        && e.target
                        && this.eRef?.nativeElement
                        && !this.eRef.nativeElement.contains(e.target)
                    ) {
                        this.clear();
                    }
                }
            );
    }

    ngOnChanges(changes: SimpleChanges) {

        this.filteredContacts = (this.contacts||[])?.reduce((items, c) => {
            switch (this.displayStyle) {
                case 'email':
                    if (c.email) {
                        items.push(c);
                    }
                    break;
                case 'phone':
                    if (c.phone) {
                        items.push(c);
                    }
                    break;
            }
            return items;
        },[]);

    }

    ngOnInit() {

        this.form = this.fb.group({
            searchTerm: ['']
        });

        this.form.get('searchTerm').valueChanges
            .pipe(
                debounceTime(550),
                distinctUntilChanged(),
                filter((val) => !!val && typeof val === 'string' && val.length >= 3)
            )
            .pipe(takeUntil(this.d$))
            .subscribe(async (value) => {
                if (value) {
                    try {
                        await this.searchContacts(value);
                    } catch (e) {
                        this.pSvc.alert$.next(e);
                    }
                    this.pSvc.loading$.next(false);;

                }
            });

    }

    async searchContacts(value: string) {

        try {
            const aClient = algoliasearch(environment.algolia.appId, environment.algolia.searchKey);
            const index = aClient.initIndex('contacts');

            this.builder.typoTolerance = 'strict';
            let req: IAlgoliaSearchRequest = Object.assign({}, this.builder.toSearchRequest());
            req.params.facetFilters.push([`_env:${environment.type}`]);
            req.params.facetFilters.push([`_client:${this.cSvc.client_id}`]);
            req.query = value;

            let results: IAlgoliaSearchResults = await index.search(req.query, req.params);
            this.matches = results?.hits.sort((a, b) => {
                let aM: number = 0;
                if (a['_highlightResult'].email?.matchLevel !== 'none') {
                    aM++;
                }
                let bM: number = 0;
                if (b['_highlightResult'].email?.matchLevel !== 'none') {
                    bM++;
                }
                return (aM > bM) ? -1 : 1
            }).reduce((items, item) => {
                if (item.email && !this.filteredContacts.find(c => c.id === item.id)) {
                    let i: Contact = new Contact(item);
                    i._docRef = this.cSvc.client$.getValue()._docRef.collection('contacts').doc(item.id);
                    items.push(i);
                }
                return items;
            }, []);

            if (this.matches.length) {
                this.popoverInstance = createPopper(
                    this.inputRef.nativeElement,
                    this.popoverDropdownRef.nativeElement,
                    {
                        placement: 'auto'
                    }
                );
                this.popoverInstance.forceUpdate();
                // this.cdRef.detectChanges();
            }
        } catch (e) {
            console.warn(e);
        }

    }

    displayFn(hit: any) {
        let result: string = hit.contact?.email;
        if (hit.contact?.name) {
            result += ` (${hit.contact?.name})`;
        }
        return result;
    }

    contactDisplay(contact: Contact, retry?: boolean): string {
        if (contact?.email) {
            if (contact?.name) {
                return `${contact.email} : ${contact.nick_name || contact.name}`;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    removeItem(i: number) {
        this.filteredContacts.splice(i, 1);
        this.onChange.emit(this.filteredContacts?.map(c => new Contact(c)));
    }

    addItem(contact: Contact) {
        contact['_select'] = true;
        if (!this.filteredContacts?.find(i => i.id === contact.id)) {
            this.filteredContacts = this.filteredContacts || [];
            this.filteredContacts.push(contact);
        }
        this.onChange.emit(this.filteredContacts?.map(c => new Contact(c)));
        this.clear();
    }

    clear() {
        this.matches = [];
        this.popoverInstance?.destroy();
        this.selected = null;
        this.form.get('searchTerm').setValue('');
    }

    selectContact(item: Contact) {
        this.addItem(item);
    }

    async handleKeyup(e: KeyboardEvent) {
        switch (e.key) {
            case 'Escape':
                this.form.get('searchTerm').setValue('');
                this.clear();
                break;
            case 'Enter':

                if (this.selected !== null && this.matches.length && this.matches[this.selected]) {
                    this.addItem(this.matches[this.selected]);
                } else {
                    await this.addItemFromForm();
                }
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                let direction: number = (e.key === 'ArrowDown') ? 1 : -1;
                if (this.matches?.length) {
                    if (this.selected === null) {
                        this.selected = 0;
                    } else {

                        for (let i = 0; i < this.matches.length; i++) {
                            if (this.selected === i) {
                                if (this.matches[i + direction]) {
                                    this.selected = i + direction;
                                    break;
                                } else {
                                    this.selected = null;
                                }
                            }
                        }
                    }
                } else {
                    this.selected = null;
                    if (this.form.get('searchTerm').value) {
                        this.searchContacts(this.form.get('searchTerm').value);
                    }
                }
                break;
        }
    }

    async addItemFromForm() {
        if (!this.matches.length) {
            if (this.form.get('searchTerm').value) {
                if (this.medium === EMedium.EMAIL && !isValidEmail(this.form.get('searchTerm').value)) {
                    this.pSvc.alert$.next({
                        title: 'Cannot Add',
                        message: 'This email does not appear to be valid.'
                    });
                } else if (this.medium === EMedium.TEXT && !isValidPhone(this.form.get('searchTerm').value)) {
                    this.pSvc.alert$.next({
                        title: 'Cannot Add',
                        message: 'This phone number does not appear to be valid.'
                    });
                } else {
                    // Add contact
                    try {
                        let data: any = {};
                        if (this.medium === EMedium.TEXT) {
                            data.phone = this.form.get('searchTerm').value
                        } else {
                            data.email = this.form.get('searchTerm').value
                        }
                        let result: any = await this.cSvc.callAPI('/cms/add/contact', 'post', data);
                        this.addItem( (await this.fSvc.getObject(`clients/${this.cSvc.client_id}/contacts/${result.id}`)) as Contact);
                    } catch (e) {
                        console.log(e);
                        this.pSvc.alert$.next(e);
                    }
                }
            }
        }
    }

}
