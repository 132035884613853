import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EMedium, Thread, ThreadMessage} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {ObjectTypeIcon} from '@library/nxt/list/object-type-icon';
import {EThreadContext} from '@library/nxt/_services/messaging.service';

@Component({
    selector: 'activity',
    standalone: true,
    imports: [
        CommonModule,
        ObjectTypeIcon
    ],
    template: `
        <div class="mt-2">
            <div *ngFor="let item of parent.activity"
                 class="ml-2 border-t border-gray-100 text-sm text-gray-400 p-2 {{ (item.medium===EMedium.NOTE||item.medium===EMedium.CHAT) ? (item.inbound===true ? 'bg-red-100' : 'bg-yellow-100') : '' }}"
                [style]="item.unread ? {'border-left': '2px solid red'}: {'border-left':'0px'}"
            >
                <span class="time mr-4">{{ item.date | date:'M/d h:mm a' }}</span>
                <span class="mr-2">{{ item.creator?.nick_name || item.creator?.name || item.creator?.email || item.creator?.phone }}</span>
                <span class="italic"
                        *ngIf="item.subject !== 'Re: '+parent.subject">{{ item.subject }}</span> {{ item.summary }}
            </div>
        </div>
    `
})
export class ActivityComponent implements OnChanges {
    @Input() parent: Thread;
    @Input() context: EThreadContext;
    EThreadContext = EThreadContext;
    EMedium = EMedium;

    ngOnChanges(changes: SimpleChanges) {

    }
}
