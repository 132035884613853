import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule, IconsComponent],
    selector: 'simple-search',
    template: `
        <div class="m-3">
            <div class="mt-1 flex rounded-md shadow-sm">
                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <icon name="heroicon-outline-search" class="h-5 w-5 text-gray-400"></icon>
                    </div>
                    <input type="text" [(ngModel)]="searchTerm" (ngModelChange)="onChange.emit(searchTerm)"
                           class="focus:ring-dark-500 focus:border-dark-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                           [placeholder]="placeholder">
                    <div *ngIf="searchTerm" (click)="searchTerm='';onChange.emit(searchTerm)"
                         class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center">
                        <icon name="heroicon-outline-x" class="h-4 w-4 text-gray-400"></icon>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class SimpleSearch {
    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() placeholder: string = 'Search';
    @Input() searchTerm;
}
