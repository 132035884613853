"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Comment = exports.Email = void 0;
const model_core_1 = require("@nxt/model-core");
const College_1 = require("./College");
const index_1 = require("../index");
class Email extends model_core_1.Base {
    get results() {
        return {
            sent: this.results_sent,
            views: this.results_views,
            emails: this.results_emails
        };
    }
    constructor(data, olMap) {
        super('emails', 32);
        this.msgId = '';
        this.root = false;
        this.io = null; // Inbound or Outbound.
        this.threadId = '';
        this.recipients = [];
        this.subject = '';
        this.snippet = '';
        this.body = '';
        this.html = '';
        this.opts = {};
        this.results_sent = 0;
        this.results_views = 0;
        this.results_emails = 0;
        this.team = null;
        this.cRef = null;
        this.eRef = null;
        this.from = null;
        this.cc = [];
        this.shortUrl = '';
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Email);
    }
    sharePath() {
        var _a, _b, _c;
        if ((_a = this._docRef) === null || _a === void 0 ? void 0 : _a.path) {
            let user_id = (((_c = (_b = this._docRef.parent.parent.parent) === null || _b === void 0 ? void 0 : _b.parent) === null || _c === void 0 ? void 0 : _c.parent.id) === 'users') ? this._docRef.parent.parent.parent.parent.id : this._docRef.parent.parent.id;
            let path = this._docRef.path.replace(`users/${user_id}/emails/`, '');
            return `/public/e/${user_id}/${encodeURIComponent(path)}`;
        }
    }
    emptyImgPath() {
        var _a;
        if ((_a = this._docRef) === null || _a === void 0 ? void 0 : _a.path) {
            let user_id = this._docRef.parent.parent.id;
            let path = this._docRef.path.replace(`users/${user_id}/emails/`, '').replace(/\//g, '~');
            return `/api/profile/${user_id}/${path}/empty.png`;
        }
    }
    toJSON() {
        this.root = (this.msgId === this.threadId);
        return super.toJSON();
    }
}
exports.Email = Email;
class Comment extends model_core_1.Base {
    constructor(data, olMap) {
        super('comments', 14);
        this.creator = null;
        this.ref = null;
        this.message = '';
        this.object = null;
        this.cRef = null;
        this.eRef = null;
        this.email = null;
        this.reactions = {};
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Email);
    }
    async loadAll(opts, stack, p) {
        var _a;
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
        if (this.ref) {
            if (opts === null || opts === void 0 ? void 0 : opts.getDoc) {
                this.object = (0, model_core_1.loadObject)(await opts.getDoc(this.ref), opts);
                await this.object.loadAll(opts);
                if (this.object instanceof College_1.CollegeRef) {
                    this.cRef = this.object;
                }
                else if (this.object instanceof index_1.RcrtEventRef) {
                    this.eRef = this.object;
                }
                else if (this.object instanceof Email) {
                    this.email = this.object;
                    this.cRef = this.email.cRef;
                    this.eRef = this.email.eRef;
                }
            }
            else if ((_a = this.ref) === null || _a === void 0 ? void 0 : _a.get) {
                this.object = (0, model_core_1.loadObject)(await this.ref.get(), opts);
                await this.object.loadAll(opts);
                if (this.object instanceof College_1.CollegeRef) {
                    this.cRef = this.object;
                }
                else if (this.object instanceof index_1.RcrtEventRef) {
                    this.eRef = this.object;
                }
                else if (this.object instanceof Email) {
                    this.email = this.object;
                    this.cRef = this.email.cRef;
                    this.eRef = this.email.eRef;
                }
            }
        }
    }
    react(react) {
        // If the user already replied, remove it;
        Object.keys(this.reactions || {}).forEach(k => {
            if (this.reactions[k] && this.reactions[k].length) {
                let n = this.reactions[k].findIndex(i => i.id === react.u.id);
                if (n > -1) {
                    this.reactions[k].splice(n, 1);
                }
            }
        });
        this.reactions[react.r] = this.reactions[react.r] || [];
        this.reactions[react.r].push({ name: react.u.name, id: react.u.id });
    }
    toJSON() {
        let r = super.toJSON();
        if (!this.ref) {
            throw `Cannot save Comment without a ref!`;
        }
        if (!this.creator) {
            throw `Cannot save Comment without a creator!`;
        }
        if (!this.message) {
            throw `Cannot save Comment without a message!`;
        }
        this.reactions = Object.keys(this.reactions || {}).reduce((map, k) => {
            if (this.reactions[k] && this.reactions[k].length) {
                map[k] = this.reactions[k].map(i => {
                    return { name: i.name, id: i.id };
                });
            }
            return map;
        }, {});
        console.log(this.reactions);
        delete this.object;
        return r;
    }
}
exports.Comment = Comment;
index_1.olm.emails = (ref, map) => {
    return new Email(ref, map);
};
index_1.olm.comments = (ref, map) => {
    return new Comment(ref, map);
};
