import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
    EMedium,
    ThreadMessage,
    EThreadMessageEvents,
    prettifyPhone,
    IMenuItem,
    Thread
} from '@nxt/model-core';

import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {ClientService} from '@library/shared/_services/client.service';
import {CommonModule} from '@angular/common';
import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {FilterMatches} from './filter-matches';
import {ThreadRightClickMenu} from './thread-right-click.menu';
import {PageService} from '@library/shared/_services/page.service';
import {AttachmentViewerComponent} from './attachment-viewer.component';
import {AvatarComponent} from '@library/shared/avatars/avatar.component';
import {AvatarStackComponent} from '@library/shared/avatars/avatar.stack.component';
import {DomSanitizer} from '@angular/platform-browser';
import {ObjectTypeIcon} from '@library/nxt/list/object-type-icon';
import {FollowersComponent} from './followers.component';
import {FromComponent} from './_subcomponents/from.component';
import {ContextComponent} from './_subcomponents/context.component';
import {SubjectComponent} from './_subcomponents/subject.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, MessageMediumIcon, PopButtonComponent, FilterMatches, ThreadRightClickMenu,
        AttachmentViewerComponent, AvatarComponent, AvatarStackComponent, ObjectTypeIcon, FollowersComponent, FromComponent, ContextComponent, SubjectComponent
    ],
    selector: 'message-item-summary',
    template: `
        <div *ngIf="message"
           [id]="message?.id"
           [style]="itemStyle"
           [class]="itemClass"
           (contextmenu)="rightClick($event)"
           (click)="onSelect.emit(message);log(message)"
        >
            <div class="align-top align-items-center text-center p-2">
                <message-medium-icon class="w-4 h-4 text-gray-500" [item]="message"></message-medium-icon>
                <div class="mt-1"></div>
                <span *ngIf="!message.inbound && message.results && message.results[EThreadMessageEvents.SENT]"
                      class="ml-1 text-blue-500 text-xs" title="Sent">
                    {{ (message?.object?._type === 'groups') ? message.results[EThreadMessageEvents.SENT] : 'S' }}
                </span>
                <span *ngIf="message.results && message.results[EThreadMessageEvents.DELIVERED]"
                      class="ml-1 text-green-500 text-xs" title="Delivered">
                    D
                </span>
                <span *ngIf="!message.inbound && message.results && message.results[EThreadMessageEvents.FAILED]"
                      class="ml-1 text-red-600 text-xs" title="Failed">
                    F
                </span>
            </div>
            <div class="flex flex-col p-2 overflow-hidden">
                <from [parent]="message"></from>
                <context [parent]="thread" [context]="context"></context>
                <subject [parent]="message"></subject>
                <div class="w-full mt-1 text-gray-600 text-sm" *ngIf="message.medium !== EMedium.EMAIL">
                    <span *ngIf="!message._html">{{ message.message }}</span>
                    <span [innerHTML]="message._html"></span>
                </div>
                <div class="w-full mt-1 text-gray-600 text-sm" *ngIf="message.medium === EMedium.EMAIL">
                    <span class="text-xs"  [innerHTML]="message._summary"></span>
                </div>
                <filter-matches [parent]="message"></filter-matches>
                <div class="m-2" *ngIf="attachments">
                    <attachment-viewer class="break-all" [condensed]="true"
                                       [parent]="message"></attachment-viewer>
                </div>
            </div>
        </div>
    `
})
export class MessageItemSummary implements OnChanges {
    @Output() onSelect: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Input() parent: any;
    @Input() message: ThreadMessage;
    @Input() selected: boolean;
    @Input() context: EThreadContext;
    @Input() attachments: boolean;
    @Input() avatars: boolean;
    @Input() loadAll: boolean;
    EThreadMessageEvents = EThreadMessageEvents;
    EThreadContext = EThreadContext;
    EMedium = EMedium;
    prettifyPhone = prettifyPhone;
    msgOptions: IMenuItem[];
    history: ThreadMessage[];
    itemStyle: string;
    thread: Thread;

    get itemClass(): string {
        if (this.message.medium === EMedium.NOTE || this.message.medium === EMedium.CHAT) {
            return this.selected ? 'flex p-1 bg-yellow-200 hover:bg-yellow-300' :  'flex p-1 bg-yellow-100 hover:bg-yellow-200';
        } else {
            return this.selected ? 'flex p-1 bg-blue-100 hover:bg-blue-200' :  'flex p-1';
        }
    }

    constructor(
        public cSvc: ClientService,
        public mSvc: MessagingService,
        private pSvc: PageService,
        private sanitizer: DomSanitizer
    ) {
    }

    async ngOnChanges(changes: SimpleChanges) {
        this.msgOptions = await this.mSvc.buildMsgOptions(this.message);
        this.itemStyle = (this.message?.unread || this.message?._unread?.seen === false) ? 'border-left: 2px solid red' : '';
        if (this.message.html && !this.message.message) {
            if (this.message['html_match']) {
                this.message._html = this.sanitizer.bypassSecurityTrustHtml(this.message['html_match']);
            } else {
                this.message._html = this.sanitizer.bypassSecurityTrustHtml(this.message.html);
            }
        }
        if (this.message.summary) {
            if (this.message['summary_match']) {
                this.message['_summary'] = this.sanitizer.bypassSecurityTrustHtml(this.message['summary_match']);
            } else {
                this.message['_summary'] = this.sanitizer.bypassSecurityTrustHtml(this.message.summary);
            }
        }

        await this.message.loadAll({
            loadAllFn: this.mSvc.loadAll,
            olm: this.mSvc.olm,
            properties: this.loadAll ? null : ['files']
        });
        if (this.message?.tRef?.get) {
            this.thread = new Thread( await this.message.tRef.get(), this.mSvc.olm );
        }
    }

    rightClick(e: MouseEvent) {
        e.preventDefault();
        this.pSvc.modal$.next({
            component: ThreadRightClickMenu,
            onLoaded: async (comp: ThreadRightClickMenu) => {
                comp.event = e;
                comp.message = this.message;
                comp.context = this.context;
                comp.init();
            }
        })
    }

    log(msg: ThreadMessage) {
        console.log(msg._docRef.path);
    }

}
