import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {ClientService} from '@library/shared/_services/client.service';
import {CommonModule} from '@angular/common';

import {AvatarStackComponent} from '@library/shared/avatars/avatar.stack.component';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {Client} from '@nxt/model-core';
import {AccountService} from '@library/nxt/_services/account.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,  AvatarStackComponent, PopButtonComponent
    ],
    selector: 'followers-by-role',
    template: `
        <div class="flex w-full" *ngIf="parent">
            <div class="flex-none" *ngIf="label">
                <pop-button [items]="roles"
                            [label]="label"
                            btnClass="btn-gray btn-xs"></pop-button>
            </div>
            <div class="flex place-content-center">
            
                <div *ngFor="let role of parent[property]"
                     class="btn-clear cursor-pointer bg-gray-100 btn-xs text-dark truncate max-w-48 rounded-full"
                     (click)="toggleRoles(role)" title="Click to Remove">
                    {{ role.label  }}
                </div>
                
                <pop-button [items]="roles" *ngIf="parent[property].length"
                            label=" + "
                            btnClass="btn-clear btn-xs"></pop-button>
            </div>
        </div>
    `
})
export class FollowersByRoleComponent implements OnInit {
    @Output() onChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Input() label: string = 'Followers By Role:';
    @Input() parent: any;
    @Input() property: string = 'roles';
    roles: any[];
    dark: string;
    light: string;

    constructor(
        private cSvc: ClientService,
        private aSvc: AccountService
    ) {
        let c: Client = this.cSvc.client$.getValue();
        this.dark = c.theme.dark;
        this.light = c.theme.light;
    }

    async ngOnInit() {
        let uBR = await this.aSvc.getUsersByRole();
        this.roles = [];
        for (let role of this.cSvc.client$.getValue().config.roles) {
            if (uBR[role.id]) {
                this.roles.push({
                    label: (this.parent && this.parent[this.property]?.find(item => item.id === role.id)) ? `- ${role.label}` : `+ ${role.label}`,
                    id: role.id,
                    click: () => {
                        this.toggleRoles(role)
                    }
                });
            }
            this.roles.sort((a,b) => {
                return (a.label < b.label) ? -1 : 1;
            })

        }
    }

    async toggleRoles(role: any) {
        if (this.parent && this.parent[this.property]?.find(r => r.id === role.id)) {
            this.parent.remove(this.property, role);
        } else {
            this.parent.add(this.property, {id: role.id, label: role.label});
        }
        this.ngOnInit();
        this.onChange.emit(this.parent[this.property]);
    }

}
