
export let cbdibLazyComponents: any = {

   'brands-distribution': {
      t: 'Brands Distribution page',
      d: 'CBDib brands distribution page',
      client: 'CBDB',
      loader: () => import('./brands/brands-distribution/brands-distribution'),
      config: () => import('./brands/brands-distribution/brands-distribution-config')
   },
   'brands-process': {
      t: 'Brands Process page',
      d: 'CBDib brands distribution page',
      client: 'CBDB',
      loader: () => import('./brands/brands-process/brands-process'),
      config: () => import('./brands/brands-process/brands-process-config')
   },
   'cbdib-button-color-bg': {
      t: 'Button with blue background color',
      d: 'Button component for CBDib',
      client: 'CBDB',
      loader: () => import('./cbdib-button-color-bg/cbdib-button-color-bg'),
      config: () => import('./cbdib-button-color-bg/cbdib-button-color-bg-config')
   },
   'footer-nav-component': {
      t: 'Footer for CBDib',
      d: 'Footer for CBDib',
      client: 'CBDB',
      loader: () => import('./footer/footer-nav-component'),
      config: () => import('./footer/footer-nav-config')
   },
   'cbdib-get-started-component': {
      t: 'Get Started Component',
      d: 'Button Component for CBDib',
      client: 'CBDB',
      loader: () => import('./get-started/cbdib-get-started-component'),
      config: () => import('./get-started/cbdib-get-started-config')
   },
   'cbdib-hero': {
      t: 'Hero Component',
      d: 'Hero Component for CBDib',
      client: 'CBDB',
      loader: () => import('./hero/hero'),
      config: () => import('./hero/hero-config')
   },
   'featured-products': {
      t: 'Featured Products Component',
      d: 'Featured Products Component for CBDib',
      client: 'CBDB',
      loader: () => import('./home/featured-products/featured-products'),
      config: () => import('./home/featured-products/featured-products-config')
   },
   'home-category-grid': {
      t: 'Home Category Grid Component',
      d: 'Category Grid Component for CBDib',
      client: 'CBDB',
      loader: () => import('./home/home-category-grid/home-category-grid'),
      config: () => import('./home/home-category-grid/home-category-grid-config')
   },
   'how-it-works': {
      t: 'How It Works Component',
      d: 'How it works Component for CBDib',
      client: 'CBDB',
      loader: () => import('./how-it-works/how-it-works'),
      config: () => import('./how-it-works/how-it-works-config')
   },
   'icon-feature-grid': {
      t: 'Icon Feature Grid Component',
      d: 'Icon Feature Grid Component for CBDib',
      client: 'CBDB',
      loader: () => import('./icon-feature-grid/icon-feature-grid'),
      config: () => import('./icon-feature-grid/icon-feature-grid-config')
   },
   'cbdib-nav-component': {
      t: 'Header Nav Component',
      d: 'Header Nav Component for CBDib',
      client: 'CBDB',
      loader: () => import('./nav/cbdib-nav-component'),
      config: () => import('./nav/cbdib-nav-component-config')
   },
   'orange-bg-with-button': {
      t: 'Orange Background with Button Component',
      d: 'Button Component for CBDib, button blue, bg orange',
      client: 'CBDB',
      loader: () => import('./orange-bg-with-button/orange-bg-with-button'),
      config: () => import('./orange-bg-with-button/orange-bg-with-button-config')
   },
   'cbdib-benefits': {
      t: 'Retailer Benefits',
      d: 'Benefits component for home page.',
      client: 'CBDB',
      loader: () => import('./benefits/cbdib-benefits'),
      config: () => import('./benefits/cbdib-benefits-config')
   },
   'why-cbdib': {
      t: 'Why CBDib',
      d: 'Why CBDib section for brands page of site',
      client: 'CBDB',
      loader: () => import('./why-cbdib/why-cbdib'),
      config: () => import('./why-cbdib/why-cbdib-config')
   },
   'cbdib-contact-us-form': {
      t: 'Contact Form for CBDib',
      d: 'Contact From for CBDib',
      client: 'CBDB',
      loader: () => import('./cbdib-contact-us-form/cbdib-contact-us-form'),
      config: () => import('./cbdib-contact-us-form/cbdib-contact-us-form-config')
   },
   'cbdib-shipping-info-form': {
      t: 'Shipping Info Form for CBDib',
      d: 'Shipping Info Form for CBDib',
      client: 'CBDB',
      loader: () => import('./cbdib-shipping-info-form/cbdib-shipping-info-form'),
      config: () => import('./cbdib-shipping-info-form/cbdib-shipping-info-form-config')
   },



}
