import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EMedium, prettifyPhone, Thread, ThreadMessage} from '@nxt/model-core';

import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {ClientService} from '@library/shared/_services/client.service';
import {CommonModule} from '@angular/common';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {FilterMatches} from './filter-matches';
import {ThreadRightClickMenu} from './thread-right-click.menu';
import {AttachmentViewerComponent} from './attachment-viewer.component';
import {AvatarComponent} from '@library/shared/avatars/avatar.component';
import {AvatarStackComponent} from '@library/shared/avatars/avatar.stack.component';
import {ObjectTypeIcon} from '@library/nxt/list/object-type-icon';
import {FollowersComponent} from './followers.component';
import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {ContextComponent} from './_subcomponents/context.component';
import {SubjectComponent} from './_subcomponents/subject.component';
import {ActivityComponent} from './_subcomponents/activity.component';
import {AgentsFollowersTimeComponent} from './_subcomponents/agents-followers-time.component';
import {PageService} from '@library/shared/_services/page.service';
import {Subscription} from 'rxjs';
import {FireService} from '@library/nxt/_services/fire.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';

@Component({
    standalone: true,
    imports: [
        CommonModule, MessageMediumIcon, PopButtonComponent, FilterMatches, ThreadRightClickMenu,
        AttachmentViewerComponent, AvatarComponent, AvatarStackComponent, ObjectTypeIcon,
        FollowersComponent, ContextComponent, SubjectComponent, ActivityComponent, AgentsFollowersTimeComponent
    ],
    selector: 'thread-item',
    template: `
        <div *ngIf="thread"
             [id]="thread?.id"
             [class]="itemClass"
             (contextmenu)="rightClick($event)"
        >
            <div class="flex flex-col place-items-center p-2">
                <message-medium-icon 
                        class="w-4 h-auto text-gray-500" 
                        [item]="thread"
                ></message-medium-icon>
            </div>
            <div class="grow flex flex-col p-2" (click)="onSelect.emit(thread)">
                <context [parent]="thread"></context>
                <subject [parent]="thread"></subject>
                <activity [parent]="thread"></activity>
                <filter-matches [parent]="thread"></filter-matches>
                <agents-followers-time [parent]="thread"></agents-followers-time>
            </div>
        </div>
    `
})
export class ThreadItemComponent extends OnDestroyPage implements OnChanges {
    @Output() onSelect: EventEmitter<Thread> = new EventEmitter<Thread>();
    @Input() parent: any;
    @Input() thread: Thread;
    @Input() selected: boolean;
    @Input() context: EThreadContext;
    @Input() loadAll: boolean;
    EMedium = EMedium;
    prettifyPhone = prettifyPhone;
    EThreadContext = EThreadContext;
    sub: Subscription;

    get itemClass(): string {
        return this.selected ? 'flex p-1 bg-blue-100 hover:bg-blue-200' : this.thread?.unassigned ? 'flex p-1 bg-orange-100 hover:bg-orange-200' : 'flex p-1';
    }

    constructor(
        public cSvc: ClientService,
        public mSvc: MessagingService,
        private pSvc: PageService,
        private fSvc: FireService
    ) {
        super();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.thread) {
            if (this.loadAll) {
                await this.thread.loadAll({
                    loadAllFn: this.mSvc.loadAll,
                    olm: this.mSvc.olm
                });
            }
            this.watchThread();
        }
    }

    async watchThread() {
        if (this.thread._docRef && !this.thread._exists) {
            this.sub?.unsubscribe();
            this.sub = this.fSvc.watchObject(this.thread._docRef, this.d$)
                .subscribe(([t,d]) => {
                    this.thread = t as Thread;
                });
        }
    }

    rightClick(e: MouseEvent) {
        e.preventDefault();
        this.pSvc.modal$.next({
            component: ThreadRightClickMenu,
            onLoaded: (comp: ThreadRightClickMenu) => {
                comp.thread = this.thread;
                comp.event = e;
                comp.context = this.context;
                comp.init();
            }
        })
    }

    log(msg: ThreadMessage) {
        console.log(msg._docRef.path);
    }

}
