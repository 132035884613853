import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';

import {prettifyPhone, Contact} from '@nxt/model-core';

import {OrderQuoteCount} from './order-quote-count';
import {AvatarComponent} from '../../shared/avatars/avatar.component';
import {AvatarStackComponent} from '../../shared/avatars/avatar.stack.component';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        OrderQuoteCount, AvatarComponent, AvatarStackComponent, IconsComponent
    ],
    selector: 'contact-item',
    template: `
        <a *ngIf="contact" (click)="onClick.emit(contact)" [ngStyle]="{'background-color': contact.metadata?.color}">
            <div class="flex place-items-center" >
                <div class="pr-4">
                    <avatar-stack [items]="contact?.agents" size="8"></avatar-stack>
                </div>
                <div class="flex flex-col">
                    <div class="flex w-full justify-between">
                        <div class="flex items-center">
                            <order-quote-count [contact]="contact"></order-quote-count>
                            <strong class="ml-3">{{ contact.name || contact.email || contact.phone }}</strong>
                            <icon *ngIf="contact.active === false" name="heroicon-eye-slash" class="ml-2 h-4 w-4"></icon>
                            <icon *ngIf="contact.verified === true" name="heroicon-solid-check-circle" class="ml-2 h-6 w-6 text-accent-600"></icon>
                            <div *ngIf="contact.verified === false && contact.metadata.request === 'pending'" class="ml-2 h-3 w-3 rounded-full bg-yellow-600 animate-pulse"></div>
                        </div>
                        <div class="time text-right" *ngIf="dateHeader">{{ contact.last_date | date: 'h:mm a' }}</div>
                    </div>
                    <div class="gray flex" *ngIf="contact.name && contact.email">
                        <icon name="heroicon-solid-mail" class="h-5 w-5 text-gray-400"></icon>
                        <span class="line-clamp-2 ml-2">{{ contact.email }}</span>
                    </div>
                    <div class="gray flex" *ngIf="contact.name && !contact.email && contact.phone">
                        <icon name="heroicon-solid-phone" class="h-5 w-5 text-gray-400"></icon>
                        <span class="line-clamp-2 ml-2">{{ prettifyPhone(contact.phone) }}</span>
                    </div>
                </div>
            </div>
        </a>
    `
})
export class ContactItem {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() contact: Contact;
    @Input() dateHeader: boolean;
    prettifyPhone = prettifyPhone;
}

