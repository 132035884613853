"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RcrtUser = exports.RcrtUserTypeMap = exports.ERcrtClubUserRole = exports.ERcrtUserType = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
var ERcrtUserType;
(function (ERcrtUserType) {
    ERcrtUserType["NONE"] = "";
    ERcrtUserType["PLAYER"] = "p";
    ERcrtUserType["PLAYER_COACH"] = "pc";
    ERcrtUserType["COLLEGE_COACH"] = "cc";
    ERcrtUserType["PARENT"] = "pp";
    ERcrtUserType["ADVISOR"] = "a";
})(ERcrtUserType || (exports.ERcrtUserType = ERcrtUserType = {}));
var ERcrtClubUserRole;
(function (ERcrtClubUserRole) {
    ERcrtClubUserRole["NONE"] = "";
    ERcrtClubUserRole["READ_ONLY"] = "r";
    ERcrtClubUserRole["READ_WRITE"] = "rw";
})(ERcrtClubUserRole || (exports.ERcrtClubUserRole = ERcrtClubUserRole = {}));
exports.RcrtUserTypeMap = {
    p: 'Player',
    pc: 'HS/Club Coach/Admin',
    cc: 'College Coach',
    pp: 'Parent',
    a: 'Advisor'
};
class RcrtUser extends model_core_1.User {
    get results() {
        return {
            sent: this.results_sent,
            views: this.results_views,
            comments: this.results_comments,
            emails: this.results_emails,
            shares: this.results_shares
        };
    }
    get sports() {
        return this.teams.reduce((sports, team) => {
            if (!sports.find(i => i.gender === team.gender && i.sport === team.sport)) {
                sports.push(team);
            }
            return sports;
        }, []);
    }
    get canSync() {
        var _a;
        if ((_a = this.email) === null || _a === void 0 ? void 0 : _a.match(/gmail\.com$/)) {
            return true;
        }
        else {
            return false;
        }
    }
    get isSynced() {
        return (this.metadata.email) ? true : false;
    }
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm);
        this.image = null;
        this.type = ERcrtUserType.NONE;
        this.bio = {};
        this.address = null;
        this.teams = [];
        this.links = {};
        this.collab = '';
        this.club = '';
        this.rating_factors = [];
        this.event_refs = new model_core_1.ChildArray();
        this.college_refs = new model_core_1.ChildArray();
        this.collabs = new model_core_1.ChildArray();
        this.shares = new model_core_1.ChildArray();
        this.orders = new model_core_1.ChildArray();
        this.last_check = 0;
        this.results_sent = 0;
        this.results_views = 0;
        this.results_comments = 0;
        this.results_emails = 0;
        this.results_shares = 0;
        this.results_collabs = 0;
        this.counts = { colleges: 0, archived: 0, events: 0, collabs: 0 };
        this._m = ['name', 'email', 'first_name', 'last_name'];
        this._s = ['name', 'email', 'first_name', 'last_name', 'teams'];
        this._d = ['addresses', 'cards', 'devices', 'location', 'location_id', 'nick_name', 'online', 'photoURL', 'role', 'signature', 'tax', 'unread'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtUser);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a, _b;
        let r = super.toJSON();
        ['x', 'instagram'].forEach(p => {
            if (this.links[p]) {
                let parts = this.links[p].split('/');
                if (parts.length) {
                    this.links[p] = parts[parts.length - 1];
                }
                this.links[p] = this.links[p].replace('@', '');
            }
        });
        if ((_a = this.teams) === null || _a === void 0 ? void 0 : _a.length) {
            r.teams = (_b = this.teams) === null || _b === void 0 ? void 0 : _b.map(t => {
                return t;
            });
        }
        return r;
    }
    toPublicJSON() {
        let r = this.toJSON();
        ['college_refs', 'event_refs'].forEach(p => {
            delete r[p];
        });
        return r;
    }
}
exports.RcrtUser = RcrtUser;
index_1.olm.users = (ref, map) => {
    return new RcrtUser(ref, map);
};
