import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    async handleError(err: any) {
        const chunkFailedMessage = /ChunkLoadError/;
        if (chunkFailedMessage.test(err.message)) {
            if (confirm('Looks like there is a new version available that must be downloaded. Click OK to reload.')) {
                try {
                    await Promise.all((await caches.keys()).map(caches.delete))
                } catch (e) {
                    console.warn(e);
                }
                window.location.reload();
            }
        }
        if (
            !err.toString().match(/ExpressionChangedAfterItHasBeenCheckedError|triggerButtons/)
        ) {
            console.warn(err);
        }
    }
}
