import {Input, Component, OnChanges, SimpleChanges} from '@angular/core';
import {EMedium, Thread, ThreadMessage} from '@nxt/model-core';
import {NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [
        NgSwitch, NgSwitchCase, NgSwitchDefault, IconsComponent
    ],
    selector: 'message-ref-icon',
    template: `
        <ng-container [ngSwitch]="type">
            <icon name="heroicon-solid-fire" 
                    *ngSwitchCase="'quotes'"
                    [class]="class"
            ></icon>
            <icon name="heroicon-outline-currency-dollar"
                    *ngSwitchCase="'orders'"
                    [class]="class"
            ></icon>
            <icon name="heroicon-outline-credit-card" 
                    *ngSwitchCase="'contacts'"
                    [class]="class"
            ></icon>
            <icon name="custom-flight-takeoff"
                    *ngSwitchCase="'trips'"
                    [class]="class"
            ></icon>
            <icon name="heroicon-outline-clipboard-check" 
                    *ngSwitchCase="'tasks'"
                    [class]="class"
            ></icon>
            <icon name="heroicon-outline-folder" 
                    *ngSwitchCase="'pages'"
                    [class]="class"
            ></icon>
            <icon name="heroicon-outline-cloud-upload"
                    *ngSwitchCase="'deployments'"
                    [class]="class"
            ></icon>
            <icon name="heroicon-outline-user-circle" 
                    *ngSwitchCase="'users'" 
                    [class]="class"
            ></icon>
            <icon name="heroicon-wrench" 
                    *ngSwitchCase="'work_orders'" 
                    [class]="class"
            ></icon>
        </ng-container>
    `
})
export class MessageRefIcon implements OnChanges {
    @Input() item: ThreadMessage | Thread;
    @Input() class: string = 'w-12 h-12 text-gray-500';
    EMedium = EMedium;
    type: string;

    ngOnChanges(changes: SimpleChanges) {
        if (this.item?.object?._type) {
            this.type = this.item?.object?._type;
        } else if (this.item?.ref?.path) {
            this.type = this.item.ref.path.split('/')[this.item.ref.path.split('/').length - 2];
        }
    }


}
