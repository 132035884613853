"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EwinOrderItem = exports.productEncodingProperties = void 0;
const model_core_1 = require("@nxt/model-core");
const Options_1 = require("./Options");
const index_1 = require("../index");
exports.productEncodingProperties = ['window.style', 'window.quality', 'window.type', 'frame.exterior', 'window.number', 'glass.type', 'grid.type', 'grid.pattern'];
class EwinOrderItem extends model_core_1.OrderItem {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm);
        this.label = '';
        this.location = {
            zip: '',
            zone: '',
            zoneLabel: '',
            state: '',
            county: '',
            east: false,
            west: false
        };
        this.window = {
            type: { label: '', value: '' },
            style: { label: '', value: '' },
            quality: { label: '', value: '' },
            sash: { label: '', value: '' },
            number: { label: '', value: '' },
            operation: { label: '', value: '' }
        };
        this.frame = {
            exterior: { label: '', value: '' },
            interior: { label: '', value: '' },
            strength: { label: '', value: '' },
            drywall_remove: { label: '', value: '' },
            jamb_extender: {}
        };
        this.glass = {
            type: { label: '', value: '' },
            temper: { label: '', value: '' },
            tint: { label: '', value: '' },
            privacy: { label: '', value: '' },
            laminate: { label: '', value: '' },
            obscure: { label: '', value: '' }
        };
        this.grid = {
            include: { label: '', value: '' },
            color: {},
            type: { label: '', value: '' },
            pattern: { label: '', value: '' },
            upper: {
                horiz: { label: '', value: '' },
                vert: { label: '', value: '' }
            },
            lower: {
                horiz: { label: '', value: '' },
                vert: { label: '', value: '' }
            }
        };
        this.screen = {
            boxed: { label: '', value: '' },
            type: { label: '', value: '' }
        };
        this.hardware = {
            performance: { label: '', value: '' },
            ada_lock: { label: '', value: '' }
        };
        this.options = [];
        this.unit_weight = .215;
        this._alerts = [];
        this._orderable = true;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, EwinOrderItem);
        if (!this.dimensions.get('Length')) {
            this.dimensions.push({ name: 'Length', variable: false, inches: 3 });
        }
        ['Width', 'Height'].forEach(p => {
            if (!this.dimensions.get(p)) {
                this.dimensions.push({ name: p, variable: true, inches: 0 });
            }
        });
        this.getName();
    }
    toString() {
        return this.getName(true);
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        Object.assign(r, this.toJSON());
        return r;
    }
    toJSON() {
        var _a, _b, _c;
        let r = super.toJSON();
        r.quantity = this.quantity;
        r.total_weight = this.total_weight;
        r.options = this.options || [];
        delete r.pricing;
        if ((_c = (_b = (_a = this.frame) === null || _a === void 0 ? void 0 : _a.jamb_extender) === null || _b === void 0 ? void 0 : _b.length) === null || _c === void 0 ? void 0 : _c.toJSON) {
            r.frame.jamb_extender.length = this.frame.jamb_extender.length.toJSON();
        }
        return r;
    }
    getName(includeDims) {
        var _a, _b, _c;
        this.name = '';
        try {
            if (includeDims) {
                this.name = this.getDimensionsString() + ' ';
            }
            if (this.window.style.label) {
                this.name += this.window.style.label;
            }
            if (this.window.type.value === 'NEW' && this.window.quality.label) {
                this.name += ' ' + this.window.quality.label;
            }
            if (this.grid.type.value && this.grid.pattern.label) {
                this.name += ' ' + this.grid.pattern.label;
            }
            if (this.window.type.label) {
                this.name += ' ' + this.window.type.label;
            }
            if ((_a = this.window.number.value) === null || _a === void 0 ? void 0 : _a.match(/2|3/)) {
                this.name += ` (${this.window.number.value}-wide)`;
            }
            this.name += ' Window';
            if ((_c = (_b = this.location) === null || _b === void 0 ? void 0 : _b.installation) === null || _c === void 0 ? void 0 : _c.value) {
                this.name += ` (w/ Installation)`;
            }
        }
        catch (e) { }
        return this.name;
    }
    getDimensionsString() {
        var _a, _b;
        if (((_a = this.dimensions.get("Width")) === null || _a === void 0 ? void 0 : _a.size) && ((_b = this.dimensions.get("Height")) === null || _b === void 0 ? void 0 : _b.size)) {
            return `${this.dimensions.get("Width").toInchesString()} x ${this.dimensions.get("Height").toInchesString()}`;
        }
        else {
            return '';
        }
    }
    encode() {
        const dimensionOrder = ['Width', 'Height'];
        let result = [];
        // Push all the data properties, in the order specified above.
        exports.productEncodingProperties.forEach((prop) => {
            let part = eval(`this.${prop}.value`);
            result.push(part || '');
        });
        // Now append width & height, in that order.
        dimensionOrder.forEach((dim) => {
            result.push(this.dimensions.get(dim).size);
        });
        return result.join('_');
    }
    calc(u) {
        var _a, _b, _c;
        // Confirm orderability;
        let options = this.getOptions(u);
        if (this.quantity && this.unit_weight && this.dimensions instanceof model_core_1.DimensionArray && ((_a = this.dimensions) === null || _a === void 0 ? void 0 : _a.get('Height')) && ((_b = this.dimensions) === null || _b === void 0 ? void 0 : _b.get('Width'))) {
            let lin_inches = (Number(this.dimensions.get('Height').size) + Number(this.dimensions.get('Width').size)) * 2;
            this._override_weight = (0, model_core_1.round)(this.quantity * lin_inches * this.unit_weight, 2);
        }
        if ((this._orderable || this.metadata.override) && this.pricing && this.quantity) {
            try {
                let matches = {};
                let notes = {};
                let price_per_li = 0;
                let multipliers = [];
                let flat = 0;
                let getPrice = (attributes, level, debug) => {
                    var _a;
                    if (attributes === null || attributes === void 0 ? void 0 : attributes.length) {
                        for (let attr of attributes) {
                            if (debug) {
                                console.log(`${level}. ${attr.prop}.${attr.subprop}: ${attr.value}`);
                            }
                            //
                            let disabled = eval(`(options.${attr.prop} && options.${attr.prop}.${attr.subprop} && options.${attr.prop}.${attr.subprop}.disabled) ? options.${attr.prop}.${attr.subprop}.disabled(this, u) : false`);
                            let exists = eval(`this.${attr.prop} && this.${attr.prop}.${attr.subprop} ? true : false`);
                            let match = eval(`exists && this.${attr.prop}.${attr.subprop}.value === attr.value && !disabled`);
                            if (!match && attr.value === 'YES') {
                                match = eval(`exists && this.${attr.prop}.${attr.subprop}.value === true && !disabled`);
                            }
                            if (debug) {
                                console.log(`disabled:${disabled}; exists:${exists}; match:${match}`);
                            }
                            if (attr.prop === 'location' && attr.subprop !== 'installation') {
                                disabled = false;
                                if (attr.value === 'any') {
                                    match = eval(`!!this.${attr.prop}.${attr.subprop}`);
                                }
                                else if (attr.value === true || attr.value === false) {
                                    match = eval(`this.${attr.prop}.${attr.subprop} === ${attr.value}`);
                                }
                                else {
                                    match = eval(`this.${attr.prop}.${attr.subprop} === '${attr.value}'`);
                                }
                            }
                            if (debug) {
                                console.log(`disabled:${disabled}; exists:${exists}; match:${match}`);
                            }
                            let any = eval(`exists && !!this.${attr.prop}.${attr.subprop}.value && !disabled`);
                            if (!match && attr.value === 'any' && any) {
                                match = true;
                            }
                            if (debug) {
                                console.log(`disabled:${disabled}; exists:${exists}; match:${match}`);
                            }
                            if (match) {
                                if (attr.price) {
                                    return attr;
                                }
                                else if ((_a = attr.attributes) === null || _a === void 0 ? void 0 : _a.length) {
                                    return getPrice(attr.attributes, level + 1);
                                }
                                else {
                                    return attr;
                                }
                            }
                        }
                    }
                };
                for (const id of Object.keys(this.pricing.components)) {
                    let desc = this.pricing.components[id].description;
                    let attr = getPrice((_c = this.pricing.components[id]) === null || _c === void 0 ? void 0 : _c.attributes, 1, desc === 'Installation');
                    if (attr === null || attr === void 0 ? void 0 : attr.price) {
                        matches[desc] = (matches[desc]) ? matches[desc] + Number(attr.price) : Number(attr.price);
                        if (attr.note) {
                            // console.log('attr.note', attr.note);
                            notes[desc] = (notes[desc]) ? notes[desc] + '; ' + attr.note : attr.note;
                        }
                        if (this.pricing.components[id].multiplier) {
                            multipliers.push(Number(attr.price));
                        }
                        else if (this.pricing.components[id].flat) {
                            flat += Number(attr.price);
                        }
                        else {
                            price_per_li += Number(attr.price);
                        }
                    }
                }
                // CUSTOM OPTIONS (NXT-ONLY)
                if (this.options && this.options.length) {
                    this.options.forEach((option) => {
                        if (option.price && !isNaN(Number(option.price))) {
                            matches[option.description] = option.price;
                            price_per_li += Number(option.price);
                        }
                    });
                }
                // Calculate Linear Inches:
                if (this.dimensions.get('Height').size && this.dimensions.get('Width').size) {
                    let li = ((this.dimensions.get('Height').size + this.dimensions.get('Width').size) * 2);
                    if (this.pricing.metadata.minimum && Number(this.pricing.metadata.minimum) > li) {
                        li = Number(this.pricing.metadata.minimum);
                        matches['Use Minimum Linear Inches'] = this.pricing.metadata.minimum;
                    }
                    matches['Price/Linear Inch'] = price_per_li;
                    if (multipliers.length) {
                        for (let mult of multipliers) {
                            price_per_li = price_per_li * mult;
                        }
                    }
                    matches['Price/Linear Inch With Multipliers'] = price_per_li;
                    matches['Linear Inches'] = li;
                    this.metadata.pricing = matches;
                    this.metadata.pricing_notes = notes;
                    this.price = (0, model_core_1.round)((price_per_li * li) + flat, 2);
                }
            }
            catch (e) {
                console.warn(e);
            }
        }
        if (this.price && this.quantity) {
            this.subtotal = this.price * this.quantity;
        }
        else {
            delete this.subtotal;
        }
    }
    // Loop through selections and remove any defaulted selections.
    reset(options, obj) {
        // Object.keys(options).forEach((prop: string) => {
        //     let p = prop.toLowerCase();
        //     if (obj[p] && obj[p].default) {
        //         obj[p] = {label:'', value:''};
        //     } else if (typeof options[p] === 'object' && obj[p]) {
        //         this.reset(options[p], obj[p])
        //     }
        // });
    }
    validateSelection(selected, options, u) {
        for (const option of options) {
            if (option.value === selected.value && option.disabled) {
                if (typeof option.disabled === 'function') {
                    return option.disabled(this, u);
                }
                else {
                    return option.disabled || false;
                }
            }
        }
    }
    ;
    /**
     * This method sets default and locked values as well as identifies any illegal selections. It also
     * populates the _alerts array with alert messages for some illegal selections.
     * @param options: hash map created via the getOptions() method.
     * @param p: top-level property (e.g. window, frame, grid)
     * @param sp: second-level property (e.g. window.type, grid.upper)
     * @param ssp: third-level property (e.g. grid.upper.vert, grid.lower.horiz)
     * @param disabled: result of disabled() function call found in the property maps above.
     * @param locked: result of locked() function call found in property maps above.
     * @param dflt: gets the default value of a property, result of default() function call found in property maps above.
     */
    setVals(options, p, sp, ssp, disabled, locked, dflt, required, u) {
        let obj = (ssp) ? this[p][sp][ssp] || {} : this[p][sp] || {};
        if (locked !== undefined) {
            obj = {
                locked: true,
                value: locked.value,
                label: locked.label
            };
        }
        if (dflt !== undefined && (obj.value === '' || obj.default || obj.locked)) {
            if (isNaN(dflt)) {
                console.warn(`Default value is invalid for ${p}.${sp}.${ssp || ''}`);
            }
            if (options[ssp || sp].options[dflt]) {
                obj = JSON.parse(JSON.stringify(options[ssp || sp].options[dflt]));
            }
            obj.default = true;
        }
        // Validate selection.
        if (!disabled) {
            obj.disabled = this.validateSelection(obj, options[ssp || sp].options, u);
        }
        else {
            obj.disabled = disabled;
        }
        if (obj.disabled) {
            if (obj.disabled !== true) {
                this._alerts.push({ prop: p, subprop: sp, subsubprop: ssp, reason: obj.disabled });
            }
            obj.disabled = true;
        }
        else {
            delete obj.disabled;
        }
        if (required && !obj.value) {
            this._alerts.push({ prop: p, subprop: sp, subsubprop: ssp, reason: required });
        }
        // Need this so product name is more than just 'Window'
        ['label'].forEach(prop => {
            var _a, _b;
            if (!obj[prop] && ((_b = (_a = options[ssp || sp]) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.find(i => i.value === obj.value))) {
                obj[prop] = options[ssp || sp].options.find(i => i.value === obj.value)[prop];
            }
        });
        if (ssp) {
            this[p][sp][ssp] = obj;
        }
        else {
            this[p][sp] = obj;
        }
    }
    find(options, val) {
        let obj = options.find(i => i.value === val);
        if (obj) {
            obj = JSON.parse(JSON.stringify(obj));
            delete obj.default;
        }
        return obj;
    }
    // Loop through available options and disable any choices on the current item that match disabled options.
    validate(options, stack, user) {
        Object.keys(options).forEach((prop) => {
            let p = prop.toLowerCase();
            if (!p.match(/height|width|area/i)) {
                if (typeof options === 'object' && !options[p].options) {
                    this.validate(options[p], stack ? stack.concat(p) : [p], user);
                }
                else if (options[p] && options[p].options) {
                    // Set default or locked values as needed, and check for illegal selections.
                    this.setVals(options, stack[0], (stack[1]) ? stack[1] : p, (stack[1]) ? p : null, options[p].disabled ? options[p].disabled(this, user) : undefined, options[p].locked ? options[p].locked(this, user) : undefined, options[p].default ? options[p].default(this, user) : undefined, options[p].required ? options[p].required(this, user) : undefined, user);
                }
            }
            else if (p.match(/height|width/i)) {
                // console.log(this.dimensions);
                if (!(this.dimensions instanceof model_core_1.DimensionArray)) {
                    this.dimensions = new model_core_1.DimensionArray(this.dimensions);
                }
                let size = this.dimensions.get(prop).size;
                if (size && options[prop]) {
                    if (options[prop].mn && size < options[prop].mn) {
                        this._alerts.push({
                            prop: 'frame',
                            subprop: prop,
                            reason: `Frame ${prop.toLowerCase()} must be at least ${options[prop].mn}".`,
                        });
                    }
                    if (options[prop].mx && size > options[prop].mx) {
                        this._alerts.push({
                            prop: 'frame',
                            subprop: prop,
                            reason: `Frame ${prop.toLowerCase()} must be ${options[prop].mx}" or less.`,
                        });
                    }
                }
            }
            else if (options[prop]) {
                let w = this.dimensions.get('Width').size;
                let h = this.dimensions.get('Height').size;
                if (w && h) {
                    // FrameUI check. FrameUI is the H+W limits in the window-builder software.
                    if (options[prop].fui && (w + h) > options[prop].fui) {
                        this._alerts.push({
                            prop: "frame",
                            subprop: prop,
                            reason: `Frame height + width must be less than ${options[prop].fui}".`,
                        });
                    }
                    else if (options[prop].a && (w * h) > options[prop].a) {
                        this._alerts.push({
                            prop: "frame",
                            subprop: prop,
                            reason: `Frame area (height x width) is too large, and must be less than ${options[prop].a} sq in.`,
                        });
                    }
                }
            }
        });
    }
    // Abbreviation functions, just to get to property values more succinctly.
    geo() {
        return this.location.west ? 'west' : 'east';
    }
    qlty() {
        return (this.window.type.value === Options_1.WINDOW_TYPE.REPLACEMENT || this.window.quality.value === Options_1.QUALITY.PREMIUM) ? Options_1.QUALITY.PREMIUM : this.window.quality.value || '';
    }
    styl() {
        return this.window.style.value || ''; // Style (SH/DH/PIC/SLI)
    }
    num() {
        return this.window.number.value || '';
    }
    ssh() {
        return this.window.sash.value || '';
    }
    ptrn() {
        return this.grid.pattern.value || '';
    }
    grd() {
        return this.grid.type.value || '';
    }
    typ() {
        return this.window.type.value || '';
    }
    h() {
        var _a, _b, _c;
        return ((_a = this.dimensions) === null || _a === void 0 ? void 0 : _a.get) ? ((_c = (_b = this.dimensions) === null || _b === void 0 ? void 0 : _b.get('Height')) === null || _c === void 0 ? void 0 : _c.size) || 0 : 0;
    }
    w() {
        var _a, _b, _c;
        return ((_a = this.dimensions) === null || _a === void 0 ? void 0 : _a.get) ? ((_c = (_b = this.dimensions) === null || _b === void 0 ? void 0 : _b.get('Width')) === null || _c === void 0 ? void 0 : _c.size) || 0 : 0;
    }
    /**
     * Returns hash of available options given the current selections
     * made on the product.
     */
    getOptions(u) {
        // Full options pulled from constants.
        let result = {
            window: Options_1.WINDOW,
            frame: Options_1.FRAME,
            location: Options_1.LOCATION,
            glass: Options_1.GLASS,
            screen: Options_1.SCREEN,
            grid: Options_1.GRID,
            hardware: Options_1.HARDWARE,
            Width: {},
            Height: {},
            Area: {}
        };
        // Based on configuration of order item, pare down options.
        this.reset(result, this);
        // Set min/max height/width, and max area based on window type.
        let getD = (geo, dim, prop) => {
            let result;
            let numWide = (this.window.number.value) ? Number(this.window.number.value) : 1;
            try {
                let o = Options_1.FRAME_LIMITS[geo][this.qlty()][this.styl()];
                if (o instanceof Function) {
                    o = o(this, this.dimensions.get('Width').size, this.dimensions.get('Height').size);
                    result = prop ? o[dim][prop] : o[dim];
                }
                else {
                    if (this.styl() === Options_1.WINDOW_STYLE.SLIDER) {
                        o = o[this.ssh()];
                    }
                    if (dim === 'fui' || dim === 'a') {
                        return o[dim];
                    }
                    else if (numWide === 1) {
                        if (typeof o[dim][prop] === 'function') {
                            result = o[dim][prop](this.dimensions.get((dim === 'w') ? 'Height' : 'Width').size);
                        }
                        else {
                            result = o[dim][prop];
                        }
                    }
                    else if (o[numWide]) {
                        if (typeof o[numWide][`${prop}${dim}`] === 'function') {
                            result = o[numWide][`${prop}${dim}`](this.dimensions.get((dim === 'w') ? 'Height' : 'Width').size);
                        }
                        else {
                            result = o[numWide][`${prop}${dim}`];
                        }
                    }
                }
                return result;
            }
            catch (e) {
                // console.warn(`${geo},${dim},${prop}`, e);
            }
        };
        result.Width = {
            mn: getD(this.geo(), 'w', 'mn'),
            mx: getD(this.geo(), 'w', 'mx')
        };
        result.Height = {
            mn: getD(this.geo(), 'h', 'mn'),
            mx: getD(this.geo(), 'h', 'mx')
        };
        result.Area = {
            fui: getD(this.geo(), 'fui'),
            a: getD(this.geo(), 'a')
        };
        this._alerts = [];
        this.validate(result, null, u);
        if (!this.location.zip || this.location.zip.length < 5 || this.location.error) {
            this._alerts.push({ prop: 'location', subprop: 'zip', reason: this.location.error || 'Zip code is required.' });
        }
        if (!this.dimensions.get('Height').size || !this.dimensions.get('Width').size) {
            this._alerts.push({ prop: 'frame', subprop: 'size', reason: `Height & Width must be set.` });
        }
        if (this.grid.type.value && !this.grid.pattern.value) {
            this._alerts.push({ prop: 'grid', subprop: 'pattern', reason: 'Grid pattern must be set or grid option removed.' });
        }
        if (this.window.type.value === 'NEW' && !this.window.quality.value) {
            this._alerts.push({ prop: 'window', subprop: 'quality', reason: 'Window quality selection is required.' });
        }
        this._orderable = (this._alerts.length === 0);
        return result;
    }
}
exports.EwinOrderItem = EwinOrderItem;
index_1.olm.items = (ref, map) => {
    return new EwinOrderItem(ref, map);
};
