import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {createPopper} from "@popperjs/core";

//@ts-ignore
import {environment} from '@env/environment';
import {ClientService} from '@library/shared/_services/client.service';
import {IMenuItem, loadObject, Thread, ThreadMessage} from '@nxt/model-core';
import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {PageService} from '@library/shared/_services/page.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {FireService} from '@library/nxt/_services/fire.service';


@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="z-40" style="min-width: 12rem" #popoverDropdownRef>
            <div class="bg-white text-base float-left py-2 list-none text-left rounded shadow-lg mb-1 border-dark"
                 style="min-width: 12rem; border-width: 1px;"
            >
                <ng-container *ngFor="let item of threadOptions">
                    <ng-container *ngIf="!item.hide">
                        <div (click)="handleClick(item)" [style.color]="item.color"
                             class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                        >
                            <div>{{ item.label ? item.label : item }}</div>
                            <span class="text-xs font-italic" *ngIf="item.tooltip">{{ item.tooltip }}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    `
})
export class ThreadRightClickMenu extends OnDestroyPage implements AfterViewInit {
    @ViewChild("popoverDropdownRef", {static: false}) popoverDropdownRef: ElementRef;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    event: MouseEvent;
    thread: Thread;
    message: ThreadMessage;
    threadOptions: IMenuItem[];
    context: EThreadContext;

    constructor(
        private router: Router,
        private pSvc: PageService,
        private cSvc: ClientService,
        private fSvc: FireService,
        private mSvc: MessagingService,
        private eRef: ElementRef,
        @Inject('ObjectLoaderService') private oSvc: any
    ) {
        super();
    }

    async init() {

        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (e
                        && e.target
                        && this.eRef?.nativeElement
                        && !this.eRef.nativeElement.contains(e.target)
                    ) {
                        this.onClose.emit();
                    }
                }
            );

        if (this.message && !this.message._exists) {
            this.message = (await this.fSvc.getObject(`clients/${this.cSvc.client_id}/threadsmsgs/${this.message.id}`)) as ThreadMessage;
        }
        if (this.message?.tRef?.get && !this.thread) {
            this.thread = new Thread( await this.message.tRef?.get(), this.mSvc.olm );
        }
        if (this.thread) {
            this.thread.object = this.thread.object ? this.thread.object : this.thread.ref?.get ? loadObject(await this.thread.ref?.get(), {olm:this.oSvc.getOLM(this.cSvc.name_key)}) : null;
            this.threadOptions = await this.mSvc.buildThreadOptions(this.thread, this.context || EThreadContext.INBOX, this.message);
        }

    }

    ngAfterViewInit() {
        createPopper(
            {
                getBoundingClientRect: this.generateGetBoundingClientRect(this.event.x, this.event.y)
            } as HTMLElement,
            this.popoverDropdownRef.nativeElement,
            {
                placement: 'auto'
            }
        );
    }

    generateGetBoundingClientRect(x = 0, y = 0) {
        return () => ({
            width: 0,
            height: 0,
            top: y,
            right: x,
            bottom: y,
            left: x,
        });
    }

    async handleClick(item: IMenuItem) {
        if (item.click) {
            item.click();
        } else if (item.routerLink) {
            await this.router.navigate([item.routerLink]);
        }
        this.onClose.emit(item);
    }

}
