"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadAll = exports.olm = void 0;
const model_core_1 = require("@nxt/model-core");
const model_core_2 = require("@nxt/model-core");
exports.olm = Object.assign({}, model_core_1.olm);
__exportStar(require("./src/Product"), exports);
__exportStar(require("./src/Order"), exports);
async function loadAll(parent, opts, stack, p) {
    opts = opts || {};
    opts.olm = opts.olm || exports.olm;
    await (0, model_core_2.loadAll)(parent, opts, stack, p);
}
exports.loadAll = loadAll;
