import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Task} from '@nxt/model-core';
import {DomSanitizer} from '@angular/platform-browser';
import {AvatarComponent} from '../../shared/avatars/avatar.component';
import {AvatarStackComponent} from '../../shared/avatars/avatar.stack.component';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [CommonModule, AvatarComponent, AvatarStackComponent, IconsComponent],
    selector: 'task-item',
    template: `
        <a (click)="onClick.emit(task)" *ngIf="task">
            <div class="flex pl-2 w-full justify-between">
                <div class="flex flex-col">
                    <span class="font-bold">{{ task.name || '' }}</span>
                    <div class="flex space-x-3 p-1">
                        <div class="italic text-sm">Change Group: {{ task.changeId||'N/A' }}</div>
                        <avatar-stack [items]="task.agents" size="6"></avatar-stack>
                    </div>
                </div>
                <div class="ml-2 p-2 w-28 flex flex-col upperclass text-xs whitespace-nowrap font-bold">
                    <div [class]="(task.index < 30) ? 'text-green-600' : (task.index < 60) ? 'text-yellow-500' : 'text-red-500'">
                        <span class="mr-1" *ngIf="task.index<30">LOW</span>
                        <span class="mr-1" *ngIf="task.index>=30 && task.index<60">MED</span>
                        <span class="mr-1" *ngIf="task.index>=60">HI</span>
                        PRIORITY
                    </div>
                    <div [class]="(task.completed===100) ? 'text-blue-600' : (task.completed > 60) ? 'text-blue-400' : (task.completed > 30) ? 'text-blue-200' : 'text-orange-500'">
                        <span class="mr-1">{{task.completed||'0'}}%</span>
                        COMPLETE
                    </div>
                    <div [class]="(task.tested===100) ? 'text-blue-600' : (task.tested > 60) ? 'text-blue-400' : (task.tested > 30) ? 'text-blue-200' : 'text-orange-500'">
                        <span class="mr-1">{{task.tested||'0'}}%</span>
                        TESTED
                    </div>
                </div>
            </div>
            <div class="text-gray-500 ml-6 max-h-100 overflow-hidden" [innerHTML]="html">
            </div>
        </a>
    `
})
export class TaskItem implements OnChanges {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() task: Task;
    html: any;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.task.description) {
            this.html = this.sanitizer.bypassSecurityTrustHtml(this.task.description);
        } else {
            this.html = null;
        }
    }
}
