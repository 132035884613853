"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetalsQuote = exports.MetalsCart = exports.MetalsOrder = void 0;
const model_core_1 = require("@nxt/model-core");
const OrderItem_1 = require("./OrderItem");
const index_1 = require("../index");
class MetalsOrder extends model_core_1.Order {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type, idLen);
        this.pricing = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, MetalsOrder);
    }
    async loadAll(opts, stack, p) {
        var _a;
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
        // TODO Deprecate once we retroactively update work arrays on all historical orders.
        if (!((_a = this.work) === null || _a === void 0 ? void 0 : _a.length) && this._docRef) {
            let q = await this._docRef.parent.parent.collection('work_orders').where('order_id', '==', this.id).get();
            if (q.size) {
                this.work = q.docs.map(d => new index_1.MetalsWorkOrder(d));
            }
        }
    }
    calc(u) {
        var _a, _b, _c;
        this.metadata.little = false;
        if ((_a = this.shipments) === null || _a === void 0 ? void 0 : _a.length) {
            this.metadata.ltl = false;
            this.shipments.forEach((shipment) => {
                if (shipment.ltl) {
                    this.metadata.ltl = true;
                }
            });
        }
        if ((_b = this.items) === null || _b === void 0 ? void 0 : _b.length) {
            (_c = this.items) === null || _c === void 0 ? void 0 : _c.forEach((item) => {
                var _a, _b;
                if (((_a = this.pricing) === null || _a === void 0 ? void 0 : _a.id) && !((_b = item.pricing) === null || _b === void 0 ? void 0 : _b.id)) {
                    item.pricing = this.pricing;
                }
                if (item.calc) {
                    item.calc(u);
                    if (item.isLTL()) {
                        this.metadata.ltl = true;
                    }
                    else if (item.isLittle()) {
                        this.metadata.little = true;
                    }
                }
                else {
                    console.warn(`Item is not typed.`, item);
                }
            });
        }
        super.calc(u);
        if (this.total_weight > 150) {
            this.metadata.ltl = true;
        }
    }
    setItemsByQty(u) {
        this.items_by_qty = [];
        if (this.items && this.items.length) {
            let total = 1;
            this.items.forEach((item) => {
                let n = 1;
                while (n <= item.quantity) {
                    let piece = new OrderItem_1.MetalsOrderItem(JSON.parse(JSON.stringify(item)));
                    piece.quantity = 1;
                    piece.index = total;
                    piece.id = `${item.id}-${n}`;
                    piece.calc(u);
                    this.items_by_qty.push(piece);
                    total++;
                    n++;
                }
            });
        }
    }
    async copy(parent, keep, remove) {
        return new MetalsOrder(await super.clean(this, parent, keep || ['items'], remove || ['payments', 'shipments']));
    }
    toJSON() {
        let r = super.toJSON();
        delete r.items_by_qty;
        return r;
    }
    async toSearchJSON(opts) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        let r = await super.toSearchJSON(opts);
        // Append service center to data in algolia.
        try {
            if (this.shipments[0].from_address.id) {
                let doc = await this._docRef.parent.parent.collection('addresses').doc(this.shipments[0].from_address.id).get();
                if (doc.exists) {
                    r.service_center = doc.data().nickname;
                }
            }
            if (!r.service_center) {
                r.service_center = this.shipments[0].from_address.nickname;
            }
        }
        catch (e) {
        }
        return r;
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new MetalsOrder(await super.clean(new MetalsOrder(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.MetalsOrder = MetalsOrder;
class MetalsCart extends MetalsOrder {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, MetalsCart);
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new MetalsOrder(await super.clean(new MetalsOrder(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.MetalsCart = MetalsCart;
class MetalsQuote extends MetalsOrder {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'quotes', 23);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, MetalsQuote);
    }
    async copy(parent) {
        return new MetalsQuote(await super.clean(new MetalsQuote(this), parent, ['items', 'shipments'], null, true));
    }
    async copyToOrder(parent) {
        await this.loadAll();
        return new MetalsOrder(await super.clean(new MetalsOrder(this), parent, ['items', 'shipments'], null, true));
    }
    toString() {
        return `Quote: ${this.quote_id}`;
    }
}
exports.MetalsQuote = MetalsQuote;
index_1.olm.orders = (ref, map) => {
    return new MetalsOrder(ref, map);
};
index_1.olm.quotes = (ref, map) => {
    return new MetalsQuote(ref, map);
};
