export let mvrkLazyComponents: any = {
   'mvrk-footer': {
      t: 'Site Footer',
      d: 'Dark background site footer.',
      client: 'MVRK',
      loader: () => import('./mvrk-footer/mvrk-footer'),
      config: () => import('./mvrk-footer/mvrk-footer-config')
   },
   'mvrk-text-left-pic-right': {
      t: 'Content: Two-Column | Text on left | Right-bleed Pic on Right',
      d: 'Content section designed for Mav investors page. Headline and text in left column.  Image on the right (which bleeds to the right edge).',
      client: 'MVRK',
      loader: () => import('./text-left-pic-right/text-left-pic-right'),
      config: () => import('./text-left-pic-right/text-left-pic-right-config')
   },
   'mvrk-pic-left-text-right': {
      t: 'Content: Two-Column | Left-bleed Pic on left | Text on Right',
      d: 'Content section designed for Mav investors page. Headline and text in right column.  Image on the left (which bleeds to the left edge).',
      client: 'MVRK',
      loader: () => import('./pic-left-text-right/pic-left-text-right'),
      config: () => import('./pic-left-text-right/pic-left-text-right-config')
   },
   'mvrk-double-image-middle': {
      t: 'Content: Two images side by side with text underneath each.',
      d: 'Content section designed for Maverick middle section of portfolio, showcasing 2 of our previous projects.',
      client: 'MVRK',
      loader: () => import('./double-image-middle/double-image-middle'),
      config: () => import('./double-image-middle/double-image-middle-config')
   },
   'mvrk-three-pics-row': {
      t: 'Content: 3 images side by side with text under each.',
      d: 'Content section designed for Maverick team page, showing 3 team members side by side.',
      client: 'MVRK',
      loader: () => import('./3-pics-row/3-pics-row'),
      config: () => import('./3-pics-row/3-pics-row-config')
   },
   'mvrk-logo-title-text': {
      t: 'Top section for each view of Maverick',
      d: 'Designed for Maverick, top section of each view on the side, logo, title of page, text description of page.',
      client: 'MVRK',
      loader: () => import('./logo-title-text/mvrk-logo-title-text'),
      config: () => import('./logo-title-text/mvrk-logo-title-text-config')
   },
   'mvrk-nav-transparent-header': {
      t: 'Transparent Navigation Header',
      d: 'Maverick navigation header',
      client: 'MVRK',
      loader: () => import('./navbar-transparent/mvrk-navbar-transparent'),
      config: () => import('./navbar-transparent/mvrk-navbar-transparent-config')
   },
   'mvrk-home-logo-title': {
      t: 'Top section for Maverick home page.',
      d: 'top section for Maverick home page, does not have 2 different text sections, unlike the other top sections',
      client: 'MVRK',
      loader: () => import('./home-logo-title/home-logo-title'),
      config: () => import('./home-logo-title/home-logo-title-config')
   },
   'mvrk-text-button': {
      t: 'Text sections with button for link navigation beneath.',
      d: 'For use with Maverick home page, has a title, two text sections, and a button with navigation functionality.',
      client: 'MVRK',
      loader: () => import('./text-button/text-button'),
      config: () => import('./text-button/text-button-config')
   },
   'mvrk-three-logos-row': {
      t: 'Three logos in a row with text body underneath.',
      d: 'For use with Maverick home page, has 3 logos/png images with longer text underneath each.',
      client: 'MVRK',
      loader: () => import('./3-logos-row/3-logos-row'),
      config: () => import('./3-logos-row/3-logos-row-config')
   }
};
