import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {NgForOf, NgIf} from '@angular/common';

@Component({
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        NgForOf
    ],
    selector: 'input-select',
    template: `
        <div *ngIf="form && controlName" [formGroup]="form"
             class="relative mt-4 p-0 max-w-400 {{class}}">
            <label *ngIf="label"
                   class="absolute -top-1.5 left-2.5 -mt-px inline-block px-1 bg-white rounded-md text-xs font-medium text-gray-900">
                {{label}}
            </label>
            <select [formControlName]="controlName"
                    (change)="onChange.emit(form.get(controlName).value)"
                    [value]="form.get(controlName).value"
                    [class]="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required') ? 'input-error' : 'input-default'"
                    class="mt-1 block w-full pl-3 py-1.5 text-base border-gray-300 focus:outline-none focus:ring-dark-500 focus:border-dark-500 sm:text-sm rounded-md"
            >
                <option *ngFor="let option of options"
                        [selected]="(form.get(controlName)?.value === option.value || form.get(controlName)?.value === option.id) ? true : false"
                        [value]="option.value||option.id||''">
                    {{option.label || option.id}}
                </option>
            </select>
            <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required')"
               class="px-3 pt-1 italic text-xs text-red-500">
                {{ label }} is required.
            </p>
        </div>
    `
})
export class InputSelectComponent implements OnInit {
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() label: string;
    @Input() class: string = '';
    @Input() options: any[];
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() default:string;

    ngOnInit() {
        ['form', 'controlName'].forEach(prop => {
            if (!this[prop]) {
                console.warn(`Cannot render text input. Missing value for ${prop}`);
            }
        });

        if (!this.form?.get(this.controlName)?.value && this.default) {
            this.form.get(this.controlName).setValue(this.default);
        }
    }
}
