import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, RouterLink} from '@angular/router';

import {combineLatest} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {ClientService} from '../../shared/_services/client.service';
import {UserService} from '../_services/user.service';
import {NxtTosAgreementDialog} from './nxt-tos-agreement.dialog';
import {PageService} from '../../shared/_services/page.service';
import {User} from '@nxt/model-core';

@Component({
    selector: 'nxt-tos-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        NxtTosAgreementDialog
    ],
    template: `
        <div class="z-[100] absolute top-0 w-full bg-black text-white p-4 text-center" *ngIf="showTerms">
            Please review and agree to Terms of Service. <a class="cursor-pointer underline" (click)="launchTOS()">Click here</a>.
        </div>
    `
})
export class NxtTosHeader {
    show: boolean;
    user: User;
    terms: any;
    showTerms: boolean;

    constructor(
        public cSvc: ClientService,
        public uSvc: UserService,
        private pSvc: PageService
    ) {

        combineLatest([this.uSvc.user$, this.cSvc.terms$, this.cSvc.clients$])
            .pipe(map(res => {
                return {user: res[0], terms: res[1], clients: res[2]};
            }))
            .subscribe(
                async (result) => {
                    this.user = result.user;
                    this.terms = result.terms;
                    if (result?.clients?.length) {
                        if (
                            !window.location.pathname.match(/\/activate$/)
                            && this.terms?.version
                            && this.user?.id
                            && !this.user.terms.find(t => t.version === this.terms.version)
                        ) {
                            this.showTerms = true;
                        } else {
                            this.showTerms = false;
                        }
                    }
                });

    }

    launchTOS() {
        this.pSvc.modal$.next({
            label: 'Terms of Service',
            component: NxtTosAgreementDialog,
            onLoaded: (comp: NxtTosAgreementDialog) => {
                comp.onClose.pipe(take(1))
                    .subscribe(agreed => {
                        if (agreed) {
                            this.showTerms = false;
                        }
                    })
            }
        });
    }
}
