import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterModule} from '@angular/router';

import {IMenuItem} from '@nxt/model-core';
import {PopButtonComponent} from '../../shared/buttons/pop-button.component';
import {NotificationSidebarComponent} from './notification-sidebar.component';
import {ClientSidebarComponent} from './client-sidebar.component';
import {take, takeUntil} from 'rxjs/operators';
import {UserService} from '../_services/user.service';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {MessagingService} from '../_services/messaging.service';
import {ThemeService} from '../../shared/_services/theme.service';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    selector: 'nxto-header-component',
    imports: [
        CommonModule, RouterModule, PopButtonComponent,
        NotificationSidebarComponent, ClientSidebarComponent, IconsComponent
    ],
    template: `
        <nav class="bg-dark fixed top-0 z-10 w-full">
            <div class="w-full px-2">
                <div class="relative flex items-center justify-between h-16">
                    <div class="flex-1 flex">
                        <button *ngIf="(pSvc.menu$ | async)"
                                (click)="menuToggleClicked.emit(true)"
                                class="cursor-pointer btn-clear border-none hover:bg-none bg-opacity-5 rounded-r-0 rounded-l-full mr-0 ml-0 text-light">
                            <span class="sr-only">Open main menu</span>
                            <icon name="heroicon-outline-menu" class="h-6 w-6"></icon>
                        </button>
                        <button (click)="showClientsMenu(true)"
                                class="cursor-pointer btn-clear border-none hover:bg-none bg-opacity-5 rounded-l-0 rounded-r-full mr-0 ml-0">
                            <span class="sr-only">Pick Client</span>
                            <span *ngIf="!tSvc.currentTheme?.logo_light"
                                  class="text-light">{{ (cSvc.client$|async)?.name || (cSvc.client$|async)?.name_key }}</span>
                            <img class="h-6 w-auto" *ngIf="tSvc.currentTheme?.logo_light"
                                 [src]="tSvc.currentTheme?.logo_light">
                        </button>
                    </div>
                    <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <a routerLink="/login" *ngIf="(pSvc.path$|async) !== '/login' && (cSvc.u$|async)===null"
                           class="bg-dark-400 p-2 pl-5 pr-5 text-light hover:text-light-700">
                            <span>Log In</span>
                        </a>

                        <ng-container *ngIf="uSvc.user$|async">
                            <button class="btn-clear btn-xs" (click)="launchAllSearch()">
                                <icon name="heroicon-outline-search" class="h-6 w-6 text-light"></icon>
                            </button>
                            <button (click)="setNotificationsMenu(true)"
                                    [class]="(mSvc.counts$|async)?.unseen ? 'flex bg-red-800 hover-bg-red-500 p-1 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white' : 'flex bg-dark-800 p-1 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'">
                                <span class="sr-only">
                                    View notifications
                                </span>
                                <icon name="custom-notifications-off" class="h-6 w-6"
                                                               *ngIf="muted"></icon>
                                <icon name="custom-notifications-active" class="h-6 w-6"
                                                                  *ngIf="!muted"></icon>
                                <span class="ml-2 mr-2" *ngIf="(mSvc.counts$|async)?.total">
                                    {{ (mSvc.counts$|async)?.total }}
                                </span>
                            </button>

                            <!-- Profile dropdown -->
                            <div class="ml-3 relative">
                                <pop-button btnClass=""
                                            [items]="userMenu"
                                            avatarSize="8"
                                            [avatarUser]="uSvc.user$|async"
                                ></pop-button>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="!(uSvc.user$|async) && (cSvc.u$|async)?.uid">
                            <!-- Profile dropdown -->
                            <div class="ml-3 relative">
                                <pop-button btnClass=""
                                            [items]="nonUserMenu"
                                            avatarSize="8"
                                            [avatarUser]="cSvc.u$|async"
                                ></pop-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </nav>

        <client-sidebar [show]="showClients" (onToggle)="showClients=$event;">
        </client-sidebar>

        <notification-sidebar [show]="showNotifications"
                              (onToggle)="setNotificationsMenu($event);">
        </notification-sidebar>
    `
})
export class NxtoHeaderComponent extends OnDestroyPage {
    @Input() sideNavHidden: boolean;
    @Output() menuToggleClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public uSvc: UserService,
        public cSvc: ClientService,
        public pSvc: PageService,
        public mSvc: MessagingService,
        public tSvc: ThemeService,
        private router: Router,
        @Inject('AllSearchDialog') private AllSearchDialog: any
    ) {

        super();
        this.pSvc.clickEsc$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (e) {
                        this.setNotificationsMenu(false);
                        this.showClientsMenu(false);
                    }
                }
            );

    }

    get muted(): boolean {
        if (this.uSvc.user$.getValue()?.metadata?.notifications?.mute) {
            return true;
        } else {
            return false;
        }
    }

    showClients: boolean;
    showNotifications: boolean;

    // bookmarks: IMenuItem[];

    get userMenu(): IMenuItem[] {
        return [
            {
                label: 'Your Profile',
                click:() => {
                    this.mSvc.flushThread('Profile Nav');
                    this.router.navigate([`/${this.cSvc.name_key}/company/users/details/${this.uSvc.user$.getValue().id}`]);
                }
            },
            {
                label: 'Notification Settings',
                click:() => {
                    this.mSvc.flushThread('Profile Nav');
                    this.router.navigate([`/${this.cSvc.name_key}/company/users/details/${this.uSvc.user$.getValue().id}/notifications`]);
                }
            },
            {
                label: 'Sign Out',
                url: `/login/logout`
            }
        ]
    }

    get nonUserMenu(): IMenuItem[] {
        return [
            {
                label: 'Sign Out',
                routerLink: `/login/logout`
            }
        ]
    }

    setNotificationsMenu(show: boolean) {
        this.showNotifications = show;
    }

    showClientsMenu(show: boolean) {
        if (this.cSvc.clients$.getValue()?.length) {
            this.showClients = show;
        }
    }

    launchAllSearch() {
        this.pSvc.modal$.next({
            label: 'Search',
            component: this.AllSearchDialog,
            onLoaded: (comp: any) => {
                comp.onClick
                    .pipe(take(1))
                    .subscribe(async i => {
                        if (i) {
                            this.mSvc.navByRef(null, i._type, i.id);
                        }
                        comp.onClose.emit();
                    })
            }
        })
    }

}
