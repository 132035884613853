import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { User, Contact } from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {AvatarComponent} from './avatar.component';
import {PageService} from '../_services/page.service';

@Component({
    standalone: true,
    imports: [
        CommonModule, AvatarComponent
    ],
    selector: 'avatar-stack',
    template: `
        <div [class]="vertical ? 'flex flex-col -space-y-1.5 relative overflow-hidden cursor-pointer': 'flex -space-x-1 relative overflow-hidden cursor-pointer'" *ngIf="items">
            <ng-container *ngFor="let item of items; let i = index;">
                <ng-container *ngIf="!limit || i < (limit-1)">
                    <avatar [user]="item" (click)="onClick.emit(item)" [debug]="debug"></avatar>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="lastUser">
                <avatar [user]="lastUser" (click)="onClick.emit()"></avatar>
            </ng-container>
        </div>
    `
})
export class AvatarStackComponent implements OnChanges {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() items: User[] | Contact[] | any[] = [];
    @Input() limit: number;
    @Input() size: number = 6;
    @Input() vertical: boolean;
    @Input() btnClass: string;
    @Input() editable: boolean = true;
    @Input() debug: boolean;
    lastUser: any;

    constructor(private pSvc: PageService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.debug) {
            console.log(this.items);
        }
        if (
            !(this.pSvc.dimensions$.getValue()?.mobile)
            && this.limit
            && this.items?.length >= this.limit
        ) {
            this.lastUser = {
                first: `+`,
                last: (this.items.length - this.limit + 1).toString()
            };
        } else if (
            !(this.pSvc.dimensions$.getValue()?.mobile)
            && this.editable
            && this.items?.length
            && (!this.limit || this.items?.length <= this.limit)
        ) {
            this.lastUser = {
                first: `+`
            };
        } else {
            this.lastUser = null;
        }
    }
}
