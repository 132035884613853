import {Optional, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import algoliasearch from 'algoliasearch/lite';

import {
    AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, Filter, Client,
    IAlgoliaQueryFacetFilterItem, IAlgoliaSearchRequest, IAlgoliaSearchResults, IMenuItem, User
} from '@nxt/model-core';
import {SpinnerComponent} from '../spinner/spinner.component';
import {FiltersDialog} from '../../nxt/search/filters.dialog';
import {TabBarComponent} from '../../nxt/tabs/tab-bar.component';
import {LocalStorageService} from '../_services/local-storage.service';
import {PageService} from '../_services/page.service';
import {ClientService} from '../_services/client.service';
import {FireService, IFirestoreQuery} from '../../nxt/_services/fire.service';
import {Router} from '@angular/router';
import {AccountService} from '../../nxt/_services/account.service';
// @ts-ignore
import {environment} from '@env/environment';
import {OnDestroyPage} from '../_inherited/ondestroy.page';
import {IconsComponent} from '../icons/icons.component';
import {PopButtonComponent} from '../buttons/pop-button.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule,
        SpinnerComponent, TabBarComponent,
        FiltersDialog, IconsComponent, PopButtonComponent
    ],
    selector: 'algolia-search-component',
    template: `
        <div class="flex items-stretch place-items-center w-full p-1" *ngIf="builder">
            <button *ngIf="filterSet && !aiEnabled"
                    (click)="showFilters()"
                    class="btn-clear btn-sm hover:bg-accent-100 transition">
                <icon name="heroicon-outline-filter" class="h-5 w-5 mr-1"></icon>
                <span class="hidden md:block">Filters</span>
            </button>

            <button *ngIf="AI" (click)="toggleAI()"
                    class="btn-clear btn-sm hover:bg-accent-100 transition">
                <img src="/assets/apis/ai-broker-parser.png" alt="Toggle AI"
                     class="h-7 w-7 object-contain transition"
                     [ngClass]="{'animate-pulse': !aiEnabled}">
            </button>

            <div class="grow w-full">
                <div class="flex rounded-full shadow-sm">
                    <div class="relative flex items-stretch flex-grow">
                        <ng-container *ngIf="!aiEnabled">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <icon *ngIf="!(searching$|async)" name="heroicon-outline-search"
                                      class="h-5 w-5 text-gray-400"></icon>
                                <spinner *ngIf="searching$|async" class="h-4 w-4 text-accent"></spinner>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="aiEnabled">
                            <div class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                                <div role="status"
                                     class="relative flex items-center justify-center w-10 h-10 ml-[-4px]">
                                    <spinner *ngIf="searching$|async" class="h-5 w-5 animate-colorCycle"></spinner>
                                    <icon *ngIf="!(searching$|async)" [name]="searchIcon"
                                          [class]="'h-8 w-8 animate-colorCycle ' + AIClass"></icon>
                                </div>
                            </div>
                        </ng-container>
                        <input type="text" [id]="subtype" [disabled]="disabled"
                               (keyup)="aiEnabled ? null : handleKeyup($event)"
                               (keyup.enter)="aiEnabled ? handleAIEnter($event) : null"
                               [(ngModel)]="builder.query"
                               class="focus:ring-dark-500 focus:border-dark-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300 placeholder-animate"
                               [placeholder]="aiEnabled ? AIPlaceholder : placeholder">
                        <div *ngIf="builder.query" (click)="clearTerm()"
                             class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center">
                            <icon name="heroicon-outline-x" class="h-5 w-5 text-gray-400"></icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex place-content-center" *ngIf="savedSearchOptions?.length">
                <pop-button
                    btnClass="btn-clear whitespace-nowrap overflow-hidden"
                    iconClass="h-3 w-3 ml-2"
                    iconName="heroicon-outline-chevron-down"
                    [items]="savedSearchOptions"
                    [label]="selectedSearch?.name || 'Saved'"
                ></pop-button>
            </div>
        </div>
        <div *ngIf="searchableAttributes" class="flex flex-wrap gap-4 mt-2">
            <label
                class="flex items-center gap-2 bg-accent-50 px-3 py-1 rounded-lg shadow-sm transition-all hover:bg-accent-100"
                *ngFor="let label of getSearchableAttributeLabels()">
                <input
                    type="checkbox"
                    [checked]="attributeFilters[label]"
                    (change)="toggleAttributeFilter(label)"
                    class="form-checkbox h-5 w-5 text-accent-600 focus:ring-accent-500 border-gray-300 rounded"
                />
                <span class="text-accent-700 font-medium">{{ label }}</span>
            </label>
        </div>

        <div class="flex justify-between p-1 w-full" *ngIf="showChicklets">
            <div class="flex">
                <div *ngFor="let filter of builder?.namedFilterItems; let i = index;"
                     [style.backgroundColor]="filter.color"
                     class="chicklet flex m-1 place-content-center">
                    <span [style.color]="filter.contrast">{{ filter.name }}</span>
                    <span *ngIf="i < (builder.namedFilterItems.length-1) && (filter.type==='or'||filter.type==='and')"
                          (click)="toggleAnd();" class="ml-2"
                          [style.color]="filter.contrast">{{ builder.and ? 'and' : 'or' }}</span>
                    <icon (click)="removeFilter(filter);" name="heroicon-outline-x"
                          class="ml-1 h-4 w-4 text-white"></icon>
                </div>
                <div (click)="clearTerm()" *ngIf="showResultsChicklet && activeQuery && !aiEnabled"
                     class="chicklet flex m-1 place-content-center space-y-2 bg-gray-200">
                    <div>Results: {{ nbHits }}</div>
                    <icon name="heroicon-outline-x" class="ml-1 h-4 w-4 -mt-1"></icon>
                </div>
            </div>


            <div>
                <button class="btn-gray btn-sm" (click)="saveQuery()" *ngIf="nbHits && savedSearchUser && saveable">
                    Save
                    <icon name="heroicon-outline-database" class="ml-1 h-4 w-4"></icon>
                </button>
                <button class="btn-clear btn-sm ml-3 text-red-600" (click)="deleteQuery()" *ngIf="selectedSearch">
                    Delete
                    <icon name="heroicon-outline-trash" class="ml-1 h-5 w-5"></icon>
                </button>
            </div>
        </div>
    `,
    styles: [`
        .placeholder-animate::placeholder {
            color: #6B7280;
        }
    `]
})
export class AlgoliaSearchComponent extends OnDestroyPage implements OnInit, OnChanges {
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean]>();
    @Output() onSearching: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onAIResult: EventEmitter<any> = new EventEmitter<any>();
    @Input() label: string;
    @Input() placeholder: string;
    // @Input() types: string[];
    @Input() index: string;
    @Input() subtype: string;
    @Input() minLength: number;
    @Input() saveSearch: boolean = true;
    @Input() savedSearchUser: User = null;
    @Input() hitsPerPage: number = 100;
    @Input() preSearchLogic: Function;
    @Input() filterSet: IAlgoliaFilterSet[];
    @Input() builder: AlgoliaQueryBuilder;
    @Input() startAfter: number;
    @Input() autoStart: boolean | 'ifQueryPresent';
    @Input() showChicklets: boolean = true;
    @Input() showResultsChicklet: boolean = true;
    @Input() emptyOnClear: boolean = false;
    @Input() disabled: boolean = false;
    @Input() _env: string = environment.type;
    @Input() searchableAttributes: { [label: string]: string };
    @Input() AI: boolean = false;
    @Input() searchIcon: string;
    @Input() AIClass: string = '';
    @Input() placeHolderOptions: string[];
    @Input() apiRoute: string;
    aiEnabled: boolean = false;
    AIPlaceholder: string = 'Ask me a question...';
    private placeholderIndex: number = 0;
    savedSearchOptions: IMenuItem[];
    selectedSearch: AlgoliaQueryBuilder;

    searching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    nbHits: number;
    attributeFilters: Record<string, boolean> = {};
    localStorageKey: string;

    get activeQuery() {
        return (this.builder.query || this.builder.namedFilterItems?.length);
    }

    get saveable(): boolean {
        return !!(
            this.index && this.subtype && this.activeQuery
            && (!this.builder.id || this.builder.query !== this.selectedSearch?.query)
        );
    }

    get savedSearchId(): string {
        return `${this.cSvc.name_key}-${this.index}-${this.subtype || ''}-request`;
    }

    constructor(
        public pSvc: PageService,
        private cSvc: ClientService,
        private lSvc: LocalStorageService,
        @Optional() private fSvc: FireService
    ) {
        super();
    }

    ngOnInit() {
        if (this.index) {
            // Load user's stored search state for this search
            this.builder = new AlgoliaQueryBuilder((this.saveSearch !== false) ? this.lSvc.localState[this.savedSearchId] : null);
            this.builder.build();
            this.builder.type = '';
            // These are CRITICAL for saving searches. If you change these
            // properties in a component that uses this search component, the user
            // will no longer see their saved searches - which were saved under the previous index/subtype names.
            this.builder.index = this.index || '';
            this.builder.subtype = this.subtype || '';
            if (this.searchableAttributes) {
                this.localStorageKey = `${this.cSvc.name_key}-${this.index}-attributeFilters`;
                this.attributeFilters = this.lSvc.localState[this.localStorageKey] || this.initializeAttributeFilters();
            }
        }
        this.searchOrClear();
        if (this.AI) {
            this.aiEnabled = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.savedSearchUser) {
            this.loadSavedSearches();
        }
    }

    searchOrClear() {
        let go: boolean = false;
        if (this.index && this.builder) {
            if (this.autoStart === 'ifQueryPresent' && this.builder?.query) {
                go = true;
            }
            if (this.autoStart !== 'ifQueryPresent' && (this.builder?.query || this.builder?.filterItems?.length)) {
                go = true;
            } else if (this.autoStart && !this.emptyOnClear) {
                go = true;
            }
        }

        if (go) {
            this.search();
        } else {
            this.onClear.emit(true);
            this.nbHits = null;
            this.saveState();
        }
    }

    clearTerm(includeFilters?: boolean) {
        this.selectedSearch = null;
        delete this.builder.id;
        this.builder.query = '';
        if (!this.aiEnabled && !this.AI) {
            this.builder.page = 1;
            this.showMore$.next(false);
            this.nbHits = null;
            if (includeFilters || this.preSearchLogic) {
                this.builder.filterItems = [];
            }
            this.searchOrClear();
        } else {
            this.onClear.emit([true, true]);
            this.search();
        }
    }

    nextPage() {
        if (this.showMore$.getValue()) {
            this.builder.page++;
            this.search(true);
        }
    }

    extractHighlights(parent, n) {
        n = n || 0;
        Object.keys(parent)?.forEach(p => {
            if (parent[p].matchLevel === 'none') {
                delete parent[p];
            } else if (typeof parent[p] === 'object' && !parent[p].matchLevel) {
                parent[p] = this.extractHighlights(parent[p], n);
            } else {
                switch (parent[p].matchLevel) {
                    case 'full':
                        n += 2;
                        break;
                    case 'partial':
                        n += 1;
                        break;
                }
            }
        });
        return n;
    }

    async search(append?: boolean) {
        this.searching$.next(true);
        try {
            if (this.preSearchLogic) {
                this.builder = this.preSearchLogic(this.builder);
            }
            this.builder.hitsPerPage = this.hitsPerPage;
            if (!append) {
                this.builder.page = 0;
            }

            let result: any;

            if (this.searchableAttributes) {
                this.builder.restrictSearchableAttributes = Object.keys(this.attributeFilters)
                    .filter((key) => this.attributeFilters[key])
                    .map((key) => this.searchableAttributes[key]);
            }

            const aClient = algoliasearch(environment.algolia.appId, environment.algolia.searchKey);
            const index = aClient.initIndex(this.index);

            let req: IAlgoliaSearchRequest = Object.assign({}, this.builder.toSearchRequest());
            if (this.index !== 'airports') {
                if (this._env) {
                    req.params.facetFilters.push([`_env:${this._env}`]);
                }
                if (this.index !== 'clients') {
                    req.params.facetFilters.push([`_client:${this.cSvc.client_id}`]);
                }
            }
            this.onSearching.emit(true);

            result = await index.search(req.query, req.params);
            this.nbHits = result?.nbHits || 0;
            if (result) {
                this.showMore$.next((result.page < result.nbPages - 1));
                this.onResults.emit([result, append]);
            }
            this.saveState();
            this.onSearching.emit(false);
            this.searching$.next(false);

        } catch (e) {
            console.warn(e, e?.message);
            e.title = e.title || e.name;
            this.pSvc.alert$.next(e);
            this.searching$.next(false);
        }
    }

    handleKeyup(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            if (
                (this.minLength && this.builder.query?.length >= this.minLength)
                || (!this.minLength && this.builder.query?.length)
            ) {
                this.builder.page = 0;
                this.search();
            } else {
                this.clearTerm();
            }
        } else if (this.startAfter && this.builder.query.length > this.startAfter) {
            this.search();
        } else if (!this.builder.query?.length) {
            this.clearTerm();
        }
    }

    async handleAIEnter(e: KeyboardEvent) {
        if (e.key === 'Enter' && this.builder.query?.length) {
            this.searching$.next(true);
            try {
                const response = await this.cSvc.callAPI(this.apiRoute, 'post', {query: this.builder.query});
                console.log('AI Response:', response);
                if (response.querySummary) {
                    this.pSvc.notification$.next({
                        title: "Levo AI Search Results",
                        message: response.querySummary,
                    });
                }
                this.onAIResult.emit(response);
            } catch (error) {
                console.error("AI API call failed:", error);
                this.pSvc.notification$.next({
                    title: "AI Search Failed",
                    message: "Unable to fetch AI search results. Please try again."
                });
            } finally {
                this.searching$.next(false);
            }
        } else {
            this.clearTerm();
        }
    }

    removeFilter(filter: AlgoliaQueryFacetFilterItem) {
        this.selectedSearch = null;
        delete this.builder.id;
        this.builder.removeFilter(filter);
        this.searchOrClear();
    }

    toggleAnd() {
        this.selectedSearch = null;
        delete this.builder.id;
        this.builder.and = !this.builder.and;
        this.searchOrClear();
    }

    saveState() {
        if (this.saveSearch && this.builder?.toFullJSON) {
            this.lSvc.saveState(this.savedSearchId, this.builder.toFullJSON());
        }
    }

    toggleFilter(filter) {
        if (this.builder.filterItems.find(i => i.id === filter.id)) {
            this.builder.removeFilter(filter);
        } else {
            this.builder.addFilter(filter);
        }
        this.searchOrClear();
    }

    showFilters() {

        this.pSvc.modal$.next({
            component: FiltersDialog,
            label: 'Filters',
            onLoaded: (comp: FiltersDialog) => {
                comp.filterSet = this.filterSet;
                comp.builder = this.builder;
                if (this.filterSet?.length) {
                    comp.buildButtons(this.filterSet[0]);
                }
                let sub = comp.onToggle.subscribe(
                    filter => {
                        if (filter) {
                            this.toggleFilter(filter);
                            comp.builder = this.builder;
                        }
                    }
                );
                comp.onClose.pipe(take(1)).subscribe(
                    c => {
                        sub?.unsubscribe();
                        this.searchOrClear();
                    }
                );
            }
        });
    }

    getSearchableAttributeLabels(): string[] {
        return Object.keys(this.searchableAttributes);
    }

    initializeAttributeFilters(): Record<string, boolean> {
        return Object.keys(this.searchableAttributes).reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});
    }

    toggleAttributeFilter(label: string): void {
        this.attributeFilters[label] = !this.attributeFilters[label];
        this.lSvc.saveState(this.localStorageKey, this.attributeFilters);
    }

    async loadSavedSearches() {
        if (this.savedSearchUser?._exists) {
            if (!this.index || !this.subtype) {
                console.warn(`Saved searches will not work without both the 'index' and 'subtype' properties set!`);
            } else {
                let res = await firstValueFrom(
                    this.fSvc.getColl(
                        `users/${this.savedSearchUser?.id}/clients/${this.cSvc.client_id}/searches`,
                        [
                            {name: 'where', args: ['index', '==', this.index]},
                            {name: 'where', args: ['subtype', '==', this.subtype]},
                            {name: 'orderBy', args: ['name', 'asc']}
                        ]
                    )
                );
                this.savedSearchOptions = res?.map(doc => {
                    let b: AlgoliaQueryBuilder = new AlgoliaQueryBuilder(doc);
                    if (this.builder?.id === b.id) {
                        this.selectedSearch = b;
                    }
                    return {
                        label: b.name,
                        click: () => {
                            this.selectedSearch = b;
                            console.log('wtaf', b.query);
                            this.builder = new AlgoliaQueryBuilder(b.toFullJSON());
                            console.log('wtf', this.builder.query);
                            this.search();
                        }
                    };
                });
            }
        }
    }

    saveQuery() {
        this.pSvc.notification$.next({
            title: 'Only Term & Filters Are Saved!',
            message: `Please note: only the search term and non-date-related filters are saved. The date component is not saved - otherwise the query will get 'stale' over time.`,
            buttons: [
                {
                    label: 'Name Your Search',
                    click: async () => {
                        let name: string = prompt(`Enter a unique name for your search`);
                        if (name) {
                            this.pSvc.blocking$.next(true);
                            try {
                                let builder: AlgoliaQueryBuilder = new AlgoliaQueryBuilder(this.builder);
                                builder.setID();
                                builder._docRef = this.fSvc.fs.doc(`users/${this.savedSearchUser?.id}/clients/${this.cSvc.client_id}/searches/${builder.id}`);
                                builder.name = name;
                                await builder.save();
                                console.log('builder', builder._docRef.path);
                                await this.loadSavedSearches();
                            } catch (e) {
                                this.pSvc.alert$.next(e);
                            }
                            this.pSvc.blocking$.next(false);
                        }
                    }
                }
            ]
        });
    }

    async deleteQuery(confirm?: boolean) {
        if (this.selectedSearch?._exists) {
            if (!confirm) {
                this.pSvc.notification$.next({
                    title: 'Are You Sure?',
                    message: `Click confirm to delete your saved query labeled "${this.selectedSearch.name}."`,
                    buttons: [
                        {
                            label: 'Confirm',
                            click: () => {
                                this.deleteQuery(true);
                            }
                        }
                    ]
                });
            } else {
                // Disconnect what's in the search box from what we're deleting.
                delete this.builder.id;
                await this.selectedSearch.delete();
                delete this.selectedSearch;
                await this.loadSavedSearches();
            }
        }
    }

    startPlaceholderAnimation() {
        setInterval(() => {
            this.placeholderIndex = (this.placeholderIndex + 1) % this.placeHolderOptions.length;
            this.AIPlaceholder = this.placeHolderOptions[this.placeholderIndex];
        }, 4000);
    }

    toggleAI() {
        this.aiEnabled = !this.aiEnabled;

        if (this.aiEnabled) {
            this.startPlaceholderAnimation();
        } else {
            this.clearTerm();
        }
    }
}

export interface IAlgoliaFilterSet {
    name: string;
    items: IAlgoliaQueryFacetFilterItem[],
    buttons?: IMenuItem[]
}

export let filterSet: number;

export async function getManualFilterSet(
    client: Client,
    fSvc: FireService,
    pSvc: PageService,
    lSvc: LocalStorageService,
    router: Router,
    type?: string
): Promise<IAlgoliaFilterSet> {

    let result: IAlgoliaFilterSet;
    try {

        // Check local storage first.  Then load the latest list and update local storage if it's changed.
        let stateId: string = `filters-${type || 'default'}`;
        let res: any[] = lSvc.localState[stateId];
        if (!res?.length || !filterSet) {
            let query: IFirestoreQuery[] = [{name: 'where', args: ['active', '==', true]}];
            if (type) {
                query.push({name: 'where', args: ['type', '==', type]});
            }
            res = await firstValueFrom(fSvc.getColl(`clients/${client.id}/filters`, query));
            lSvc.saveState(stateId, res?.map(i => {
                let filter: Filter = new Filter(i);
                return filter.toJSON();
            }));
            filterSet = Date.now();
        }

        result = {
            name: 'Manual Filters',
            buttons: [
                {
                    label: 'Manage Manual Filters',
                    class: 'btn-dark',
                    click: () => {
                        pSvc.clickEsc$.next(true);
                        switch (type) {
                            case 'quotes':
                                router.navigate([`/${client.name_key}/quotes/filters`]);
                                break;
                            default:
                                router.navigate([`/${client.name_key}/threads/filters`]);
                                break;
                        }

                    }
                }
            ],
            items: res?.map(item => {
                let filter: Filter = new Filter(item);
                let fItem = new AlgoliaQueryFacetFilterItem();
                fItem.id = filter.id;
                fItem.name = filter.name;
                fItem.color = filter.color || '#333';
                fItem.contrast = filter.contrast || 'yellow';
                fItem.key = 'filters.id';
                fItem.type = 'or';
                fItem.value = `filters.id:${item.id}`;
                return fItem;
            })
        };
    } catch (e) {
        console.warn(e, 'filters');
    }


    return result;
}

export async function getUsersByRoleFilterSets(
    aSvc: AccountService,
    cSvc: ClientService
): Promise<IAlgoliaFilterSet[]> {
    let results: IAlgoliaFilterSet[] = [];

    let roles: any[] = cSvc.client$.getValue()?.config?.roles || [];
    let uBR: any = await aSvc.getUsersByRole();
    if (uBR) {
        Object.keys(uBR).forEach(role => {
            if (role !== 'date' && roles?.find(r => r.id === role) && roles?.find(r => r.id === role).show_in_filters) {
                let set: IAlgoliaFilterSet = {
                    name: `Agent: ${role.toUpperCase()}`,
                    items: uBR[role].map((u: User) => {
                        let i: AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem();
                        i.id = u.id;
                        i.name = u.name;
                        i.value = `agent_id:${u.id}`;
                        i.color = '#dfdfdf';
                        i.contrast = '#000000';
                        i.type = 'or';
                        return i;
                    })
                };
                results.push(set);
            }
        });
    }
    return results;
}
