import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder} from '@angular/forms';

import {Thread, User, IMenuItem, ThreadMessage, EMedium} from '@nxt/model-core';
import {TabBarComponent} from '@library/nxt/tabs/tab-bar.component';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {UserService} from '@library/nxt/_services/user.service';
import {ClientService} from '@library/shared/_services/client.service';
import {PageService} from '@library/shared/_services/page.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';



import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {FollowersComponent} from '../../threads/_components/followers.component';
import {FollowersByRoleComponent} from '../../threads/_components/followers-by-role.component';
import {InputToggleNoformComponent} from '@library/shared/input/input-toggle-noform.component';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {InputHtmlComponent} from '@library/shared/input/input-html.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        TabBarComponent, PipesModule,
          
        FooterButtonsComponent, FollowersComponent, FollowersByRoleComponent,
        InputToggleNoformComponent, InputStackedTextComponent, InputHtmlComponent
    ],
    template: `
        <div class="m-1 w-full">
            <div class="p-2 italic">
                Assign primary responsibility and/or followers who will receive notifications of messages during the life of the thread (or until the user unfollows)
            </div>
            <div class="border-gray-200 border rounded-md flex bg-gray-100 w-90 m-auto mb-2">
                <div class="border-gray-200 border-r p-4 font-bold bg-gray-200 w-100">
                    Agents
                </div>
                <div class="p-4 italic text-gray-500 grow">
                    <div class="mb-2  text-sm">Agents have primary responsibility for responding/managing, and will get notifications of messages in the thread.</div>
                    <followers
                            label="Assign Agents"
                            [parent]="msg||thread"
                            property="agents"
                            (onChange)="update('agents',$event)"
                    ></followers>
                </div>
            </div>
            <div class="border-gray-200 border rounded-md flex bg-gray-100 w-90 m-auto mb-2">
                <div class="border-gray-200 border-r p-4 font-bold bg-gray-200 w-100">
                    Followers
                </div>
                <div class="p-4 italic text-gray-500 grow">
                    <div class="mb-2  text-sm">Followers will get notifications of messages in the thread.</div>
                    <followers
                            label="Assign Followers"
                            [parent]="msg||thread"
                            property="followers"
                            (onChange)="update('followers',$event)"
                    ></followers>
                </div>
            </div>
            <div class="border-gray-200 border rounded-md flex bg-gray-100 w-90 m-auto mb-2">
                <div class="border-gray-200 border-r p-4 font-bold bg-gray-200 w-100">
                    Roles
                </div>
                <div class="p-4 italic text-gray-500 grow">
                    <div class="mb-2 text-sm">Assign groups of users by role to get notifications of messages in the thread.</div>
                    <followers-by-role
                            label="Followers By Role Group"
                            [parent]="msg||thread"
                            property="roles"
                            (onChange)="update('roles',$event)"
                    ></followers-by-role>
                </div>
            </div>

            <div class="border-gray-200 border rounded-md flex bg-gray-100 w-90 m-auto mb-2">
                <div class="p-4 font-bold w-100 bg-white">
                    Add Note
                </div>
                <div [formGroup]="form" class="p-1 italic text-gray-500 w-90">
                    <div class="bg-yellow-100 w-full">
                        <input-html
                                label="Optional Internal Note"
                                controlName="message"
                                [form]="form"
                        ></input-html>
                    </div>
                </div>
            </div>
            
        </div>
        
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class AssignDialog extends OnDestroyPage implements OnChanges {
    @Output() onClose: EventEmitter<[Thread,ThreadMessage]> = new EventEmitter<[Thread,ThreadMessage]>();
    @Input() label: string = '';
    @Input() thread: Thread;
    @Input() msg: ThreadMessage;
    notify: boolean = false;
    fMsg: ThreadMessage = new ThreadMessage();
    form: FormGroup;
    buttons: IMenuItem[] = [
        {
            label: 'Done',
            click: () => {
                this.pSvc.blocking$.next(true);
                try {
                    this.fMsg.html = this.form.get('message').value||'';
                    let tmp = document.createElement("DIV");
                    tmp.innerHTML = this.fMsg.html;
                    this.fMsg.summary = tmp.textContent || tmp.innerText || "";
                    this.cSvc.callAPI(`/messaging/notify`, 'post', {
                        thread: this.thread._docRef.path,
                        message: this.fMsg.toJSON()
                    });
                    this.onClose.emit([this.thread,this.msg]);
                } catch (e) {

                }
                this.pSvc.blocking$.next(false);
            }
        }
    ];
    get config(): any {
        return  {
            menubar: false,
            skin: 'oxide',
            statusbar: false,
            height: 120,
            toolbar:  false
        }
    }

    constructor(
        public uSvc: UserService,
        private cSvc: ClientService,
        private pSvc: PageService,
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnChanges(changes?: SimpleChanges) {
        this.form = this.fb.group({
            message: ['']
        });
    }

    async update(property: string, users: User[]) {
        this.fMsg.remove(property, this.thread[property]);
        this.fMsg.add(property, users);
        console.log(this.fMsg, this.fMsg.unassigned);

        this.thread[property] = users;
        await this.thread.save(this.cSvc.client$.getValue());
        if (this.msg) {
            this.msg[property] = users;
            await this.msg.save(this.thread);
        }
    }

    protected readonly EMedium = EMedium;
}
