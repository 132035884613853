import {
    Component,
    EventEmitter,
    Output,
    Input,
    ViewChild,
    ElementRef,
    OnChanges,
    SimpleChanges,
    Inject
} from '@angular/core';
import {Subscription} from 'rxjs';
import {CommonModule} from '@angular/common';

import {ScrollableDivDirective} from '@library/shared/scrollable-div/scrollable-div';
import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {MessageRefIcon} from '@library/nxt/list/message-ref-icon';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {FollowersComponent} from '@library/nxt/followers/followers.component';

import {IMenuItem, Thread, ThreadMessage, EMedium, Base} from '@nxt/model-core';
import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';

import {MessageItemComponent} from './message-item.component';
import {ScrollableThreadTriggeredList} from '@library/nxt/list/scrollable-thread-triggered-list';
import {ComposeMsgComponent} from './compose-msg.component';
import {FollowersByRoleComponent} from '@library/nxt/followers/followers-by-role.component';
import {PageService} from '@library/shared/_services/page.service';


@Component({
    standalone: true,
    imports: [
        CommonModule, ScrollableDivDirective, MessageMediumIcon,
        MessageRefIcon, PopButtonComponent, FollowersComponent,
        MessageItemComponent,
        ScrollableThreadTriggeredList, ComposeMsgComponent, FollowersByRoleComponent
    ],
    selector: 'thread-detail',
    template: `
        <div class="h-full bg-white" *ngIf="thread">
            
            <div class="flex justify-between p-2">
                <div class="flex">
                    <button *ngIf="show.move && thread?.object?._type !== 'tasks' && thread?.object?._type !== 'pricing' && thread?.object?._type !== 'deployments' && thread?.object?._type !== 'work_orders'"
                            class="btn-gray btn-xs ml-1" 
                            title="Move Thread" 
                             (click)="tOptSvc.move(mSvc,thread)"
                    >
                        Move
                    </button>
                    <button  *ngIf="show.print && thread?.object?._type !== 'tasks' && thread?.object?._type !== 'pricing' && thread?.object?._type !== 'deployments' && thread?.object?._type !== 'work_orders'"
                             class="btn-gray btn-xs ml-1" 
                             title="Move Thread" 
                             (click)="msgList.print()"
                    >
                        Print
                    </button>
                </div>
                <div class="flex" *ngIf="show.followers">
                    <followers
                            label="Agents"
                            limit="3"
                            [parent]="parent"
                            property="agents"
                            (onChange)="update('agents',$event)"
                    ></followers>
                    <followers
                            label="Followers"
                            limit="4"
                            [parent]="parent"
                            property="followers"
                            (onChange)="update('followers',$event)"
                    ></followers>
                    <followers-by-role
                            [parent]="parent"
                            label="Roles"
                            property="roles"
                            (onChange)="update('roles',$event)"
                    ></followers-by-role>
                </div>
            </div>

            <!-- MSG COMPOSER -->
            <compose-msg
                    [thread]="thread"
                    [parent]="parent"
                    [message]="mSvc.activeMsg$|async"
                    [context]="context"
                    [editorHeight]="(thread?.medium===EMedium.EMAIL||thread?.medium===EMedium.WEB) ? '140px' : '70px'"
                    (onThread)="confirmThread($event)"
                    (onLoaded)="onCompose.emit($event);"
                    (onMessage)="onMessage.emit($event)"
                    [templateDocument]="mSvc.templateDocument$|async"
                    [isTemplateModal]="isTemplateModal"
            ></compose-msg>

            <scrollable-thread-triggered-list #msgList [thread]="thread" [parent]="parent"></scrollable-thread-triggered-list>
            
        </div>
    `
})
export class ThreadDetailComponent extends OnDestroyPage implements OnChanges {
    @ViewChild('msgList', {static:true}) msgList: ScrollableThreadTriggeredList;
    @Output() onMessage: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Output() onCompose: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
    @Input() thread: Thread;
    @Input() parent: Base;
    @Input() context: EThreadContext;
    @Input() show: {move:boolean, print:boolean, followers:boolean} = { move: true, print: true, followers: true };
    @Input() isTemplateModal: boolean = true;
    EThreadContext = EThreadContext;
    EMedium = EMedium;
    message: ThreadMessage;
    loaded: boolean;
    buttons: IMenuItem[] = [];
    sub: Subscription;
    medium: any;
    pagingSize: number = 15;

    constructor(
        private fSvc: FireService,
        private pSvc: PageService,
        public mSvc: MessagingService,
        @Inject('ThreadOptionsService') public tOptSvc: any
    ) {

        super();

    }

    async ngOnChanges(changes?: SimpleChanges) {
        if (this.thread?.id && this.parent?.id) {
            if (this.thread.medium === EMedium.CHAT) {
                await this.mSvc.startMessage(this.parent||this.thread.object, this.thread, [], [], EMedium.CHAT);
            }
            let id: string = this.parent?.id||this.thread.object?.id||this.thread?.id;
            let msg = this.mSvc.getMsg(id);
            msg.medium = this.thread.medium;
            this.mSvc.activeMsg$.next(this.mSvc.getMsg(id));
        }
    }

    async confirmThread(threadPath: string) {
        if (threadPath && !this.thread?._exists) {
            this.thread = (await this.fSvc.getObject(threadPath)) as Thread;
        }
    }

    async update(property: string, users: any) {
        if (property === 'followers' && this.parent['agents']?.find(a => users.find(u => u.id === a.id))) {
            this.pSvc.notification$.next({
                title: 'Already Agent!',
                message: `You are already following this thread as an agent.`
            });
        } else {
            let update: any = {};
            update[property] = users;
            return this.parent.update(update);
        }
    }

}
