"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GmtlProduct = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class GmtlProduct extends model_core_1.Product {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'products', 31);
        this.properties = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, GmtlProduct);
    }
    toString() {
        return `Product: ${this.name}`;
    }
    toJSON() {
        let r = super.toJSON();
        delete r.oldRef;
        return r;
    }
}
exports.GmtlProduct = GmtlProduct;
index_1.olm.products = (ref, map) => {
    return new GmtlProduct(ref, map);
};
