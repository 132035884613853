import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {MessagingService} from '@library/nxt/_services/messaging.service';
import {PageService} from '@library/shared/_services/page.service';
import {ThreadDetailWrapperComponent} from './_components/thread-detail-wrapper.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, ThreadDetailWrapperComponent,
    ],
    selector: 'thread-sidebar',
    template: `
        <div *ngIf="mSvc.thread$|async" 
             [style.width]="width" 
             [style.height]="'calc(100vh - 40px)'"
             class="absolute right-1 top-6 z-30 bg-white shadow shadow-2xl"
        >
            <div class="absolute inset-0 overflow-hidden w-full h-full">
                <thread-detail-wrapper [thread]="mSvc.thread$|async"></thread-detail-wrapper>
            </div>
        </div>
    `
})
export class ThreadSidebar {
    @Input() show: boolean;
    width: string;

    constructor(
        public mSvc: MessagingService,
        private pSvc: PageService
    ) {

        this.pSvc.dimensions$
            .subscribe(d => {
                if (d.mobile) {
                    this.width = '100vw';
                } else {
                    this.width = 'calc(50vw - 20px)';
                }
            });

        this.pSvc.clickEsc$
            .subscribe(
                res => {
                    if (!this.pSvc.modal$.getValue()) {
                        this.mSvc.thread$.next(null);
                    }
                }
            );

        this.mSvc.thread$
            .subscribe(t => {
                this.show = !!t?.id && !(t?.object?._type==='clients');
            })
    }

}
