import {Component, Input} from '@angular/core';
import {ThreadMessage,Thread} from '@nxt/model-core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'filter-matches',
    standalone: true,
    imports: [
        CommonModule
    ],
    template: `
        <div class="text-gray-400" *ngIf="parent?.filters?.length">
            <span *ngFor="let match of parent.filters"
                  [style.backgroundColor]="match.color||'#efefef'"
                  class="inline-flex items-center px-1.5 py-0.5 m-1 rounded-full text-xs font-medium text-gray-800">
                <span [style.color]="match.contrast">{{match.name}}</span>
            </span>
        </div>
    `
})
export class FilterMatches {
    @Input() parent: Thread|ThreadMessage;
}
