import {Component, Input} from '@angular/core';
import {EThreadMessageEvents, Thread, ThreadMessage} from '@nxt/model-core';
import {NgIf} from '@angular/common';

@Component({
    selector: 'results',
    standalone: true,
    imports: [
        NgIf
    ],
    template: `
        <span *ngIf="parent.results && parent.results[EThreadMessageEvents.SENT]" class="ml-1 text-blue-500 text-xs" title="Sent">
            {{ (parent?.object?._type === 'groups') ? parent.results[EThreadMessageEvents.SENT] : 'S' }}
        </span>
        <span *ngIf="parent.results && parent.results[EThreadMessageEvents.DELIVERED]" class="ml-1 text-green-500 text-xs" title="Delivered">
            D
        </span>
        <span *ngIf="!parent.inbound && parent.results && parent.results[EThreadMessageEvents.FAILED]" class="ml-1 text-red-600 text-xs" title="Failed">
            F
        </span>
    `
})
export class MessageResultsComponent {
    @Input() parent: ThreadMessage;
    EThreadMessageEvents = EThreadMessageEvents;
}
