import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Thread, ThreadMessage} from '@nxt/model-core';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'agents-followers-time',
    standalone: true,
    imports: [
        DatePipe
    ],
    template: `
        <div (mouseover)="setTitles()" class="flex align-top place-content-end place-items-center">
            <div [title]="agentsTitle" class="time mr-2">Agents ({{ parent?.agents?.length || '0' }})</div>
            <div [title]="followersTitle" class="time mr-2">Followers ({{ parent?.followers?.length || '0' }})</div>
            <div [title]="rolesTitle" class="time">Roles ({{ parent?.roles?.length || '0' }})</div>
        </div>
    `
})
export class AgentsFollowersTimeComponent {
    @Input() parent: ThreadMessage|Thread;
    @Input() timestamp: number;
    agentsTitle: string = '';
    followersTitle: string = '';
    rolesTitle: string = '';

    setTitles() {
        if (this.parent?.agents?.length && !this.agentsTitle) {
            this.agentsTitle = this.getTitle('agents');
        }
        if (this.parent?.followers?.length && !this.followersTitle) {
            this.followersTitle = this.getTitle('followers');
        }
        if (this.parent?.roles?.length && !this.rolesTitle) {
            this.rolesTitle = this.getTitle('roles');
        }
    }

    getTitle(p: string) {
        return (this.parent[p]||[]).map(u => u.name||u.label).join('\n');
    }
}

