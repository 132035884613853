import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IAlgoliaFilterSet} from '../../shared/search/algolia-search.component';
import {AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, IMenuItem} from '@nxt/model-core';
import {TabBarComponent} from '../tabs/tab-bar.component';
import {CommonModule} from '@angular/common';
import {ThemeService} from '../../shared/_services/theme.service';
import {IconsComponent} from '../../shared/icons/icons.component';
import {FooterButtonsComponent} from '../footer/footer-buttons.component';

@Component({
    standalone: true,
    imports: [CommonModule, TabBarComponent, IconsComponent, FooterButtonsComponent],
    template: `
        <div class="overflow-auto mb-20 p-3">
            <div class="body">
                <tab-bar [tabs]="tabs"></tab-bar>

                <div class="p-3 grid grid-cols-2 lg:grid-cols-3 gap-2 bg-gray-100">
                    <ng-container *ngFor="let filter of activeSet?.items">
                        <div class="p-1 cursor-pointer flex bg-white hover:invert rounded-md content-center overflow-hidden"
                             [style.backgroundColor]="filter.color"
                             (click)="onToggle.emit(filter)">

                            <icon name="heroicon-solid-check" *ngIf="isIn(filter)" class="h-5 w-5 mr-1"
                                                  [style.color]="filter.contrast"></icon>
                            <icon name="heroicon-solid-x" *ngIf="!isIn(filter)" class="h-5 w-5 mr-1"
                                              [style.color]="filter.contrast"></icon>
                            <span [style.color]="filter.contrast">{{ filter.name }}</span>

                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class FiltersDialog {
    @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() filterSet: IAlgoliaFilterSet[];
    @Input() builder: AlgoliaQueryBuilder;
    buttons: IMenuItem[];

    constructor(private tSvc: ThemeService) {
    }

    buildButtons(filterSet: IAlgoliaFilterSet) {
        this.buttons = [
            {
                label: 'Done',
                click: () => {
                    this.onClose.emit();
                }
            }
        ];
        this.buttons = this.buttons.concat(filterSet.buttons||[]);
    }

    get activeSet(): IAlgoliaFilterSet {
        if (!this._set && this.filterSet?.length) {
            this._set = this.filterSet[0];
        }
        return this._set;
    }
    _set: IAlgoliaFilterSet;

    get tabs(): IMenuItem[] {
        return this.filterSet?.map(f => {
            return {
                label: f.name,
                active: this.activeSet.name === f.name,
                click: () => {
                    this._set = f;
                    this.buildButtons(f);
                }
            }
        });
    }

    set activeSet(s: IAlgoliaFilterSet) {
        if (s?.items) {
            s.items.forEach((i, n) => {
                if (!i.color || !i.contrast) {
                    [i.color, i.contrast] = this.tSvc.getRandomColorOrContrast();
                    console.log(i.color, i.contrast);
                }
            });
        }
        this._set = s;
    }

    isIn(filter: AlgoliaQueryFacetFilterItem) {
        return !!this.builder?.filterItems?.find(i => i.id === filter.id);
    }
}
