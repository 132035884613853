"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GmtlShipment = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class GmtlShipment extends model_core_1.Shipment {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, GmtlShipment);
    }
    setDimensions() {
        var _a, _b, _c, _d;
        super.setDimensions();
        if (((_a = this.packaging) === null || _a === void 0 ? void 0 : _a.manual) && !this.packaging.handlingUnit) {
            this.packaging.handlingUnit = 'OTH';
        }
        if (!((_b = this.packaging) === null || _b === void 0 ? void 0 : _b.manual) && ((_c = this.items) === null || _c === void 0 ? void 0 : _c.length)) {
            this.packaging = new model_core_1.Packaging({
                name: 'Calculated By setPackageDimensions Method',
                quantity: 1,
                manual: false,
                weight: this.total_weight,
                handlingUnit: 'OTH',
                dimensions: [
                    { name: 'Height' },
                    { name: 'Width' },
                    { name: 'Length' }
                ]
            });
            let dims = { Length: 0, Width: 0, Height: 0 };
            // Let's start by standardizing the dimensions of all the pieces.
            let addendum = new model_core_1.DimensionArray();
            for (let item of this.items) {
                // console.log('item.properties.shape', item.properties.shape);
                for (let x = 1; x <= item.quantity; x++) {
                    // Handle stackability.  Angles, for example may be 1" x 1" but you can stack them.
                    // So, replace the larger of the two dimensions with the Thickness.
                    // 'Angle' | 'Bar' | 'Beam' | 'Channel' | 'Pipe' | 'Plate' | 'Sheet' | 'Tube' | 'Grating' | 'U-Trim';
                    switch ((_d = item.properties) === null || _d === void 0 ? void 0 : _d.shape) {
                        case 'Angle':
                            dims.Height += item.dimensions.get('Thickness').size; // Cut by an estimated amount to account for stackability.
                            addendum[0] = new model_core_1.Dimension({ name: 'Height' });
                            addendum[0].setSize(item.dimensions.get('Leg A').size);
                            dims.Width += item.dimensions.get('Thickness').size;
                            addendum[1] = new model_core_1.Dimension({ name: 'Width' });
                            addendum[1].setSize(item.dimensions.get('Leg B').size);
                            dims.Length = dims.Length || this.getLargestDimension('Length');
                            break;
                        default:
                            ['Height', 'Width'].forEach(p => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
                                if ((_a = item.dimensions.get(p)) === null || _a === void 0 ? void 0 : _a.size) {
                                    dims[p] += ((_b = item.dimensions.get(p)) === null || _b === void 0 ? void 0 : _b.size) || 0;
                                }
                                else if ((p === 'Height' || p === 'Width')
                                    &&
                                        (((_c = item.dimensions.get('Diameter')) === null || _c === void 0 ? void 0 : _c.size) || ((_d = item.dimensions.get('Outside Diameter')) === null || _d === void 0 ? void 0 : _d.size) || ((_e = item.dimensions.get('Outer Diameter')) === null || _e === void 0 ? void 0 : _e.size))) {
                                    dims[p] += ((_f = item.dimensions.get('Diameter')) === null || _f === void 0 ? void 0 : _f.size) || ((_g = item.dimensions.get('Outside Diameter')) === null || _g === void 0 ? void 0 : _g.size) || ((_h = item.dimensions.get('Outer Diameter')) === null || _h === void 0 ? void 0 : _h.size) || 0;
                                }
                                else if (p === 'Height' && !((_j = item.dimensions.get('Height')) === null || _j === void 0 ? void 0 : _j.size)) {
                                    if ((_k = item.dimensions.get('Thickness')) === null || _k === void 0 ? void 0 : _k.size) {
                                        dims[p] += ((_l = item.dimensions.get('Thickness')) === null || _l === void 0 ? void 0 : _l.size) || 0;
                                    }
                                    else if ((_m = item.dimensions.get('Flange')) === null || _m === void 0 ? void 0 : _m.size) {
                                        dims[p] += ((_o = item.dimensions.get('Flange')) === null || _o === void 0 ? void 0 : _o.size) || 0;
                                    }
                                    else if ((_p = item.dimensions.get('Opening')) === null || _p === void 0 ? void 0 : _p.size) {
                                        dims[p] += ((_q = item.dimensions.get('Opening')) === null || _q === void 0 ? void 0 : _q.size) || 0;
                                    }
                                    else if ((_r = item.dimensions.get('Bar Height')) === null || _r === void 0 ? void 0 : _r.size) {
                                        dims[p] += ((_s = item.dimensions.get('Bar Height')) === null || _s === void 0 ? void 0 : _s.size) || 0;
                                    }
                                }
                                else if (p === 'Width' && !((_t = item.dimensions.get('Width')) === null || _t === void 0 ? void 0 : _t.size)) {
                                    if ((_u = item.dimensions.get('Thickness')) === null || _u === void 0 ? void 0 : _u.size) {
                                        dims[p] += ((_v = item.dimensions.get('Thickness')) === null || _v === void 0 ? void 0 : _v.size) || 0;
                                    }
                                    else if ((_w = item.dimensions.get('Flange')) === null || _w === void 0 ? void 0 : _w.size) {
                                        dims[p] += ((_x = item.dimensions.get('Flange')) === null || _x === void 0 ? void 0 : _x.size) || 0;
                                    }
                                }
                                else {
                                    console.warn(`Item ${item.sku} is missing dimension: ${p}`);
                                }
                            });
                            dims.Length = dims.Length || this.getLargestDimension('Length');
                            // if (!item.properties?.shape) {
                            //     console.warn(item.sku);
                            // }
                            break;
                    }
                }
            }
            // Addendum (for stackable angles)
            if (addendum.length) {
                for (let dim of addendum) {
                    dims[dim.name] += dim.size;
                }
            }
            this.packaging.dimensions = new model_core_1.DimensionArray(Object.keys(dims).map((dim) => {
                let dObj = new model_core_1.Dimension({ name: dim });
                dObj.setSize(dims[dim]);
                return dObj;
            }));
            this.total_width = this.packaging.dimensions.get('Width').size;
            this.total_height = this.packaging.dimensions.get('Height').size;
            this.total_length = this.packaging.dimensions.get('Length').size;
        }
    }
    getLargestDimension(dim) {
        var _a, _b, _c, _d, _e, _f;
        let result;
        if (((_b = (_a = this.packaging) === null || _a === void 0 ? void 0 : _a.dimensions) === null || _b === void 0 ? void 0 : _b.get) && ((_e = (_d = (_c = this.packaging) === null || _c === void 0 ? void 0 : _c.dimensions) === null || _d === void 0 ? void 0 : _d.get(dim)) === null || _e === void 0 ? void 0 : _e.size)) {
            result = this.packaging.dimensions.get(dim).size;
        }
        else {
            (_f = this.items) === null || _f === void 0 ? void 0 : _f.forEach(i => {
                var _a, _b, _c, _d, _e;
                if (((_a = i.dimensions) === null || _a === void 0 ? void 0 : _a.get) && ((_c = (_b = i.dimensions) === null || _b === void 0 ? void 0 : _b.get(dim)) === null || _c === void 0 ? void 0 : _c.size) && (!result || ((_e = (_d = i.dimensions) === null || _d === void 0 ? void 0 : _d.get(dim)) === null || _e === void 0 ? void 0 : _e.size) > result)) {
                    result = i.dimensions.get(dim).size;
                }
            });
        }
        return result;
    }
}
exports.GmtlShipment = GmtlShipment;
index_1.olm.shipments = (ref, map) => {
    return new GmtlShipment(ref, map);
};
