import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {ClientService} from '_library/shared/_services/client.service';
import {CommonModule} from '@angular/common';

import {AvatarStackComponent} from '_library/shared/avatars/avatar.stack.component';
import {PopButtonComponent} from '_library/shared/buttons/pop-button.component';
import {Client} from '@nxt/model-core';
import {AccountService} from '_library/nxt/_services/account.service';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {takeUntil} from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [
        CommonModule,  AvatarStackComponent, PopButtonComponent
    ],
    selector: 'followers-by-role',
    template: `
        <div class="flex w-full" *ngIf="parent">
            <div class="flex-none" *ngIf="label">
                <pop-button [items]="roles"
                            [label]="label"
                            [btnClass]="btnClass"></pop-button>
            </div>
            <div class="flex place-content-center">
            
                <div *ngFor="let role of parent[property]"
                     class="btn-clear cursor-pointer bg-gray-100 btn-xs text-dark truncate max-w-48 rounded-full"
                     (click)="toggleRoles(role)" title="Click to Remove">
                    {{ role.label  }}
                </div>
                
                <pop-button [items]="roles" *ngIf="parent[property].length"
                            label=" + "
                            btnClass="btn-clear btn-xs"></pop-button>
            </div>
        </div>
    `
})
export class FollowersByRoleComponent extends OnDestroyPage {
    @Output() onChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Input() label: string = 'Followers By Role:';
    @Input() parent: any;
    @Input() property: string = 'roles';
    @Input() btnClass: string = 'btn-gray btn-xs';
    roles: any[];

    constructor(
        private cSvc: ClientService,
    ) {
        super();
        this.cSvc.client$.pipe(takeUntil(this.d$))
            .subscribe(c => {
                this.buildRoles(c);
            });
    }

    buildRoles(c: Client) {
        this.roles = [];
        if (c?.config?.roles?.length) {
            for (let role of c.config.roles) {
                // if (uBR[role.id]) {
                this.roles.push({
                    label: (this.parent && this.parent[this.property]?.find(item => item.id === role.id)) ? `- ${role.label}` : `+ ${role.label}`,
                    id: role.id,
                    click: () => {
                        this.toggleRoles(role)
                    }
                });
                // }
                this.roles.sort((a,b) => {
                    return (a.label < b.label) ? -1 : 1;
                })

            }
        }
    }

    async toggleRoles(role: any) {
        if (this.parent && this.parent[this.property]?.find(r => r.id === role.id)) {
            this.parent.remove(this.property, role);
        } else {
            this.parent.add(this.property, {id: role.id, label: role.label});
        }
        this.buildRoles(this.cSvc.client$.getValue());
        this.onChange.emit(this.parent[this.property]);
    }

}
