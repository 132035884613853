import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { addDays, addMonths, endOfMonth, format, parse, startOfDay, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import { OnDestroyPage } from '_library/shared/_inherited/ondestroy.page';
import { IconsComponent } from '_library/shared/icons/icons.component';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'input-date-time-calendar',
    standalone: true,
    imports: [
        CommonModule,
        IconsComponent
    ],
    styles: [`
        .centered {
            position: absolute;
            z-index: 10001;
            top: calc(100vh - 50%);
            left: 50%;
            /* bring your own prefixes */
            transform: translate(-50%, -50%);
        }
    `],
    template: `
        <div class="centered z-[3000] flex max-w-max px-4">
            <div class="w-screen max-w-lg flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-2xl ring-1 ring-gray-900/5">
                <div class="bg-gray-100 p-4">
                    <div>
                        <div class="flex items-center justify-between p-1 mb-6">
                            <div>
                                <button class="btn-clear btn-sm" (click)="prevMonth()">
                                    <span class="sr-only">Previous month</span>
                                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                <button class="btn-clear btn-sm" (click)="nextMonth()">
                                    <span class="sr-only">Next month</span>
                                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                    </svg>
                                </button>    
                            </div>
                            <h2 class="flex-auto text-lg md:text-xl text-sm font-semibold text-gray-900 text-center">{{label}}</h2>
                            <button class="btn-clear btn-sm" (click)="onClose.emit()">
                                <span class="sr-only">Close</span>
                                <icon name="heroicon-outline-x" class="h-5 w-5 text-gray-300 ml-4"></icon>
                            </button>
                        </div>
                        <!-- Tablet/Desktop Layout -->
                        <div class="hidden md:block">
                            <div class="grid gap-3 grid-cols-2">
                                <div>
                                    <div class="grid grid-cols-7 bg-white text-center text-xs leading-6 text-gray-500">
                                        <div>S</div>
                                        <div>M</div>
                                        <div>T</div>
                                        <div>W</div>
                                        <div>T</div>
                                        <div>F</div>
                                        <div>S</div>
                                    </div>
                                    <div class="mt-2 grid grid-cols-7 text-sm bg-white">
                                        <!--
                                              Always include: "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                                              Is selected, include: "text-white"
                                              Is not selected and is today, include: "text-dark-600"
                                              Is not selected and is not today and is current month, include: "text-gray-900"
                                              Is not selected and is not today and is not current month, include: "text-gray-400"
                                              Is selected and is today, include: "bg-dark-600"
                                              Is selected and is not today, include: "bg-gray-900"
                                              Is not selected, include: "hover:bg-gray-200"
                                              Is selected or is today, include: "font-semibold"
                                            -->
                                        <div class="py-1" *ngFor="let item of days; let i = index;">
                                            <button (click)="setDate(item.date)"
                                                    type="button"
                                                    class="mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200
                                                    {{ item.selected ? 'bg-gray-900 font-semibold text-white' 
                                                        : item.past ? 'bg-gray-200 text-gray-400'
                                                        : item.today ? 'font-semibold text-dark-400'
                                                        : item.month ? 'text-gray-800' : 'text-gray-400' }}">
                                                <time>{{ item.label }}</time>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="mt-2 grid {{ hours.length > 12 ? 'grid-cols-4' : 'grid-cols-3' }} text-sm bg-white">
                                        <div *ngFor="let item of hourItems; let i = index;">
                                            <button (click)="setHr(item.label)"
                                                    type="button"
                                                    class="mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200
                                                    {{ item.selected ? 'bg-gray-900 font-semibold text-white' 
                                                        : item.past ? 'bg-gray-200 text-gray-400'
                                                        : 'text-gray-400' }}">
                                                <time>{{ item.label }}</time>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mt-2 grid grid-cols-4 text-sm bg-white">
                                        <div *ngFor="let item of minItems; let i = index;">
                                            <button (click)="setMin(item.label)"
                                                    type="button"
                                                    class="mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200
                                                    {{ item.selected ? 'bg-gray-900 font-semibold text-white' 
                                                        : item.past ? 'bg-gray-200 text-gray-400'
                                                        : 'text-gray-400' }}">
                                                <time>:{{ item.label }}</time>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Mobile Layout -->
                        <div class="md:hidden block">
                            <div class="grid grid-cols-2 gap-1 mb-2">
                                <div (click)="showDate()" [class]="date$|async">
                                    DATE
                                </div>
                                <div (click)="showTime()" [class]="time$|async">
                                    TIME
                                </div>
                            </div>
                            
                            <div *ngIf="(date$|async)===selectedStyle">
                                <div class="grid grid-cols-7 bg-white text-center text-xs leading-6 text-gray-500">
                                    <div>S</div>
                                    <div>M</div>
                                    <div>T</div>
                                    <div>W</div>
                                    <div>T</div>
                                    <div>F</div>
                                    <div>S</div>
                                </div>
                                <div class="mt-2 grid grid-cols-7 text-sm bg-white">
                                    <!--
                                          Always include: "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                                          Is selected, include: "text-white"
                                          Is not selected and is today, include: "text-dark-600"
                                          Is not selected and is not today and is current month, include: "text-gray-900"
                                          Is not selected and is not today and is not current month, include: "text-gray-400"
                                          Is selected and is today, include: "bg-dark-600"
                                          Is selected and is not today, include: "bg-gray-900"
                                          Is not selected, include: "hover:bg-gray-200"
                                          Is selected or is today, include: "font-semibold"
                                        -->
                                    <div class="py-1" *ngFor="let item of days; let i = index;">
                                        <button (click)="setDate(item.date)"
                                                type="button"
                                                class="mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200
                                                {{ item.selected ? 'bg-gray-900 font-semibold text-white' 
                                                    : item.past ? 'bg-gray-200 text-gray-400'
                                                    : item.today ? 'font-semibold text-dark-400'
                                                    : item.month ? 'text-gray-800' : 'text-gray-400' }}">
                                            <time>{{ item.label }}</time>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(time$|async)===selectedStyle">
                                <div class="mt-2 grid {{ hours.length > 12 ? 'grid-cols-4' : 'grid-cols-3' }} text-sm bg-white">
                                    <div *ngFor="let item of hourItems; let i = index;">
                                        <button (click)="setHr(item.label)"
                                                type="button"
                                                class="mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200
                                                {{ item.selected ? 'bg-gray-900 font-semibold text-white' 
                                                    : item.past ? 'bg-gray-200 text-gray-400'
                                                    : 'text-gray-400' }}">
                                            <time>{{ item.label }}-{{item.past}}</time>
                                        </button>
                                    </div>
                                </div>
                                <div class="mt-2 grid grid-cols-4 text-sm bg-white">
                                    <div *ngFor="let item of minItems; let i = index;">
                                        <button (click)="setMin(item.label)"
                                                type="button"
                                                class="mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200
                                                {{ item.selected ? 'bg-gray-900 font-semibold text-white' 
                                                    : item.past ? 'bg-gray-200 text-gray-400'
                                                    : 'text-gray-400' }}">
                                            <time>:{{ item.label }}</time>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="grid grid-cols-2 gap-2">
                            <div (click)="onClose.emit(null)" class="mt-2 btn-gray btn-sm w-full">
                                CLEAR
                            </div>
                            <div (click)="emitDate()" class="mt-2 btn-dark btn-sm w-full">
                                SET
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    `
})
export class InputDateTimeCalendar extends OnDestroyPage implements OnChanges {
    @Output() onClose: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() onDestroy: EventEmitter<Date> = new EventEmitter<Date>();
    @Input() date: string;
    @Input() minDate: Date;
    @Input() hours: string[] = ['6','7','8','9','10','11','12','13','14','15','16','17','18'];
    @Input() minutes: string[] = ['00', '15', '30', '45' ];
    label: string;
    reference: Date;
    selected: Date;
    days: any[];
    selectedDay: Date;
    selectedHr: string;
    selectedMin: string = '00';
    selectedStyle: string = 'cursor-pointer text-center text-xs leading-6 bg-gray-200 text-black hover:text-black';
    unselectedStyle: string = 'cursor-pointer text-center text-xs leading-6 bg-gray-200 text-white hover:text-gray-600';
    time$: BehaviorSubject<string> = new BehaviorSubject<string>(this.unselectedStyle);
    date$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedStyle);

    hourItems: any[];
    minItems: any[];

    constructor(
    ) {
        super();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.onDestroy.emit();
    }

    ngOnChanges(changes?: SimpleChanges) {

        if (this.date && (!this.days?.length || changes.date)) {
            // The reference date is the date around which the calendar
            // display is built.  This begins with the 'date' value passed
            // into the component, but changes as the user navigates through
            // the calendar.
            this.reference = parse(this.date, 'M/d/yyyy H:mm', new Date());
            this.selectedDay = this.reference;
            this.selectedHr = this.selectedDay.getHours().toString();
            this.selectedMin = this.selectedDay.getMinutes().toString();
            if (this.selectedMin === '0') {
                this.selectedMin = '00';
            }
        } else {
            this.reference = new Date();
            this.selectedDay = this.reference;
        }
        this.buildDays();
    }

    showTime() {
        this.time$.next(this.selectedStyle);
        this.date$.next(this.unselectedStyle);
        this.buildDays();
    }

    showDate() {
        this.time$.next(this.unselectedStyle);
        this.date$.next(this.selectedStyle);
        this.buildDays();
    }

    setDate(d: Date) {
        if (!this.minDate || startOfDay(d).valueOf() >= startOfDay(this.minDate).valueOf()) {
            this.selectedDay = d;
            this.reference = d;
            this.buildDays();
        }
    }

    emitDate() {
        if (this.selectedDay !== undefined && this.selectedHr !== undefined && this.selectedMin !== undefined) {
            this.selected = parse(`${format(this.selectedDay, 'M/d/yyyy')} ${this.selectedHr}:${this.selectedMin}`, `MM/dd/yyyy H:mm`, new Date());
            this.onClose.emit(this.selected);
        }
    }

    setHr(n: string) {
        if (!this.isHrPast(n)) {
            this.selectedHr = n;
            this.buildHours();
        }
    }

    setMin(n: string) {
        this.selectedMin = n;
        this.buildHours();
    }

    buildDays() {
        this.label = format(this.reference, 'MMMM yyyy');
        // Build the list of days for the currently referenced month.
        let today: Date = new Date(Date.now());
        let start: Date = startOfWeek(startOfMonth(this.reference));
        let end: Date = endOfWeek(endOfMonth(this.reference));
        // If the start of the month is not a Monday, go backward until we find a monday.
        this.days = [];
        while (start <= end) {

            let item: any = {
                date: start,
                label: start.getDate(),
                past: (this.minDate && startOfDay(start).valueOf() < startOfDay(this.minDate).valueOf()),
                today: (start.getDate() === today.getDate() && start.getMonth() === today?.getMonth()),
                selected: (start.getDate() === this.selectedDay?.getDate() && start.getMonth() === this.selectedDay?.getMonth()),
                month: (start.getMonth() === this.reference.getMonth())
            };
            this.days.push(item);
            start = addDays(start, 1);
        }
        this.buildHours();
    }

    buildHours() {
        this.hourItems = this.hours?.map(hr => {
            let item:any = {
                label: hr,
                past: this.isHrPast(hr),
                selected: this.selectedHr === hr
            };
            return item;
        });
        this.buildMinutes();
    }

    buildMinutes() {
        this.minItems = this.minutes?.map(min => {
            let item:any = {
                label: min,
                past: this.isMinPast(min),
                selected: this.selectedMin === min
            };
            return item;
        });
    }

    isHrPast(hr: string):boolean {
        if (this.minDate
            && this.minDate.getDate() === this.selectedDay.getDate() && this.minDate.getMonth() === this.selectedDay.getMonth()
        ) {
            if (this.minDate.getHours() > Number(hr)) {
                return true;
            }
        }
        return false;
    }

    isMinPast(min: string):boolean {
        if (
            this.minDate
            && this.minDate.getDate() === this.selectedDay.getDate()
            && this.minDate.getMonth() === this.selectedDay.getMonth()
            && this.selectedHr
            && this.minDate.getHours() === Number(this.selectedHr)
        ) {
            if (this.minDate.getMinutes() > Number(min)) {
                return true;
            }
        }
        return false;
    }

    prevMonth() {
        this.reference = subMonths(this.reference,1);
        this.buildDays();
    }

    nextMonth() {
        this.reference = addMonths(this.reference,1);
        this.buildDays();
    }
}
