import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EMedium, REACTIONS, ThreadMessage, Unread} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {UserService} from '@library/nxt/_services/user.service';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {AccountService} from '@library/nxt/_services/account.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {ClientService} from '@library/shared/_services/client.service';

@Component({
    selector: 'message-reactions',
    standalone: true,
    imports: [
        CommonModule,
        IconsComponent
    ],
    template: `
        <div class="flex w-full place-content-center text-xs" *ngIf="loaded">
            <div [title]="r[p].title" *ngFor="let p of REACTIONS" (click)="toggle(p)" class="p-0.5 cursor-pointer flex justify-between place-items-center mr-1">
                <icon [name]="'heroicon-outline-'+p" [class]="r[p].count ? 'text-dark h-3 w-3 mr-0.5':'text-black h-3 w-3 mr-0.5'"></icon>
                <span>{{ r[p].count }}</span>
            </div>
        </div>
    `
})
export class MessageReactions implements OnChanges {
    @Input() message: ThreadMessage;
    REACTIONS = REACTIONS;
    r: any = {};
    uBR: any;
    loaded: boolean;

    constructor(
        private uSvc: UserService,
        private acctSvc: AccountService,
        private fSvc: FireService,
        private cSvc: ClientService
    ) {

    }

    async ngOnChanges(changes: SimpleChanges) {
        if (this.message && (this.message.medium === EMedium.CHAT || this.message.medium === EMedium.NOTE)) {
            this.uBR = await this.acctSvc.getUsersByRole();
            this.tally();
        }
    }

    tally() {
        REACTIONS.forEach(p => {
            this.r[p] = {
                count: Object.keys(this.message.reactions||{}).reduce((n,id) => {
                    if (this.message.reactions[id] === p) {
                        n++;
                    }
                    return n;
                },0),
                title: this.getTitle(p)
            };
        });
        this.loaded = true;
    }

    async toggle(p: string) {
        if (this.message.reactions[this.uSvc.user$.getValue().id] !== p) {
            this.message.reactions[this.uSvc.user$.getValue().id] = p;
        } else {
            delete this.message.reactions[this.uSvc.user$.getValue().id];
        }
        this.tally();
        this.message.update({reactions: this.message.reactions});
        // Mark the item seen if thumbed.
        let u = ( await this.fSvc.getObject(`users/${this.uSvc.user$.getValue().id}/clients/${this.cSvc.client_id}/unread/${this.message.id}`)) as Unread;
        if (u._exists) {
            await u.update({seen:true});
        }
    }

    getTitle(p: string) {
        return Object.keys(this.message.reactions||{}).reduce((n,id) => {
            if (this.message.reactions[id] === p) {
                let u = this.uBR.user.find(u => u.id === id);
                if (u) {
                    n.push(u.nick_name||u.name);
                }
            }
            return n;
        },[]).join('\n');
    }
}
