import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Output, EventEmitter} from "@angular/core";
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {take} from 'rxjs/operators';

import {ImageCropperComponent} from './cropper.component';

import {PageService} from '../../shared/_services/page.service';
import {FireService} from '../_services/fire.service';
import {CloudFile, getSID} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ClientService} from '../../shared/_services/client.service';

// @Directive({
//     selector: "[appDrag]"
// })
// export class DragDirective {
//     @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
//     @HostBinding("style.background") private background = "#eee";
//
//     constructor(private sanitizer: DomSanitizer) { }
//
//     @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
//         evt.preventDefault();
//         evt.stopPropagation();
//         this.background = "#999";
//     }
//
//     @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
//         evt.preventDefault();
//         evt.stopPropagation();
//         this.background = "#eee";
//     }
//
//     @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
//         evt.preventDefault();
//         evt.stopPropagation();
//         this.background = '#eee';
//         this.createHandle(evt.dataTransfer.files);
//     }
//
//     createHandle(files: any){
//         let fileHandles: FileHandle[] = [];
//         for (const file of files) {
//             const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
//             fileHandles.push({ file, url });
//         }
//         if (fileHandles.length > 0) {
//             this.files.emit(fileHandles);
//         }
//     }
// }

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule],
    selector: 'input-image',
    template: `
        <div class="max-w-500 relative mt-4 border-gray-300 bg-gradient-to-r from-gray-100 to-gray-300 border border-dashed rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-dark-600 focus-within:border-dark-600 {{class}}">
            <label *ngIf="label && !condensed" (click)="collapsed=!collapsed"
                   class="absolute -top-2 left-2 -mt-px cursor-pointer inline-block px-1 bg-white rounded-md text-xs font-medium text-black truncate">
                {{ collapse ? collapsed ? label + ' +' : label + ' -' : label}}
            </label>
            <div *ngIf="!collapse || !collapsed"
                 class="flex justify-center">
                <div class="space-y-1 text-center ">

                    <div class="flex">
                        <ng-container *ngIf="copies?.length">
                            <img *ngFor="let img of copies;"
                                 [src]="img"
                                 class="mx-auto" style="max-width: 100px; max-height: 100px;"
                                 (click)="cropImage(img)"
                            />
                        </ng-container>
                        <ng-container *ngIf="!copies?.length && !condensed">
                            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none"
                                 viewBox="0 0 48 48" aria-hidden="true">
                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </ng-container>
                    </div>

                    <p class="text-xs text-gray-500 mt-2 mb-2" *ngIf="copies?.length && crop">
                        Click an image to crop
                    </p>

                    <p class="text-xs text-gray-500" *ngIf="!condensed">
                        <label [for]="id"
                               class="relative cursor-pointer rounded-md font-medium text-dark-600 hover:text-dark-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-dark-500">
                            <u>Upload a file</u>
                            <input [id]="id" [multiple]="multiple" [name]="label" type="file" [accept]="accept"
                                   (input)="createHandle($event.target['files'])" class="sr-only"/>
                        </label>
                    </p>
                    <p class="text-xs text-gray-500" *ngIf="condensed">
                        <label [for]="id"
                               class="relative cursor-pointer rounded-md font-medium text-dark-600 hover:text-dark-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-dark-500">
                            <u>Upload</u>
                            <input [id]="id" [multiple]="multiple" [name]="label" type="file" [accept]="accept"
                                   (input)="createHandle($event.target['files'])" class="sr-only"/>
                        </label>
                        <ng-container *ngIf="showUrlInput">
                            <input class="input-default h-20 w-full" [(ngModel)]="externalUrl"
                              placeholder="URL to download and save"/>
                            <button class="btn-dark btn-xs mt-2" (click)="importUrl()">
                                Download & Save
                            </button>
                        </ng-container>
                    </p>


                    <p class="text-xs text-gray-500" *ngIf="!condensed">
                        {{ placeholder }}<br/>
                        <span *ngIf="accept">File types: {{accept}} <span
                                *ngIf="sizeLimit">up to {{ sizeLimit }}</span></span>
                    </p>
                </div>
            </div>
        </div>
    `
})
export class InputImageComponent implements OnChanges {
    @Output() uploadComplete: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() uploadStarted: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() label: string;
    @Input() collapse: boolean;
    @Input() placeholder: string;
    @Input() fileTypes: string[];
    @Input() sizeLimit: string = '';
    @Input() condensed: boolean = false;
    @Input() crop: any;
    @Input() path: string;
    @Input() size: string;
    @Input() limits: any;
    @Input() images: any[];
    @Input() multiple: boolean = false;
    @Input() class: string = '';
    @Input() ignoreFileSize: boolean;
    @Input() showUrlInput: boolean;
    externalUrl: string;
    collapsed: boolean = true;
    id: string = getSID(20);
    copies: any[];

    get accept(): string {
        return this.fileTypes?.join ? this.fileTypes.join(',') : '';
    }

    files: FileHandle[] = [];

    constructor(
        private pSvc: PageService,
        private cSvc: ClientService,
        private fSvc: FireService,
        private sanitizer: DomSanitizer,
        private cRef: ChangeDetectorRef
    ) {
    }


    async ngOnChanges(changes: SimpleChanges) {
        if (this.images) {
            this.copies = await Promise.all( this.images.map(async i => {
                return i;
                // const image: Blob = (await this.pSvc.http.get(i+now, {responseType: 'blob' as 'json'}).toPromise()) as Blob;
                // return this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(image));
            }));
            this.cRef.detectChanges();
        }
    }

    async upload(fileHandles: FileHandle[]) {

        if (this.path) {

            if (this.size && this.limits) {
                this.path = this.path.replace(/\/images\//, `/images/${this.size}KB/`);
            }

            this.pSvc.loading$.next(true);
            this.uploadStarted.emit(true);
            // let urls: string[] = [];
            let files: any[] = [];

            for (const handle of fileHandles) {

                let filePath: string = this.path;
                filePath = filePath.replace(/\/$/, '');
                if (!filePath.match(/\.(jpg|png)$/i)) {
                    const lastDot = handle.file.name.lastIndexOf('.');
                    const suffix = handle.file.name.substring(lastDot);
                    if (this.multiple) {
                        filePath += `/${Date.now()}${suffix}`;
                    } else {
                        filePath += `${suffix}`;
                    }
                }

                try {
                    let file: any = new CloudFile(handle.file);
                    if (handle.file.size > 200000 && !this.ignoreFileSize) {
                        let diff: number = handle.file.size - 200000;
                        if (diff > 1000000) {
                            this.pSvc.notification$.next({
                                title: 'Very Large File Alert!',
                                message: `The file ${handle.file.name} is very large! Files this large could slow down user experience.  Recommended max size for images is 200kb.`
                            });
                        } else {
                            this.pSvc.notification$.next({
                                title: 'Large File Alert!',
                                message: `The file ${handle.file.name} is larger than the recommended size (200kb). Files this large could slow down user experience.`
                            });
                        }
                    }
                    file.url = await this.fSvc.upload(filePath, handle.file);
                    files.push(file);

                } catch (e) {
                    this.pSvc.alert$.next(e);
                }
            }
            this.pSvc.loading$.next(false);;
            this.uploadComplete.emit(files);
            this.collapsed = true;
        } else {
            this.pSvc.alert$.next({title: 'Error', message: 'Path is not set on upload component! Cannot upload!'});
        }

    }

    createHandle(files: any){
        let fileHandles: FileHandle[] = [];
        for (const file of files) {
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            fileHandles.push({ file, url });
        }
        if (fileHandles.length > 0) {
            this.upload(fileHandles);
        }
    }

    async cropImage(img: string) {
        if (this.crop) {
            const image: Blob = (await this.pSvc.http.get(img, {responseType: 'blob' as 'json'}).toPromise()) as Blob;
            const cropperImage = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(image));
            let suffix: string = image.type.replace('image/', '').replace('jpeg', 'jpg');
            this.pSvc.modal$.next({
                component: ImageCropperComponent,
                label: 'Crop Image',
                styles: {maxHeight: '90%'},
                onLoaded: (comp: ImageCropperComponent) => {
                    comp.data = {
                        imageSource: cropperImage,
                        file: new File([image], `.${suffix}`, {type: image.type}),
                        options: {
                            aspectRatio: 1 / 1
                        }
                    };
                    comp.onClose
                        .pipe(take(1))
                        .subscribe(
                            async (img: File) => {
                                if (img) {
                                    await this.createHandle([img]);
                                }
                            });
                }
            });

        }
    }

    async importUrl() {
        this.pSvc.loading$.next(true);
        try {

            //TODO VALIDATE FUNCTIONALITY
            let result: any = await this.cSvc.callAPI('/cms/copy/url', 'post', {
                url: this.externalUrl,
                path: this.path
            });
            if (result?.url) {
                this.uploadComplete.emit([result]);
                this.externalUrl = '';
                this.pSvc.notification$.next({
                    title: 'Download Complete!',
                });
            }

        } catch (e) {
            this.pSvc.alert$.next(e)
        }
        this.pSvc.loading$.next(false);;
    }

}

export interface FileHandle {
    file: File,
    url: SafeUrl
}
