import {Component, Input} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
    standalone: true,
    imports: [NgIf],
    selector: 'date-header',
    template: `
        <div class="bg-accent-600" *ngIf="label">
            <div class="w-full py-1 px-2 sm:px-4 lg:px-5">
                <div class="flex items-center justify-between flex-wrap">
                    <div class="w-0 flex-1 flex items-center">
                        <p class="ml-3 text-sm font-medium text-white">
                            {{ label }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class DateHeaderComponent {
    @Input() label: string;
}
