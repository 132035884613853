export const nxtoLazyComponents: any = {
    'nxto-nxt-product-details': {
        t: 'Product editor component used in NXTO only.',
        client: 'NONE',
        loader: () => import('./_components/orders/nxto-product-details.component')
    },
    'nxto-nxt-order-details': {
        t: 'Order details editor component used in NXTO only.',
        client: 'NONE',
        loader: () => import('./_components/orders/nxto-order-details.component')
    },
   'nxto-header':{
        t:'Header component used in NXTO only',
        client: 'NXTO',
        type:'header',
        loader: ()=>import('./_components/header/header'),
        config: ()=>import('./_components/header/header-config')
   },
   'nxto-bg-image':{
       t:'Top section for nxto',
       client: 'NXTO',
       loader: ()=>import('./_components/bg-image-text/bg-image-text'),
       config:()=>import('./_components/bg-image-text/bg-image-text-config'),
   },
   'nxto-footer':{
       t:'Footer section for nxto',
       client: 'NXTO',
       loader: ()=>import('./_components/footer/footer'),
       config:()=>import('./_components/footer/footer-config')
   },
    'content-circle-image':{
        t:'Circle Image with smaller circles surrounding it with text',
        loader: ()=>import('./_components/content-circle-image/circle-image'),
        config: ()=>import('./_components/content-circle-image/circle-image-config')
    },
    'review-carousel':{
       t:'A Review Carousel',
       loader: ()=>import('./_components/review-carousel/review-carousel'),
       config: ()=>import('./_components/review-carousel/review-carousel-config')
    }

}
