import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule
    ],
    selector: 'input-checkbox',
    template: `
        <div *ngIf="form && controlName" [formGroup]="form"
             [class]="class+' max-w-400 relative mt-4 bg-white border border-gray-300 rounded-md shadow-sm focus-within:ring-1 focus-within:ring-dark-600 focus-within:border-dark-600'">
            <label *ngIf="label" [for]="controlName"
                   class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white rounded-md text-xs font-medium text-gray-900">{{label}}</label>
            <div class="flex px-3 py-2 form-check">
                <input [id]="controlName"
                       class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-dark-600 checked:border-dark-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                       type="checkbox"
                       (change)="onChange.emit()"
                       [formControlName]="controlName">
                <label [for]="controlName" *ngIf="placeholder" class="pl-2 mt-0.5 text-xs text-gray-500">{{placeholder}}</label>
            </div>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('required')"
               class="px-3 pt-1 italic text-xs text-red-500">
                Required
            </p>
        </div>
    `
})
export class InputCheckboxComponent implements OnInit {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() class: string = '';
    @Input() hideErrors: boolean = false;
    @Input() default:string;

    ngOnInit() {
        if (this.form?.get(this.controlName) && this.form.get(this.controlName).value === undefined && this.default !== undefined) {
            this.form.get(this.controlName).setValue(this.default);
        }
    }
}
