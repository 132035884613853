import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {PageService} from '@library/shared/_services/page.service';
import {FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {format, parse} from 'date-fns';
import {take} from 'rxjs/operators';
import {InputDateTimeCalendar} from './input-date-time-calendar';

@Component({
    selector: 'input-date-time',
    standalone: true,
    imports: [
        CommonModule, IconsComponent,
        FormsModule, ReactiveFormsModule,
        IconsComponent, InputDateTimeCalendar, IconsComponent
    ],
    styles: [`
        .centered {
            position: absolute;
            top: 30vh;
            left: 50%;
            /* bring your own prefixes */
            transform: translate(-50%, -50%);
        }
    `],
    template: `
        <div #container *ngIf="form && controlName" [formGroup]="form"
             [class]="'w-full max-w-sm relative mt-4 bg-white border border-gray-300 rounded-md shadow-sm ' + class">
            <label *ngIf="label" [for]="controlName"
                   class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white rounded-md text-xs font-medium text-gray-900">{{label}}</label>
            <div class="flex px-3 py-2 relative">
                <input type="text"
                       [placeholder]="placeholder"
                       (dblclick)="showCalendar()"
                       [formControlName]="controlName"
                       [id]="controlName"
                       [class]="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required') ? errorInputClass : inputClass"
                />
                <button (click)="showCalendar()" class="btn-gray border-t-0 border-r-0 border-b-0 absolute -right-1 top-0 h-full">
                    <icon class="h-5 w-5" name="heroicon-outline-calendar"></icon>
                </button>
            </div>
            <p *ngIf="form?.get(controlName).touched && form.get(controlName)?.hasError('required')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Required
                </p>
                <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('invalid')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Required format: m/d/yyyy H:mm
                </p>
                <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('min')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Date not allowed
                </p>
        </div>
    `
})
export class InputDateTimeComponent implements OnChanges {
    @ViewChild('container', {static: false}) container: ElementRef;
    @Output() onChange: EventEmitter<Date> = new EventEmitter<Date>();
    @Input() class: string = '';
    @Input() label: string;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() value: Date;
    @Input() minDate: Date;
    @Input() placeholder: string = 'm/d/yyyy H:mm';
    @Input() hours: string[];
    @Input() minutes: string[];
    baseInputClass: string = 'w-full block border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-xs';
    comp: InputDateTimeCalendar
    show: boolean;

    get errorInputClass() {
        return `input-error ${this.baseInputClass}`;
    }
    get inputClass() {
        return `input-default ${this.baseInputClass}`;
    }

    constructor(
        public pSvc: PageService
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.form && this.controlName) {
            this.form.get(this.controlName).addValidators([this.dateValidator(this)]);
        }
    }

    dateValidator(t) {
        return (control: FormControl) => {
            if (control.value) {
                let d: Date = parse(control.value, 'M/d/yyyy H:mm', new Date());
                if (isNaN(d.valueOf())) {
                    return { invalid: true };
                } else if (this.minDate && this.minDate.valueOf() > d.valueOf()) {
                    return {min: true};
                } else {
                    this.value = d;
                }
            }
            return null;
        }
    }

    showCalendar() {
        if (!this.show) {
            this.show = true;
            this.pSvc.modal$.next({
                component: InputDateTimeCalendar,
                onLoaded: (comp: InputDateTimeCalendar) => {
                    comp.minDate = this.minDate;
                    comp.date = this.form.get(this.controlName).value || format(new Date(), 'M/d/yyyy H:mm');
                    if (this.hours) {
                        comp.hours = this.hours;
                    }
                    if (this.minutes) {
                        comp.minutes = this.minutes;
                    }
                    comp.ngOnChanges();
                    comp.onDestroy.pipe(take(1)).subscribe(d => {
                        this.show = false;
                    });
                    comp.onClose.pipe(take(1)).subscribe(d => {
                        this.comp = null;
                        if (d !== undefined) {
                            this.value = d;
                            if (d) {
                                this.form.get(this.controlName).setValue(format(d, 'M/d/yyyy H:mm'));
                            } else {
                                this.form.get(this.controlName).setValue('');
                            }
                            this.onChange.emit(d);
                        }
                    });
                    this.comp = comp;
                }
            })
        }
    }

}
