"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EwinQuote = exports.EwinCart = exports.EwinOrder = void 0;
const model_core_1 = require("@nxt/model-core");
const OrderItem_1 = require("./OrderItem");
const index_1 = require("../index");
class EwinOrder extends model_core_1.Order {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type || 'orders', idLen);
        this.pricing = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, EwinOrder);
    }
    async loadAll(opts, stack, p) {
        var _a, _b;
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
        if ((_a = this.items) === null || _a === void 0 ? void 0 : _a.sort) {
            (_b = this.items) === null || _b === void 0 ? void 0 : _b.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                if (a.date < b.date) {
                    return -1;
                }
                if (a.date > b.date) {
                    return 1;
                }
                return 0;
            });
        }
    }
    setItemsByQty(u) {
        this.items_by_qty = [];
        if (this.items && this.items.length) {
            let total = 1;
            this.items.forEach((item) => {
                item = new OrderItem_1.EwinOrderItem(item);
                if (item._orderable && item.metadata.added) {
                    let n = 1;
                    while (n <= item.quantity) {
                        let piece = new OrderItem_1.EwinOrderItem(JSON.parse(JSON.stringify(item)));
                        piece.quantity = 1;
                        piece.index = total;
                        piece.id = `${item.id}-${n}`;
                        piece.calc(u);
                        this.items_by_qty.push(piece);
                        total++;
                        n++;
                    }
                }
            });
        }
    }
    async copy(parent) {
        return new EwinOrder(await super.clean(this, parent, ['items'], ['payments', 'shipments']));
    }
    async toSearchJSON(opts) {
        var _a;
        let r = await super.toSearchJSON(opts);
        // Add service center to output. Put it in location_id. This allows for only one location per order
        // which should work the vast majority of times - but possibly not always.
        let locations = ((_a = this.shipments) === null || _a === void 0 ? void 0 : _a.map(s => { var _a; return ((_a = s.from_address) === null || _a === void 0 ? void 0 : _a.id) || s.from_address_id; })) || [];
        if (locations.length) {
            r.location_id = locations[0];
        }
        return r;
    }
}
exports.EwinOrder = EwinOrder;
/**
 * Used on the e-commerce sites, which contains full order objects including items, shipments and payments -
 * which must be initialized when loaded from firebase.
 */
class EwinCart extends EwinOrder {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, EwinCart);
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new EwinOrder(await super.clean(new EwinOrder(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.EwinCart = EwinCart;
class EwinQuote extends EwinOrder {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'quotes', 23);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, EwinQuote);
    }
    toString() {
        return `Quote: ${this.quote_id}`;
    }
    async copy(parent) {
        return new EwinQuote(await super.clean(new EwinQuote(this), parent, ['items', 'shipments'], null, true));
    }
    async copyToOrder(parent) {
        await this.loadAll();
        return new EwinOrder(await super.clean(new EwinOrder(this), parent, ['items', 'shipments'], null, true));
    }
}
exports.EwinQuote = EwinQuote;
index_1.olm.orders = (ref, map) => {
    return new EwinOrder(ref, map);
};
index_1.olm.quotes = (ref, map) => {
    return new EwinQuote(ref, map);
};
