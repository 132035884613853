import {Component, Input} from '@angular/core';
import {Contact} from '@nxt/model-core';

@Component({
    standalone: true,
    imports: [],
    selector: 'order-quote-count',
    template: `
        <div class="flex mr-2">
            <div class="flex bg-gray-300 text-white place-content-center px-1"
                 style="font-size: 11px;">
                {{contact?.counts?.orders || '0'}}
            </div>
            <div class="flex text-white font-xs place-content-center px-1"
                 style="font-size: 11px; background-color: lightsalmon">
                {{contact?.counts?.quotes || '0'}}
            </div>
        </div>
    `
})
export class OrderQuoteCount {
    @Input() contact: Contact;
}
