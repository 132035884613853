"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BUILDER_STEPS = exports.FRAME_LIMITS = exports.HARDWARE = exports.SCREEN = exports.GRID = exports.GLASS = exports.FRAME = exports.WINDOW = exports.LOCATION = exports.sharedValidator = exports.TRUE_FALSE = exports.YES_NO = exports.OPERATION = exports.COLORS = exports.PATTERN = exports.GRID_TYPE = exports.QUALITY = exports.WINDOW_STYLE = exports.WINDOW_TYPE = exports.GEO = void 0;
var GEO;
(function (GEO) {
    GEO["EAST"] = "east";
    GEO["WEST"] = "west";
})(GEO || (exports.GEO = GEO = {}));
var WINDOW_TYPE;
(function (WINDOW_TYPE) {
    WINDOW_TYPE["REPLACEMENT"] = "REPL";
    WINDOW_TYPE["NEW"] = "NEW";
})(WINDOW_TYPE || (exports.WINDOW_TYPE = WINDOW_TYPE = {}));
var WINDOW_STYLE;
(function (WINDOW_STYLE) {
    WINDOW_STYLE["SINGLE_HUNG"] = "SH";
    WINDOW_STYLE["DOUBLE_HUNG"] = "DH";
    WINDOW_STYLE["SLIDER"] = "SLI";
    WINDOW_STYLE["PICTURE"] = "PIC";
    WINDOW_STYLE["CASEMENT"] = "CASE";
    WINDOW_STYLE["AWNING"] = "AWN";
})(WINDOW_STYLE || (exports.WINDOW_STYLE = WINDOW_STYLE = {}));
var QUALITY;
(function (QUALITY) {
    QUALITY["STANDARD"] = "$";
    QUALITY["SELECT"] = "$$";
    QUALITY["PREMIUM"] = "$$$";
})(QUALITY || (exports.QUALITY = QUALITY = {}));
var GRID_TYPE;
(function (GRID_TYPE) {
    GRID_TYPE["NONE"] = "";
    GRID_TYPE["SDL"] = "sdl";
    GRID_TYPE["CONTOUR"] = "contour";
    GRID_TYPE["FLAT"] = "flat";
    GRID_TYPE["CONTOUR_ONE"] = "1";
    GRID_TYPE["CONTOUR_THREE_FOURTHS"] = "3/4";
})(GRID_TYPE || (exports.GRID_TYPE = GRID_TYPE = {}));
var PATTERN;
(function (PATTERN) {
    PATTERN["COLONIAL"] = "COL";
    PATTERN["CRAFTSMAN"] = "CRA";
    PATTERN["SINGLE_PRAIRIE"] = "PRA";
    PATTERN["SINGLE_PERIMETER"] = "PER";
    PATTERN["SINGLE_VERTICAL"] = "VER";
})(PATTERN || (exports.PATTERN = PATTERN = {}));
var COLORS;
(function (COLORS) {
    COLORS["WHITE"] = "white";
    COLORS["ALMOND"] = "almond";
    COLORS["BEIGE"] = "beige";
    COLORS["BLACK"] = "black";
    COLORS["BROWN"] = "brown";
    COLORS["CLASSIC_CLAY"] = "classic_clay";
    COLORS["CLAY"] = "clay";
})(COLORS || (exports.COLORS = COLORS = {}));
var OPERATION;
(function (OPERATION) {
    OPERATION["LEFT"] = "left";
    OPERATION["RIGHT"] = "right";
})(OPERATION || (exports.OPERATION = OPERATION = {}));
exports.YES_NO = [
    { label: 'No', value: '' },
    { label: 'Yes', value: 'YES' }
];
exports.TRUE_FALSE = [
    { label: 'No', value: false },
    { label: 'Yes', value: true }
];
const sharedValidator = (item, parentSettings) => {
    return (c) => {
        var _a;
        if (parentSettings.disabled && parentSettings.disabled(item)) {
            return null;
        }
        if (!c.value && parentSettings.required && parentSettings.required(item)) {
            return { error: parentSettings.required(item) };
        }
        else if (c.value) {
            let err = (_a = parentSettings.options.find(item => item.value === c.value)) === null || _a === void 0 ? void 0 : _a.disabled;
            err = (err instanceof Function) ? err(item) : err;
            if (err) {
                return { error: err };
            }
        }
        return null;
    };
};
exports.sharedValidator = sharedValidator;
exports.LOCATION = {
    installation: {
        options: exports.TRUE_FALSE,
        required: (i, u) => {
            return false;
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.LOCATION.installation);
        },
        default: (i, u) => {
            return 0;
        }
    }
};
exports.WINDOW = {
    type: {
        options: [
            { label: 'Replacement', value: WINDOW_TYPE.REPLACEMENT },
            { label: 'New Construction', value: WINDOW_TYPE.NEW }
        ],
        required: (i, u) => {
            return 'Must select window type.';
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.WINDOW.type);
        },
        default: (i, u) => {
            return 1;
        }
    },
    style: {
        options: [
            { label: 'Single-Hung', value: WINDOW_STYLE.SINGLE_HUNG },
            {
                label: 'Double-Hung', value: WINDOW_STYLE.DOUBLE_HUNG,
                disabled: (i, u) => {
                    if (i.window.type.value !== WINDOW_TYPE.REPLACEMENT) {
                        if (i.qlty() === QUALITY.STANDARD) {
                            return 'Double-hung style is not available in Standard Series';
                        }
                        else if (i.qlty() === QUALITY.SELECT && i.geo() === GEO.WEST) {
                            return 'Double-hung style is not available in Select Series';
                        }
                    }
                }
            },
            {
                label: 'Casement',
                value: WINDOW_STYLE.CASEMENT,
                disabled: (i, u) => {
                    if (i.qlty() !== QUALITY.STANDARD && i.typ() === WINDOW_TYPE.NEW) {
                        return 'Casement is only available in Standard quality.';
                    }
                }
            },
            { label: 'Picture', value: WINDOW_STYLE.PICTURE },
            { label: 'Slider', value: WINDOW_STYLE.SLIDER },
            {
                label: 'Awning',
                value: WINDOW_STYLE.AWNING,
                disabled: (i, u) => {
                    if (i.qlty() !== QUALITY.STANDARD && i.typ() === WINDOW_TYPE.NEW) {
                        return 'Awning is only available in Standard quality.';
                    }
                }
            }
        ],
        required: (i, u) => {
            return 'Must select window style.';
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.WINDOW.style);
        }
    },
    quality: {
        options: [
            { label: 'Standard', value: QUALITY.STANDARD },
            {
                label: 'Select',
                value: QUALITY.SELECT
            },
            {
                label: 'Premium',
                value: QUALITY.PREMIUM
            }
        ],
        default: (i, u) => {
            return 2;
        },
        required: (i, u) => {
            return (i.window.type.value === WINDOW_TYPE.REPLACEMENT) ? false : true;
        },
        disabled: (i, u) => {
            return (i.window.type.value === WINDOW_TYPE.REPLACEMENT) ? true : false;
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.WINDOW.quality);
        }
    },
    operation: {
        options: [
            { label: 'Left', value: OPERATION.LEFT },
            { label: 'Right', value: OPERATION.RIGHT },
        ],
        disabled: (i, u) => {
            if (i.styl() === WINDOW_STYLE.CASEMENT) {
                return (i.window.number.value !== '1') ? true : false;
            }
            else {
                return true;
            }
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.WINDOW.operation);
        },
        default: (i, u) => {
            return 0;
        }
    },
    sash: {
        options: [
            {
                label: 'Even',
                value: 'even',
                disabled: (i, u) => {
                    return (i.styl() === WINDOW_STYLE.CASEMENT) ? true : false;
                }
            },
            {
                label: '1/4 x 1/2 x 1/4',
                value: '1/4',
                disabled: (i, u) => {
                    if (i.styl() === WINDOW_STYLE.CASEMENT) {
                        if (i.geo() === GEO.WEST) {
                            return true;
                        }
                    }
                    else if (i.styl() !== WINDOW_STYLE.SLIDER) {
                        return true;
                    }
                    else if (i.styl() === WINDOW_STYLE.SLIDER
                        && i.qlty() === QUALITY.PREMIUM
                        && i.num() !== '1') {
                        return true;
                    }
                }
            },
            {
                label: '1/3 x 1/3 x 1/3',
                value: '1/3',
                disabled: (i, u) => {
                    if (i.styl() === WINDOW_STYLE.SLIDER
                        && i.qlty() === QUALITY.PREMIUM
                        && i.num() !== '1') {
                        return true;
                    }
                    else if (i.styl() !== WINDOW_STYLE.SLIDER && i.styl() !== WINDOW_STYLE.CASEMENT) {
                        return true;
                    }
                }
            }
        ],
        disabled: (i, u) => {
            var _a, _b;
            if (i.styl() !== WINDOW_STYLE.SLIDER) {
                if (i.styl() === WINDOW_STYLE.CASEMENT && ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) === '3') {
                    return false;
                }
                return true;
            }
        },
        default: (i, u) => {
            if (i.styl() === WINDOW_STYLE.SLIDER) {
                return 0;
            }
            else {
                return 2;
            }
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.WINDOW.sash);
        },
        required: (i, u) => {
            if (i.styl() === WINDOW_STYLE.SLIDER) {
                return 'Sash selection is required.';
            }
        }
    },
    number: {
        options: [
            {
                label: 'Single', value: '1'
            },
            {
                label: 'Double',
                value: '2',
                disabled: (i, u) => {
                    if (i.styl() === WINDOW_STYLE.SLIDER) {
                        if (i.geo() === GEO.WEST) {
                            return true;
                        }
                        else if (i.qlty() === QUALITY.PREMIUM && (i.ssh() === '1/4' || i.ssh() === '1/3')) {
                            return 'Only Single-wide is available for this style.';
                        }
                    }
                }
            },
            {
                label: 'Triple',
                value: '3',
                disabled: (i, u) => {
                    if (i.styl() === WINDOW_STYLE.SLIDER) {
                        if (i.qlty() === QUALITY.PREMIUM && i.ssh() !== 'even') {
                            return 'Only Single-wide is available for this style & sash combination.';
                        }
                        else {
                            return 'Only Single- or Double-wide are available for this style.';
                        }
                    }
                }
            }
        ],
        default: (i, u) => {
            return 0;
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.WINDOW.number);
        },
        required: (i, u) => {
            return 'Number-wide selection is required.';
        }
    }
};
exports.FRAME = {
    interior: {
        options: [
            {
                label: 'White', value: COLORS.WHITE, hex: '#ffffff', fg: '#000'
            },
            {
                label: 'Almond', value: COLORS.ALMOND, hex: '#CEC8A8', fg: '#000',
                disabled: (i, u) => {
                    if (i.geo() !== GEO.WEST) {
                        return true;
                    }
                }
            },
            {
                label: 'Beige', value: COLORS.BEIGE, hex: '#CEC7AD', fg: '#000',
                disabled: (i, u) => {
                    if (i.geo() !== GEO.EAST) {
                        return true;
                    }
                }
            },
            {
                label: 'Classic Clay', value: COLORS.CLASSIC_CLAY, hex: '#A49786', fg: '#000',
                disabled: (i, u) => {
                    if (i.geo() !== GEO.EAST) {
                        return true;
                    }
                }
            },
            {
                label: 'Clay', value: COLORS.CLAY, hex: '#CDBFA2', fg: '#000',
                disabled: (i, u) => {
                    if (i.geo() !== GEO.WEST) {
                        return true;
                    }
                }
            }
        ],
        required: (i, u) => {
            return 'Interior Color is required.';
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.FRAME.interior);
        },
        default: (i, u) => {
            var _a, _b;
            if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.exterior) === null || _b === void 0 ? void 0 : _b.value) && exports.FRAME.interior.options.findIndex(item => item.value === i.frame.exterior.value) > -1) {
                return exports.FRAME.interior.options.findIndex(item => item.value === i.frame.exterior.value);
            }
            else {
                return 0;
            }
        }
    },
    exterior: {
        options: [
            {
                label: 'White', value: COLORS.WHITE, hex: '#ffffff', fg: '#000',
                disabled: (i, u) => {
                    var _a, _b;
                    if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) !== COLORS.WHITE) {
                        return 'Exterior white only available with frame color white.';
                    }
                }
            },
            {
                label: 'Almond', value: COLORS.ALMOND, hex: '#CEC8A8', fg: '#000',
                disabled: (i, u) => {
                    var _a, _b;
                    if (i.geo() !== GEO.WEST) {
                        return true;
                    }
                    else if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) !== COLORS.ALMOND) {
                        return 'Exterior almond only available with frame color almond.';
                    }
                }
            },
            {
                label: 'Beige', value: COLORS.BEIGE, hex: '#CEC7AD', fg: '#000',
                disabled: (i, u) => {
                    var _a, _b;
                    if (i.geo() !== GEO.EAST) {
                        return true;
                    }
                    else if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) !== COLORS.BEIGE) {
                        return 'Exterior beige only available with frame color beige.';
                    }
                }
            },
            {
                label: 'Black', value: COLORS.BLACK, hex: '#000000', fg: '#fff',
                disabled: (i, u) => {
                    var _a, _b, _c, _d;
                    if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) === COLORS.CLAY || ((_d = (_c = i.frame) === null || _c === void 0 ? void 0 : _c.interior) === null || _d === void 0 ? void 0 : _d.value) === COLORS.CLASSIC_CLAY) {
                        return 'Exterior black only available with frame color white or beige.';
                    }
                    else if (i.grd() === GRID_TYPE.FLAT) {
                        return 'Exterior color black not available with flat grid type.';
                    }
                    else if (i.geo() === GEO.WEST && i.qlty() === QUALITY.STANDARD) {
                        return 'Exterior color black not available with standard quality.';
                    }
                }
            },
            {
                label: 'Architectural Bronze', value: COLORS.BROWN, hex: '#332B28', fg: '#fff',
                disabled: (i, u) => {
                    var _a, _b, _c, _d;
                    if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) === COLORS.CLAY || ((_d = (_c = i.frame) === null || _c === void 0 ? void 0 : _c.interior) === null || _d === void 0 ? void 0 : _d.value) === COLORS.CLASSIC_CLAY) {
                        return 'Exterior architectural bronze only available with frame color white or beige.';
                    }
                    else if (i.grd() === GRID_TYPE.FLAT) {
                        return 'Exterior architectural bronze not available with flat grid type.';
                    }
                    else if (i.geo() === GEO.WEST && i.qlty() === QUALITY.STANDARD) {
                        return 'Exterior architectural bronze not available with standard quality.';
                    }
                }
            },
            {
                label: 'Classic Clay', value: COLORS.CLASSIC_CLAY, hex: '#A49786', fg: '#000',
                disabled: (i, u) => {
                    var _a, _b;
                    if (i.geo() !== GEO.EAST) {
                        return true;
                    }
                    else if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) !== COLORS.CLASSIC_CLAY) {
                        return 'Exterior clay only available with frame color clay.';
                    }
                }
            },
            {
                label: 'Clay', value: COLORS.CLAY, hex: '#CDBFA2', fg: '#000',
                disabled: (i, u) => {
                    var _a, _b;
                    if (i.geo() !== GEO.WEST) {
                        return true;
                    }
                    else if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) !== COLORS.CLAY) {
                        return 'Exterior clay only available with frame color clay.';
                    }
                }
            }
        ],
        required: (i, u) => {
            return 'Exterior Color color is required.';
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.FRAME.exterior);
        },
        default: (i, u) => {
            var _a, _b;
            if (((_b = (_a = i.frame) === null || _a === void 0 ? void 0 : _a.interior) === null || _b === void 0 ? void 0 : _b.value) && exports.FRAME.exterior.options.findIndex(item => item.value === i.frame.interior.value) > -1) {
                return exports.FRAME.exterior.options.findIndex(item => item.value === i.frame.interior.value);
            }
            else {
                return 0;
            }
        }
    },
    drywall_remove: {
        options: exports.TRUE_FALSE,
        disabled: (i, u) => {
            // Drywall remove isn't an option in the west, or in the east for replacement windows or new construction sliders/picture windows.
            if (i.geo() === GEO.WEST) {
                return true;
            }
            else if (i.styl().match(/PIC|SLI/)) {
                return true;
            }
            else if (i.window.type.value === WINDOW_TYPE.REPLACEMENT) {
                return true;
            }
            else if (i.styl() === WINDOW_STYLE.DOUBLE_HUNG || i.styl() === WINDOW_STYLE.AWNING || i.styl() === WINDOW_STYLE.CASEMENT) {
                return true;
            }
            else if (i.styl() === WINDOW_STYLE.SINGLE_HUNG && i.qlty() === QUALITY.PREMIUM) {
                return true;
            }
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.FRAME.drywall_remove);
        }
    },
    strength: {
        options: [
            {
                label: 'Normal',
                value: '',
                disabled: (i, u) => {
                    if (i.qlty() === QUALITY.PREMIUM && i.styl() === WINDOW_STYLE.PICTURE) {
                        return true;
                    }
                }
            },
            { label: 'Composite Reinforcement', value: 'YES' }
        ],
        disabled: (i, u) => {
            if (i.qlty() !== QUALITY.PREMIUM) {
                return true;
            }
        },
        default: (i, u) => {
            if (i.window.style.value === WINDOW_STYLE.PICTURE) {
                return 1;
            }
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.FRAME.strength);
        }
    },
    jamb_extender: {}
};
exports.GLASS = {
    type: {
        options: [
            {
                label: 'Energy Star (Basic)',
                value: 'basic'
            },
            {
                label: 'Energy Star (Upgrade)',
                value: 'energy'
            }
        ],
        required: (i, u) => {
            return 'Glass type selection is required.';
        },
        default: (i, u) => {
            return 1;
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GLASS.type);
        }
    },
    temper: {
        options: [
            { label: 'None', value: false },
            {
                label: 'Full',
                value: 'full',
                disabled: (i, u) => {
                    return 'This option not currently available';
                }
            },
            {
                label: 'Bottom',
                value: 'bottom',
                disabled: (i, u) => {
                    return 'This option not currently available';
                }
            },
            {
                label: 'Right-Half',
                value: 'half',
                disabled: (i, u) => {
                    // 'Ends' & 'Right-Half' option only applies to value/builder sliders.
                    return 'This option not currently available';
                }
            },
            {
                label: 'Ends',
                value: 'ends',
                disabled: (i, u) => {
                    // 'Ends' & 'Right-Half' option only applies to value/builder sliders.
                    return 'This option not currently available';
                }
            },
            {
                label: 'Yes',
                value: true
            }
        ],
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GLASS.temper);
        }
    },
    tint: {
        options: [
            { label: 'None', value: '', hex: '#dae4e5' },
            { label: 'Bronze', value: 'bronze', hex: '#cd7f32' },
            { label: 'Gray', value: 'gray', hex: '#efefef' }
        ],
        default: (i, u) => {
            return 0;
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GLASS.tint);
        }
    },
    privacy: {
        options: [
            {
                label: 'None',
                value: '',
                disabled: (i, u) => {
                    var _a, _b;
                    if ((_b = (_a = i.glass) === null || _a === void 0 ? void 0 : _a.obscure) === null || _b === void 0 ? void 0 : _b.value) {
                        return true;
                    }
                }
            },
            { label: 'Full', value: 'full' },
            {
                label: 'Bottom',
                value: 'bottom',
                disabled: (i, u) => {
                    if (i.styl() !== WINDOW_STYLE.SINGLE_HUNG && i.styl() !== WINDOW_STYLE.DOUBLE_HUNG) {
                        return true;
                    }
                }
            }
        ],
        disabled: (i, u) => {
            var _a, _b;
            if (!((_b = (_a = i.glass) === null || _a === void 0 ? void 0 : _a.obscure) === null || _b === void 0 ? void 0 : _b.value)) {
                return true;
            }
        },
        default: (i, u) => {
            var _a, _b;
            if ((_b = (_a = i.glass) === null || _a === void 0 ? void 0 : _a.obscure) === null || _b === void 0 ? void 0 : _b.value) {
                return 1;
            }
            else {
                return 0;
            }
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GLASS.privacy);
        }
    },
    laminate: {
        options: [
            { label: 'No', value: '' },
            { label: 'Yes', value: 'Yes' }
        ],
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GLASS.laminate);
        }
    },
    obscure: {
        options: [
            { label: 'None', value: '' },
            { label: 'Obscure', value: 'obscure' },
            {
                label: 'Glue Chip', value: 'glue_chip', disabled: (i, u) => {
                    return 'Glue chip option not available at this time';
                }
            },
            {
                label: 'Rain', value: 'rain', disabled: (i, u) => {
                    return 'Rain option not available at this time';
                }
            }
        ],
        default: (i, u) => {
            return 0;
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GLASS.obscure);
        }
    }
};
exports.GRID = {
    type: {
        options: [
            { label: 'None', value: '' },
            {
                label: '3/4" Contour',
                value: GRID_TYPE.CONTOUR_THREE_FOURTHS,
                disabled: (i, u) => {
                    // let r: boolean = false;
                    // if (
                    //     i.ptrn() === PATTERN.SINGLE_VERTICAL
                    //     || (i.ptrn() === PATTERN.SINGLE_PERIMETER && i.styl() === WINDOW_STYLE.PICTURE)
                    //     || (i.qlty() === QUALITY.PREMIUM && i.geo() === GEO.EAST && i.styl() === WINDOW_STYLE.SLIDER)
                    //     || (i.qlty() === QUALITY.PREMIUM && i.geo() === GEO.EAST && i.styl() === WINDOW_STYLE.CASEMENT)
                    //     || (i.qlty() === QUALITY.PREMIUM && i.geo() === GEO.EAST && i.styl() === WINDOW_STYLE.DOUBLE_HUNG)
                    //     || (i.qlty() === QUALITY.PREMIUM && i.geo() === GEO.EAST && i.styl() === WINDOW_STYLE.SINGLE_HUNG)
                    // ) {
                    //     r = true;
                    // }
                    // return (i.grd() === GRID_TYPE.CONTOUR_THREE_FOURTHS && r)
                    //     ? `3/4" Contour grid type is not available with current selections.`
                    //     : r;
                    return true;
                }
            },
            {
                label: '1" Contour',
                value: GRID_TYPE.CONTOUR_ONE,
                disabled: (i, u) => {
                    let r = false;
                    if (i.ptrn() === PATTERN.SINGLE_VERTICAL
                        || (i.ptrn() === PATTERN.SINGLE_PERIMETER && i.styl() === WINDOW_STYLE.PICTURE &&
                            (i.geo() === GEO.WEST || i.qlty() !== QUALITY.PREMIUM))
                        || (i.styl() === WINDOW_STYLE.CASEMENT && i.geo() === GEO.WEST)
                        || (i.styl() === WINDOW_STYLE.AWNING && i.geo() === GEO.WEST)) {
                        r = true;
                    }
                    return (i.grd() === GRID_TYPE.CONTOUR_ONE && r)
                        ? `1" Contour grid type is not available with current selections.`
                        : r;
                }
            },
            {
                label: 'Flat',
                value: GRID_TYPE.FLAT,
                disabled: (i, u) => {
                    if (i.ptrn() === PATTERN.SINGLE_VERTICAL) {
                        return `Flat grid type is not available with current selections.`;
                    }
                    if (i.frame.exterior.value !== i.frame.interior.value) {
                        return `Flat grid type requires interior and exterior colors to match.`;
                    }
                }
            },
            {
                label: 'Contour',
                value: GRID_TYPE.CONTOUR,
                disabled: (i, u) => {
                    let r = true;
                    if (i.geo() === GEO.EAST
                        && i.qlty() === QUALITY.PREMIUM
                        && i.ptrn() !== PATTERN.SINGLE_VERTICAL
                        && (i.styl() !== WINDOW_STYLE.PICTURE || i.ptrn() !== PATTERN.SINGLE_PERIMETER)) {
                        r = false;
                    }
                    else if (i.geo() === GEO.EAST
                        && i.qlty() === QUALITY.STANDARD
                        && (i.styl() === WINDOW_STYLE.CASEMENT || i.styl() === WINDOW_STYLE.AWNING)) {
                        r = false;
                    }
                    return (i.grd() === GRID_TYPE.CONTOUR && r)
                        ? `Contour grid type is not available with current selections.`
                        : r;
                }
            },
            {
                label: '7/8" SDL',
                value: GRID_TYPE.SDL,
                disabled: (i, u) => {
                    let r = false;
                    if (i.qlty() !== QUALITY.PREMIUM || i.geo() === GEO.WEST) {
                        r = true;
                    }
                    return (i.grd() === GRID_TYPE.SDL && r)
                        ? `7/8" SDL grid type is not available with current selections.`
                        : r;
                }
            }
        ],
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GRID.type);
        }
    },
    pattern: {
        options: [
            { label: 'Colonial', value: PATTERN.COLONIAL },
            {
                label: 'Single Prairie',
                value: PATTERN.SINGLE_PRAIRIE
            },
            {
                label: 'Craftsman',
                value: PATTERN.CRAFTSMAN,
                disabled: (i, u) => {
                    if (i.qlty() === QUALITY.PREMIUM && i.styl() !== WINDOW_STYLE.CASEMENT) {
                        return 'Craftsman pattern selection not available for Premium Series';
                    }
                }
            },
            {
                label: 'Single Perimeter',
                value: PATTERN.SINGLE_PERIMETER,
                disabled: (i, u) => {
                    if (i.styl() === WINDOW_STYLE.PICTURE) {
                        return true;
                    }
                    else if ((i.styl() === WINDOW_STYLE.CASEMENT || i.styl() === WINDOW_STYLE.AWNING) && i.geo() === GEO.WEST) {
                        return true;
                    }
                    else if ((i.styl() === WINDOW_STYLE.CASEMENT || i.styl() === WINDOW_STYLE.AWNING) && i.grd() !== GRID_TYPE.CONTOUR_ONE) {
                        return true;
                    }
                }
            },
            {
                label: 'Single Vertical',
                value: PATTERN.SINGLE_VERTICAL,
                disabled: (i, u) => {
                    if (i.qlty() !== QUALITY.PREMIUM || i.styl() === WINDOW_STYLE.CASEMENT) {
                        return true;
                    }
                }
            }
        ],
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.GRID.pattern);
        }
    },
    upper: {
        horiz: {
            options: [
                {
                    label: 'None',
                    value: '0',
                    disabled: (i, u) => {
                        // craftsman style must include at least one horizontal grid line.
                        if (i.ptrn() === PATTERN.CRAFTSMAN) {
                            return true;
                        }
                    }
                },
                {
                    label: 'One',
                    value: '1'
                },
                {
                    label: 'Two',
                    value: '2',
                    disabled: (i, u) => {
                        if (!i.h()) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.h() < 33);
                            }
                        }
                    }
                },
                {
                    label: 'Three',
                    value: '3',
                    disabled: (i, u) => {
                        if (!i.h()) {
                            return true;
                        }
                        else if (i.styl() === WINDOW_STYLE.DOUBLE_HUNG || i.styl() === WINDOW_STYLE.SINGLE_HUNG) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.h() < 46);
                            }
                        }
                    }
                },
                {
                    label: 'Four',
                    value: '4',
                    disabled: (i, u) => {
                        if (!i.h()) {
                            return true;
                        }
                        else if (i.styl() === WINDOW_STYLE.DOUBLE_HUNG || i.styl() === WINDOW_STYLE.SINGLE_HUNG) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.h() < 46);
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Five',
                    value: '5',
                    disabled: (i, u) => {
                        if (!i.h()) {
                            return true;
                        }
                        else if (i.styl() === WINDOW_STYLE.DOUBLE_HUNG || i.styl() === WINDOW_STYLE.SINGLE_HUNG) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.h() < 73);
                                    break;
                                case 'SLI-COL':
                                case 'SLI-CRA':
                                    return true;
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Six',
                    value: '6',
                    disabled: (i, u) => {
                        if (!i.h()) {
                            return true;
                        }
                        else if (i.styl() === WINDOW_STYLE.DOUBLE_HUNG || i.styl() === WINDOW_STYLE.SINGLE_HUNG) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.h() < 84);
                                    break;
                                case 'SLI-COL':
                                case 'SLI-CRA':
                                    return true;
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Seven',
                    value: '7',
                    disabled: (i, u) => {
                        if (!i.h()) {
                            return true;
                        }
                        else if (i.styl() === WINDOW_STYLE.DOUBLE_HUNG || i.styl() === WINDOW_STYLE.SINGLE_HUNG) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.h() < 97);
                                    break;
                                case 'SLI-COL':
                                case 'SLI-CRA':
                                    return true;
                                    break;
                            }
                        }
                    }
                }
            ],
            disabled: (i, u) => {
                if (!i.h() || !i.w() || !i.styl() || !i.ptrn()) {
                    return true;
                }
                else if (i.ptrn() == PATTERN.SINGLE_PRAIRIE || i.ptrn() === PATTERN.SINGLE_PERIMETER || i.ptrn() === PATTERN.SINGLE_VERTICAL) {
                    return true;
                }
            },
            default: (i, u) => {
                if (i.ptrn() === PATTERN.CRAFTSMAN && i.grid.upper.horiz.value === '0') {
                    i.grid.upper.horiz = { label: 'One', value: '1', default: true };
                }
                if (i.h() && i.w()) {
                    switch (`${i.styl()}-${i.ptrn()}`) {
                        case 'SH-COL':
                        case 'DH-COL':
                        case 'SH-CRA':
                        case 'DH-CRA':
                            return (i.h() < 73) ? 1 : 2;
                            break;
                        case 'PIC-COL':
                        case 'PIC-CRA':
                            return (i.h() < 33) ? 1 : (i.h() < 47) ? 2 : (i.h() < 73) ? 3 : (i.h() < 73) ? 4 : (i.h() < 84) ? 5 : (i.h() < 97) ? 6 : 7;
                            break;
                        case 'SLI-COL':
                        case 'SLI-CRA':
                            return (i.h() < 29) ? 1 : (i.h() < 47) ? 2 : 3;
                            break;
                        default:
                            return 1;
                            break;
                    }
                }
            },
            validator: (i) => {
                return (0, exports.sharedValidator)(i, exports.GRID.upper.horiz);
            }
        },
        vert: {
            options: [
                { label: 'None', value: '0' },
                { label: 'One', value: '1' },
                {
                    label: 'Two',
                    value: '2',
                    disabled: (i, u) => {
                        if (!i.w()) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.w() < 25);
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Three',
                    value: '3',
                    disabled: (i, u) => {
                        if (!i.w()) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.w() < 37);
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Four',
                    value: '4',
                    disabled: (i, u) => {
                        if (!i.w()) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.w() < 49);
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Five',
                    value: '5',
                    disabled: (i, u) => {
                        if (!i.w()) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.w() < 61);
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Six',
                    value: '6',
                    disabled: (i, u) => {
                        if (!i.w()) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.w() < 73);
                                    break;
                            }
                        }
                    }
                },
                {
                    label: 'Seven',
                    value: '7',
                    disabled: (i, u) => {
                        if (!i.w()) {
                            return true;
                        }
                        else {
                            switch (`${i.styl()}-${i.ptrn()}`) {
                                case 'PIC-COL':
                                case 'PIC-CRA':
                                    return (i.w() < 91);
                                    break;
                            }
                        }
                    }
                }
            ],
            disabled: (i, u) => {
                if (!i.h() || !i.w() || !i.styl() || !i.ptrn()) {
                    return true;
                }
                else if (i.ptrn() === PATTERN.SINGLE_PRAIRIE || i.ptrn() === PATTERN.SINGLE_PERIMETER || i.ptrn() === PATTERN.SINGLE_VERTICAL) {
                    return true;
                }
            },
            default: (i, u) => {
                if (i.h() && i.w()) {
                    switch (`${i.styl()}-${i.ptrn()}`) {
                        case 'SH-COL':
                        case 'DH-COL':
                        case 'SH-CRA':
                        case 'DH-CRA':
                            return (i.w() < 25) ? 1 : (i.w() < 37) ? 2 : 3;
                            break;
                        case 'PIC-COL':
                        case 'PIC-CRA':
                        case 'AWN-CRA':
                        case 'CASE-CRA':
                            return (i.w() < 25) ? 1 : (i.w() < 37) ? 2 : (i.w() < 49) ? 3 : (i.w() < 61) ? 4 : (i.w() < 73) ? 5 : (i.w() < 91) ? 6 : 7;
                            break;
                        case 'SLI-COL':
                        case 'SLI-CRA':
                            if (i.ssh() === 'even') {
                                return (i.w() < 47) ? 1 : (i.w() < 71) ? 2 : 3;
                            }
                            else if (i.ssh() === '1/4') {
                                return (i.w() < 91) ? 1 : 2;
                            }
                            else if (i.ssh() === '1/3') {
                                return (i.w() < 52) ? 1 : (i.w() < 91) ? 2 : 3;
                            }
                            break;
                    }
                }
            },
            validator: (i) => {
                return (0, exports.sharedValidator)(i, exports.GRID.upper.vert);
            }
        }
    },
    lower: {
        horiz: {
            options: [
                {
                    label: 'None',
                    value: '0',
                    disabled: (i, u) => {
                        if (i.ptrn() === PATTERN.CRAFTSMAN) {
                            // craftsman style must include at least one horizontal grid line.
                            return true;
                        }
                    }
                },
                { label: 'One', value: '1' },
                { label: 'Two', value: '2' },
                { label: 'Three', value: '3' },
                {
                    label: 'Four',
                    value: '4'
                },
                {
                    label: 'Five',
                    value: '5'
                },
                {
                    label: 'Six',
                    value: '6'
                },
                {
                    label: 'Seven',
                    value: '7'
                }
            ],
            disabled: (i, u) => {
                if (i.ptrn() === PATTERN.CRAFTSMAN && i.styl() !== WINDOW_STYLE.SLIDER) {
                    // Craftsman style grids don't have lower grid at all, except sliders.
                    return true;
                }
                else if (i.styl() === WINDOW_STYLE.PICTURE || i.styl() === WINDOW_STYLE.AWNING || i.styl() === WINDOW_STYLE.CASEMENT) {
                    // Picture & Awning windows down have 'lower' grid at all.
                    return true;
                }
                else if (i.ptrn() === PATTERN.SINGLE_PRAIRIE || i.ptrn() === PATTERN.SINGLE_PERIMETER) {
                    return true;
                }
                else if (!i.h() || !i.w() || !i.styl() || !i.ptrn()) {
                    // Obviously can't have a grid without dimensions, style or pattern.
                    return true;
                }
            },
            locked: (i, u) => {
                if (i.styl() === WINDOW_STYLE.SLIDER) {
                    return i.grid.upper.horiz;
                }
            },
            validator: (i) => {
                return (0, exports.sharedValidator)(i, exports.GRID.lower.horiz);
            }
        },
        vert: {
            options: [
                { label: 'None', value: '0' },
                { label: 'One', value: '1' },
                { label: 'Two', value: '2' },
                {
                    label: 'Three',
                    value: '3'
                },
                {
                    label: 'Four',
                    value: '4'
                },
                {
                    label: 'Five',
                    value: '5'
                },
                {
                    label: 'Six',
                    value: '6'
                },
                {
                    label: 'Seven',
                    value: '7'
                }
            ],
            disabled: (i, u) => {
                if (i.ptrn() === PATTERN.CRAFTSMAN || i.ptrn() === PATTERN.SINGLE_PRAIRIE || i.ptrn() === PATTERN.SINGLE_PERIMETER) {
                    // Craftsman style grids don't have lower grid at all, except 1/4 & 1/3 sliders (middle).
                    if (i.styl() === WINDOW_STYLE.SLIDER && i.ssh() !== 'even') {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (i.styl() === WINDOW_STYLE.PICTURE || i.styl() === WINDOW_STYLE.AWNING || i.styl() === WINDOW_STYLE.CASEMENT) {
                    // Picture & Awning windows down have 'lower' grid at all.
                    return true;
                }
                else if (!i.h() || !i.w() || !i.styl() || !i.ptrn()) {
                    // Obvi can't have a grid without dimensions, style or pattern.
                    return true;
                }
            },
            // locked: (i: EwinOrderItem, u: any) => {
            //     if (i.ptrn() === PATTERN.COLONIAL && i.styl().match(/SH|DH/)) {
            //         // Bottom vert lines must match top on colonial windows.
            //         return JSON.parse(JSON.stringify(i.grid.upper.vert));
            //     }
            // },
            default: (i, u) => {
                if (i.h() && i.w()) {
                    switch (`${i.styl()}-${i.ptrn()}`) {
                        case 'SLI-COL':
                        case 'SLI-CRA':
                            if (i.ssh() === 'even') {
                                return (i.w() < 47) ? 1 : (i.w() < 71) ? 2 : 3;
                            }
                            else if (i.ssh() === '1/4') {
                                return (i.w() < 52) ? 2 : (i.w() < 91) ? 3 : 5;
                            }
                            else if (i.ssh() === '1/3') {
                                return (i.w() < 52) ? 1 : (i.w() < 91) ? 2 : 3;
                            }
                            break;
                    }
                }
            },
            validator: (i) => {
                return (0, exports.sharedValidator)(i, exports.GRID.lower.vert);
            }
        }
    }
};
exports.SCREEN = {
    type: {
        options: exports.TRUE_FALSE,
        disabled: (i, u) => {
            if (i.screen.type.value && i.styl() === WINDOW_STYLE.PICTURE) {
                console.log(i.screen);
                return `Screen not available on picture windows`;
            }
        },
        default: (i, u) => {
            if (i.styl() === WINDOW_STYLE.PICTURE) {
                return 0;
            }
            else {
                return 1;
            }
        },
        validator: (i) => {
            return null; //sharedValidator(i, SCREEN.type);
        }
    },
    boxed: {
        options: exports.TRUE_FALSE,
        disabled: (i, u) => {
            return true; // hard-coded off for now.
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.SCREEN.boxed);
        }
    }
};
exports.HARDWARE = {
    performance: {
        options: exports.TRUE_FALSE,
        disabled: (i, u) => {
            if (i.qlty() === QUALITY.PREMIUM) {
                return true;
            }
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.HARDWARE.performance);
        }
    },
    ada_lock: {
        options: exports.TRUE_FALSE,
        disabled: (i, u) => {
            if (i.qlty() === QUALITY.PREMIUM) {
                return true;
            }
            else if (i.styl() === WINDOW_STYLE.PICTURE) {
                return true;
            }
            else if (i.styl() === WINDOW_STYLE.SLIDER && i.ssh() !== 'even') {
                return true;
            }
        },
        validator: (i) => {
            return (0, exports.sharedValidator)(i, exports.HARDWARE.ada_lock);
        }
    }
};
exports.FRAME_LIMITS = {
    east: {
        '$': {
            SH: {
                w: { mn: 14.25, mx: 52 },
                h: { mn: 25, mx: (w) => {
                        return (w > 48) ? 84 : 94.4;
                    } },
                '2': { mnw: 29, mnh: 25, mxw: (h) => {
                        return (h > 84) ? 96 : 104;
                    }, mxh: 94.4 },
                '3': { mnw: 43.25, mnh: 25, mxw: 120, mxh: 94.4 }
            },
            PIC: {
                w: { mn: 11.5, mx: (h) => {
                        return (h > 54) ? 84 : 96;
                    } },
                h: { mn: 11.5, mx: (w) => {
                        return (w > 54) ? 84 : 96;
                    } },
                '2': { mnw: 23.5, mnh: 11.5, mxw: (h) => {
                        return (h > 84) ? 108 : 120;
                    }, mxh: 96 },
                '3': { mnw: 35, mnh: 11.5, mxw: 120, mxh: 96 },
                fui: 155.5
            },
            SLI: {
                even: {
                    w: { mn: 23.5, mx: 84 },
                    h: { mn: 14, mx: (w) => {
                            return (w > 72) ? 60 : 72;
                        } },
                    '2': { mnw: 47.5, mnh: 14, mxw: 120, mxh: 72 },
                    fui: 144
                },
                '1/4': {
                    w: { mn: 48.5, mx: 108 },
                    h: { mn: 14, mx: (w) => {
                            return (w > 96) ? 60 : 72;
                        } },
                    '2': { mnw: 97.5, mnh: 14, mxw: 120, mxh: 72 },
                    fui: 167.5
                },
                '1/3': {
                    w: { mn: 37, mx: 108 },
                    h: { mn: 14, mx: (w) => {
                            return (w > 96) ? 60 : 72;
                        } },
                    '2': { mnw: 74.5, mnh: 14, mxw: 120, mxh: 72 },
                    fui: 167.5
                }
            },
            CASE: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 16, mx: 36 },
                    h: { mn: 19, mx: 78 },
                    fui: 114
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 32, mx: 78 },
                            h: { mn: 19, mx: 78 },
                            fui: 150
                        };
                        break;
                    case '3':
                        switch (i.ssh()) {
                            case '1/3':
                                r = { w: { mn: 48, mx: 108 }, h: { mn: 19, mx: 78 }, fui: 186 };
                                break;
                            case '1/4':
                                r = { w: { mn: 64, mx: 120 }, h: { mn: 19, mx: 78 }, fui: 198, a: 8784 };
                                break;
                        }
                        break;
                }
                return r;
            },
            AWN: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 19, mx: 48 },
                    h: { mn: 16, mx: 48 },
                    fui: 96
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 38, mx: 96 },
                            h: { mn: 16, mx: 48 },
                            fui: 144
                        };
                        break;
                    case '3':
                        r = {
                            w: { mn: 57, mx: 120 },
                            h: { mn: 16, mx: 48 },
                            fui: 168
                        };
                }
                return r;
            }
        },
        '$$': {
            SH: {
                w: { mn: 14.25, mx: 52 },
                h: { mn: 25, mx: (w) => {
                        return (w > 48) ? 84 : 94.4;
                    } },
                '2': { mnw: 29, mnh: 25, mxw: (h) => {
                        return (h > 84) ? 96 : 104;
                    }, mxh: 94.4 },
                '3': { mnw: 43.25, mnh: 25, mxw: 120, mxh: 94.4 }
            },
            DH: {
                w: { mn: 16, mx: 52 },
                h: { mn: 25, mx: (w) => {
                        return (w > 48) ? 76 : 84;
                    } },
                '2': { mnw: 32.5, mnh: 25, mxw: 96, mxh: 84 },
                '3': { mnw: 48.5, mnh: 25, mxw: 120, mxh: 84 }
            },
            PIC: {
                w: { mn: 11.5, mx: (h) => {
                        return (h > 54) ? 84 : 96;
                    } },
                h: { mn: 11.5, mx: (w) => {
                        return (w > 54) ? 84 : 96;
                    } },
                '2': { mnw: 23.5, mnh: 11.5, mxw: (h) => {
                        return (h > 84) ? 108 : 120;
                    }, mxh: 96 },
                '3': { mnw: 35, mnh: 11.5, mxw: 120, mxh: 96 },
                fui: 155.5
            },
            SLI: {
                even: {
                    w: { mn: 23.5, mx: 84 },
                    h: { mn: 14, mx: (w) => {
                            return (w > 72) ? 60 : 72;
                        } },
                    '2': { mnw: 47.5, mnh: 14, mxw: 120, mxh: 72 },
                    '3': { mnw: 71, mnh: 14, mxw: 120, mxh: 72 },
                    fui: 144
                },
                '1/4': {
                    w: { mn: 48.5, mx: 108 },
                    h: { mn: 14, mx: (w) => {
                            return (w > 96) ? 60 : 72;
                        } },
                    '2': { mnw: 97.5, mnh: 14, mxw: 120, mxh: 72 },
                    fui: 167.5
                },
                '1/3': {
                    w: { mn: 37, mx: 108 },
                    h: { mn: 14, mx: (w) => {
                            return (w > 96) ? 60 : 72;
                        } },
                    '2': { mnw: 74.5, mnh: 14, mxw: 120, mxh: 72 },
                    fui: 167.5
                }
            }
        },
        '$$$': {
            SH: {
                w: { mn: 12, mx: 52 },
                h: { mn: 22, mx: 96 },
                '2': { mnw: 24.5, mnh: 22, mxw: (h) => {
                        return (h > 77) ? 84 : 104;
                    }, mxh: 96 },
                '3': { mnw: 36.5, mnh: 22, mxw: (h) => {
                        return (h > 67) ? 84 : 120;
                    }, mxh: 96 },
                a: 8064
            },
            DH: {
                w: { mn: 12, mx: 52 },
                h: { mn: 20, mx: 96 },
                '2': { mnw: 24.5, mnh: 20, mxw: (h) => {
                        return (h > 77) ? 84 : 104;
                    }, mxh: 96 },
                '3': { mnw: 36.5, mnh: 20, mxw: (h) => {
                        return (h > 67) ? 84 : 120;
                    }, mxh: 96 }
            },
            PIC: {
                w: { mn: 9.5, mx: 112 },
                h: { mn: 9.5, mx: 112 },
                '2': { mnw: 19.5, mnh: 9.5, mxw: (h) => {
                        return (h > 72) ? 72 : 112;
                    }, mxh: (w) => {
                        return (w > 72) ? 72 : 112;
                    }
                },
                '3': { mnw: 29, mnh: 9.5, mxw: (h) => {
                        return (h > 72) ? 72 : 112;
                    }, mxh: (w) => {
                        return (w > 72) ? 72 : 112;
                    }
                },
                fui: 154,
                a: 8064
            },
            SLI: {
                even: {
                    w: { mn: 18.5, mx: 96 },
                    h: { mn: 11.25, mx: 72 },
                    '2': { mnw: 37.5, mnh: 11.25, mxw: 120, mxh: 72 },
                    fui: 156,
                    a: 8064
                },
                '1/4': {
                    w: { mn: 47.5, mx: 120 },
                    h: { mn: 11.25, mx: 72 },
                    f: 191.5
                },
                '1/3': {
                    w: { mn: 37.25, mx: 120 },
                    h: { mn: 11.25, mx: 72 },
                    fui: 191.5
                }
            },
            CASE: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 16, mx: 36 },
                    h: { mn: 19, mx: 78 },
                    fui: 114
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 32, mx: 78 },
                            h: { mn: 19, mx: 78 },
                            fui: 150
                        };
                        break;
                    case '3':
                        switch (i.ssh()) {
                            case '1/3':
                                r = { w: { mn: 48, mx: 108 }, h: { mn: 19, mx: 78 }, fui: 186 };
                                break;
                            case '1/4':
                                r = { w: { mn: 64, mx: 120 }, h: { mn: 19, mx: 78 }, fui: 198 };
                                break;
                        }
                        break;
                }
                return r;
            },
            AWN: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 19, mx: 52 },
                    h: { mn: 16, mx: 48 },
                    fui: 96
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 38, mx: 104 },
                            h: { mn: 16, mx: 48 },
                            fui: 144
                        };
                        break;
                    case '3':
                        r = {
                            w: { mn: 57, mx: 120 },
                            h: { mn: 16, mx: 48 },
                            fui: 168
                        };
                }
                return r;
            }
        }
    },
    west: {
        '$': {
            SH: {
                w: { mn: 10, mx: 48 },
                h: { mn: 23.5, mx: 96 },
                '2': { mnw: 20.5, mnh: 23.5, mxw: 108, mxh: 108 },
                '3': { mnw: 30.5, mnh: 23.5, mxw: 108, mxh: 108 },
                a: 6912
            },
            PIC: {
                w: { mn: 8.5, mx: 96 },
                h: { mn: 8.5, mx: 96 },
                '2': { mnw: 17.5, mnh: 8.5, mxw: 108, mxh: 108 },
                '3': { mnw: 26, mnh: 8.5, mxw: 108, mxh: 108 },
                a: 6912
            },
            SLI: {
                even: {
                    w: { mn: 20, mx: 72 },
                    h: { mn: 10, mx: 72 }
                },
                '1/4': {
                    w: { mn: 47.5, mx: 120 },
                    h: { mn: 9.5, mx: 72 }
                },
                '1/3': {
                    w: { mn: 47.5, mx: 120 },
                    h: { mn: 9.5, mx: 72 }
                }
            },
            CASE: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 15.75, mx: 40 },
                    h: { mn: 17.5, mx: 78 },
                    fui: 114
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 31.5, mx: 80 },
                            h: { mn: 19, mx: 78 },
                            fui: 154
                        };
                        break;
                    case '3':
                        switch (i.ssh()) {
                            case '1/3':
                                r = { w: { mn: 47.25, mx: 108 }, h: { mn: 19, mx: 78 }, fui: 186 };
                                break;
                        }
                        break;
                }
                return r;
            },
            AWN: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 17.5, mx: 48 },
                    h: { mn: 11.5, mx: 48 },
                    fui: 96
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 35, mx: 97 },
                            h: { mn: 11.5, mx: 48 },
                            fui: 145
                        };
                        break;
                    case '3':
                        r = {
                            w: { mn: 52.5, mx: 120 },
                            h: { mn: 11.5, mx: 48 },
                            fui: 168
                        };
                }
                return r;
            }
        },
        '$$': {
            SH: {
                w: { mn: 10.5, mx: 54 },
                h: { mn: 24, mx: 96 },
                '2': { mnw: 21.5, mnh: 24, mxw: 120, mxh: 108 },
                '3': { mnw: 33, mnh: 24, mxw: 120, mxh: 108 },
                a: 9216
            },
            PIC: {
                w: { mn: 10.5, mx: 120 },
                h: { mn: 10.5, mx: 120 },
                '2': { mnw: 21.5, mnh: 10.5, mxw: 120, mxh: 108 },
                '3': { mnw: 32, mnh: 10.5, mxw: 120, mxh: 108 },
                a: 9216
            },
            SLI: {
                even: {
                    w: { mn: 20, mx: 96 },
                    h: { mn: 10, mx: 72 }
                },
                '1/4': {
                    w: { mn: 40, mx: 130 },
                    h: { mn: 10.5, mx: 72 }
                },
                '1/3': {
                    w: { mn: 40, mx: 130 },
                    h: { mn: 10.5, mx: 72 }
                }
            }
        },
        '$$$': {
            SH: {
                w: { mn: 12, mx: 52 },
                h: { mn: 22, mx: 96 },
                '2': { mnw: 24.5, mnh: 22, mxw: 120, mxh: 108 },
                '3': { mnw: 36.5, mnh: 22, mxw: 120, mxh: 108 },
                a: 8064
            },
            DH: {
                w: { mn: 12, mx: 52 },
                h: { mn: 20, mx: 96 },
                '2': { mnw: 24.5, mnh: 20, mxw: 120, mxh: 108 },
                '3': { mnw: 36.5, mnh: 20, mxw: 120, mxh: 108 },
                a: 8064
            },
            PIC: {
                w: { mn: 9.5, mx: 112 },
                h: { mn: 9.5, mx: 112 },
                '2': { mnw: 19.5, mnh: 9.5, mxw: 120, mxh: 108 },
                '3': { mnw: 29, mnh: 9.5, mxw: 120, mxh: 108 },
                a: 8064
            },
            SLI: {
                even: {
                    w: { mn: 18.5, mx: 96 },
                    h: { mn: 11.25, mx: 72 },
                    fui: 156
                },
                '1/4': {
                    w: { mn: 47.5, mx: 130 },
                    h: { mn: 11.25, mx: 72 }
                },
                '1/3': {
                    w: { mn: 47.5, mx: 130 },
                    h: { mn: 11.25, mx: 72 }
                }
            },
            CASE: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 15.75, mx: 40 },
                    h: { mn: 17.5, mx: 78 },
                    fui: 114
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 31.5, mx: 80 },
                            h: { mn: 19, mx: 78 },
                            fui: 150
                        };
                        break;
                    case '3':
                        r = {
                            w: { mn: 47.25, mx: 108 },
                            h: { mn: 19, mx: 78 },
                            fui: 186
                        };
                        break;
                }
                return r;
            },
            AWN: (i, w, h) => {
                var _a, _b;
                let r = {
                    w: { mn: 17.5, mx: 48 },
                    h: { mn: 11.5, mx: 48 },
                    fui: 96
                };
                switch ((_b = (_a = i.window) === null || _a === void 0 ? void 0 : _a.number) === null || _b === void 0 ? void 0 : _b.value) {
                    case '2':
                        r = {
                            w: { mn: 35, mx: 97 },
                            h: { mn: 11.5, mx: 48 },
                            fui: 145
                        };
                        break;
                    case '3':
                        r = {
                            w: { mn: 52.5, mx: 120 },
                            h: { mn: 11.5, mx: 48 },
                            fui: 168
                        };
                }
                return r;
            }
        }
    }
};
exports.BUILDER_STEPS = [
    ['window-type', 'location-zip', 'location-installation', 'window-quality'],
    ['window-style', 'window-number', 'window-operation', 'window-sash'],
    ['frame-interior', 'frame-exterior', 'screen-type', 'glass-type', 'glass-temper', 'glass-obscure'],
    ['grid-type', 'grid-pattern']
];
;
